import {FreightOrder} from 'two-core';

/**
 * Get the age of a freight order in days
 * @param freightOrder
 */
export const getFreightOrderAgeInDays = (freightOrder?: FreightOrder): number => {
  if (!freightOrder) {
    return 0;
  }

  const deliveryDate = freightOrder?.delivered_at ? new Date(freightOrder?.delivered_at.toString()) : undefined;
  const handedOver = freightOrder?.handed_over ? new Date(freightOrder?.handed_over.toString()) : undefined;
  if (!handedOver) {
    return 0;
  }
  return Math.round(((deliveryDate?.getTime() ?? Date.now()) - handedOver?.getTime()) / (1000 * 60 * 60 * 24));
};
