import React from 'react';
import {
  DataTableExpandedRows,
  DataTablePageParams,
  DataTableSortParams,
  DataTableSortOrderType,
} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {MultiSelect} from 'primereact/multiselect';
import {DropdownChangeParams} from 'primereact/dropdown';
import {Toast} from 'primereact/toast';
import {AppColumnMenuBodyTemplate, MessageService, TwoDataTable, ToastService, TwoMessage} from 'two-app-ui';
import {AppContext} from 'two-app-ui';
import {QueryParameter} from 'two-core';
import ContainersService from '../../services/ContainersService';
import {Container} from 'two-core';
import {Subscription} from 'rxjs';
import config from '../../config/config';
import formats from '../../config/formats';
import {DateTime} from 'luxon';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {messages} from '../../config/messages';
import FreightOrdersService from '../../services/FreightOrdersService';
import {NavLink} from 'react-router-dom';
import OrdersService from '../../services/OrdersService';
import TlesService from '../../services/TlesService';
import '../../scss/CustomTable.scss';
import {ContainerStageDropdownOption, containerStages} from '../Container/Constants/constants';
import TasksService from '../../services/TasksService';
import GroupOrdersComponent from '../GroupOrders/GroupOrdersComponent';
import ContainerFilterBoxComponent from './ContainerFilterBoxComponent';
import ReportsService from '../../services/ReportsService';

interface State {
  loading: boolean;
  items: Container[];
  totalItems: number;
  activeFilters: {};
  filters: {
    id: string;
    state_name: string;
    warehouse: string;
    size: string;
    stage: string[];
    eta_to_port: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    showDone: boolean;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  expandedRows: DataTableExpandedRows[];
  inboundBoxesCount: number;
  inboundContainersCount: number;
  inProgressBoxesCount: number;
  inProgressContainersCount: number;
}

class ContainerListComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  containersService: ContainersService | null = null;
  //statesService: StatesService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  ordersService: OrdersService | null = null;
  tlesService: TlesService | null = null;
  tasksService: TasksService | null = null;
  reportsService: ReportsService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props = {}) {
    super(props);
    this.state = {
      items: [],
      totalItems: 0,
      loading: true,
      activeFilters: {},
      filters: {
        id: '',
        state_name: '',
        warehouse: '',
        size: '',
        stage: [],
        eta_to_port: {
          fromDate: null,
          toDate: null,
        },
        showDone: false,
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      expandedRows: [],
      inboundBoxesCount: 0,
      inboundContainersCount: 0,
      inProgressBoxesCount: 0,
      inProgressContainersCount: 0,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.idBodyTemplate = this.idBodyTemplate.bind(this);
    this.boxesBodyTemplate = this.boxesBodyTemplate.bind(this);
    this.etaToPortBodyTemplate = this.etaToPortBodyTemplate.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.availableStageOptions = this.availableStageOptions.bind(this);
  }

  async componentDidMount() {
    this.containersService = this.context.containersService;
    //this.statesService = this.context.statesService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.tlesService = this.context.tlesService;
    this.ordersService = this.context.ordersService;
    this.tasksService = this.context.tasksService;
    this.reportsService = this.context.reportsService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.containerUpdate) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          this.loadData();
        }
      }
    });
    this.loadData();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    this.setState({loading: true});
    const state = localStorage.getItem('current state') ?? '';
    this.loadContainerFilterBoxData();
    const filters: string[] = [];
    const sortBy: string[] = [];

    if (this.state.filters.id) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.id,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.size) {
      filters.push(
        JSON.stringify({
          field: 'size',
          value: this.state.filters.size,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.warehouse) {
      filters.push(
        JSON.stringify({
          field: 'location.name',
          value: this.state.filters.warehouse,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.state_name && this.state.filters.state_name.length) {
      filters.push(
        JSON.stringify({
          field: 'destination_state.long_name',
          value: this.state.filters.state_name,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.stage && this.state.filters.stage.length) {
      filters.push(
        JSON.stringify({
          field: `states->>'${state}'`,
          value: this.state.filters.stage,
          condition: 'in',
        })
      );
    } else if (!this.state.filters.showDone) {
      filters.push(
        JSON.stringify({
          field: `states->>'${state}'`,
          value: 'Done',
          condition: '<>',
        })
      );
    }

    if (this.state.filters.eta_to_port.fromDate) {
      const fromDate = this.state.filters.eta_to_port.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'eta_to_port',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.eta_to_port.toDate) {
      const toDate = this.state.filters.eta_to_port.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'eta_to_port',
          value: toDate,
          condition: '<=',
        })
      );
    }

    let sortByField = this.state.sortBy?.field ?? 'id';
    switch (sortByField) {
      case 'unload_at.name':
        sortByField = 'location.name';
        break;
      case 'destination_state.long_name':
        sortByField = 'state.long_name';
        break;
      case 'stage':
        sortByField = `states->>'${state}'`;
        break;
    }

    sortBy.push(
      JSON.stringify({
        field: sortByField,
        direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
      })
    );

    this.setState({activeFilters: {...filters}});

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    this.containersService
      ?.getContainers(params)
      .then(data => {
        const containers = (data.records as Container[]) ?? [];
        this.setState({
          items: containers,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async loadContainerFilterBoxData() {
    await this.reportsService
      ?.loadReportForContainerList()
      .then(data => {
        this.setState({
          inboundBoxesCount: data.inboundBoxesCount,
          inboundContainersCount: data.inboundContainersCount,
          inProgressBoxesCount: data.inProgressBoxesCount,
          inProgressContainersCount: data.inProgressContainersCount,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, report data load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stopTypingDetection);
  };

  rowExpansionTemplate(container: Container) {
    return <GroupOrdersComponent container={container} heightToScroll={'max'} hideFilter={true} />;
  }

  idBodyTemplate(rowData: Container) {
    return (
      <React.Fragment>
        <AppColumnMenuBodyTemplate
          rowItemIdentifier={rowData?.id?.toString() ?? ''}
          isDynamicMenuItems={true}
          selectedItems={[]}
        >
          <NavLink to={'/container/' + rowData.id}>{rowData.id}</NavLink>
        </AppColumnMenuBodyTemplate>
      </React.Fragment>
    );
  }

  etaToPortBodyTemplate(rowData: Container) {
    const etaToPort = rowData.eta_to_port
      ? DateTime.fromISO(rowData.eta_to_port.toString()).toFormat(formats.date)
      : '';
    return <span>{etaToPort}</span>;
  }

  etaToTwoBodyTemplate(rowData: Container) {
    const dateLeaveFactory = rowData.date_leave_factory
      ? DateTime.fromISO(rowData.date_leave_factory.toString()).toFormat(formats.date)
      : '';
    return <span>{dateLeaveFactory}</span>;
  }

  stageBodyTemplate(rowData: Container) {
    const state = localStorage.getItem('current state') ?? '';
    const stage = rowData?.states?.[state] ?? '';

    return <span className={`stage-badge container-stage-${stage.toLowerCase().replaceAll(' ', '-')}`}>{stage}</span>;
  }

  boxesBodyTemplate(rowData: Container) {
    const orders = rowData.freight_orders?.filter(fo => fo.id) ?? [];
    const orderIds = orders.map(fo => fo.id ?? '') ?? [];

    const state = localStorage.getItem('current state')!;

    const doneOrders = orders.filter(o => o.route?.[state]?.stage === 'Delivered');
    const doneOrderIds = doneOrders.map(fo => fo.id ?? '') ?? [];

    const orderShipmentItems = rowData.shipment_items?.filter(si => orderIds.includes(si?.order_id)) ?? [];

    const doneOrderShipmentItems = rowData.shipment_items?.filter(si => doneOrderIds.includes(si?.order_id)) ?? [];

    return <span>{`${doneOrderShipmentItems.length}/${orderShipmentItems.length}`}</span>;
  }

  availableStageOptions() {
    if (this.state.filters.showDone) {
      return containerStages;
    } else {
      return containerStages.filter(option => {
        return option.value !== 'Done';
      });
    }
  }

  render() {
    const idFilter = (
      <InputText
        name="id"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const sizeFilter = (
      <InputText
        name="size"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const warehouseFilter = (
      <InputText
        name="warehouse"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const stageSelectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span
            className={`p-mr-1 stage-badge stage-badge-filter container-stage-${value
              .toLowerCase()
              .replaceAll(' ', '-')}`}
          >
            {value}
          </span>
        );
      }

      return <></>;
    };
    const stageItemTemplate = (option: ContainerStageDropdownOption) => {
      return (
        <span className={`stage-badge container-stage-${option.value.toLowerCase().replaceAll(' ', '-')}`}>
          {option.value}
        </span>
      );
    };
    const stageFilter = (
      <MultiSelect
        selectedItemTemplate={stageSelectedItemTemplate}
        itemTemplate={stageItemTemplate}
        value={this.state.filters.stage}
        options={this.availableStageOptions()}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const etaPortFilter = (
      <DateColumnFilter
        name="eta_to_port"
        value={this.state.filters.eta_to_port}
        onChange={e => this.onFilterChange(e)}
      />
    );

    return (
      <div id="container_list_page_container" className="page-container">
        <div id="filter-box" className={'p-d-flex'}>
          <ContainerFilterBoxComponent
            inboundBoxesCount={this.state.inboundBoxesCount}
            inboundContainersCount={this.state.inboundContainersCount}
            inProgressBoxesCount={this.state.inProgressBoxesCount}
            inProgressContainersCount={this.state.inProgressContainersCount}
            showDoneContainers={this.state.filters.showDone}
            handleFilterChange={this.onFilterChange}
          />
        </div>

        <TwoDataTable
          pageSizeIdentifier={'container_list_page_container'}
          sizeIdentifiers={['filter-box']}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={this.state.activeFilters}
          rowExpansionTemplate={data => this.rowExpansionTemplate(data)}
          expandedRows={this.state.expandedRows}
          onRowToggle={e => {
            this.setState({expandedRows: e.data});
          }}
          selectedItems={[]}
        >
          <Column expander className={'table-expander'} bodyClassName={'table-expander'} />
          <Column
            header="Number"
            field="id"
            filter
            filterElement={idFilter}
            sortable
            body={this.idBodyTemplate}
            showFilterMenu={false}
            style={{width: '150px'}}
          />
          <Column
            header="Warehouse"
            field="unload_at.name"
            filter
            filterElement={warehouseFilter}
            sortable
            showFilterMenu={false}
            style={{width: '100px'}}
          />
          <Column
            header="Status"
            field="stage"
            body={this.stageBodyTemplate}
            filter
            filterElement={stageFilter}
            sortable
            showFilterMenu={false}
            style={{width: '150px'}}
          />
          <Column
            header="Eta Port"
            field="eta_to_port"
            filter
            filterElement={etaPortFilter}
            sortable
            body={this.etaToPortBodyTemplate}
            showFilterMenu={false}
            style={{width: '150px'}}
          />
          <Column
            header="Size"
            field="size"
            filter
            filterElement={sizeFilter}
            sortable
            showFilterMenu={false}
            style={{width: '100px'}}
          />
          <Column
            header="Boxes"
            field="boxes"
            body={this.boxesBodyTemplate}
            showFilterMenu={false}
            style={{width: '50px'}}
          />
        </TwoDataTable>

        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default ContainerListComponent;
