import React from 'react';
import {AppContext} from 'two-app-ui';
import {History} from 'history';
import {Container} from 'two-core';
import GroupOrdersComponent from '../GroupOrders/GroupOrdersComponent';

interface Props {
  container: Container;
  history: History;
}

interface State {
  container: Container;
}

class ContainerOrders extends React.Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      container: {
        supplier: '',
        destination_state_id: '',
        unload_at_id: 0,
        size: '',
      },
    };
  }

  componentDidMount() {
    this.setContainer(this.props.container);
  }

  setContainer(container: Container) {
    this.setState({
      container: container,
    });
  }

  render() {
    const {container} = this.state;

    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        {container?.id && <GroupOrdersComponent container={container} />}
      </div>
    );
  }
}

export default ContainerOrders;
