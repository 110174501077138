import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Tooltip} from 'primereact/tooltip';
import {InputText} from 'primereact/inputtext';
import {Toast} from 'primereact/toast';
import {AppContext, TwoDataTable, UsersService, ToastService} from 'two-app-ui';
import {Address, FreightOrder, QueryParameter, Run, Stop, User} from 'two-core';
import RunsService from '../../services/RunsService';
import config from '../../config/config';
import StopsService from '../../services/StopsService';
import FreightOrdersService from '../../services/FreightOrdersService';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/pro-regular-svg-icons';

interface Props {
  reloadData?: boolean;
}

interface State {
  loading: boolean;
  items: Run[];
  totalItems: number;
  activeFilters: {};
  filters: {name: string};
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  freightOrders: FreightOrder[];
  stops: Stop[];
  users: User[];
}

class ScheduleRunListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  runsService: RunsService | null = null;
  stopsService: StopsService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  usersService: UsersService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props = {}) {
    super(props);
    this.state = {
      items: [],
      totalItems: 0,
      loading: true,
      activeFilters: {},
      filters: {
        name: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      freightOrders: [],
      stops: [],
      users: [],
    };

    this.toast = React.createRef();

    this.ordersBodyTemplate = this.ordersBodyTemplate.bind(this);
    this.stopsBodyTemplate = this.stopsBodyTemplate.bind(this);
    this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
  }

  async componentDidMount() {
    this.runsService = this.context.runsService;
    this.stopsService = this.context.stopsService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.usersService = this.context.usersService;
    this.toastService = this.context.toastService;

    this.loadData();
    this.loadStops();
    this.loadFreightOrders();
    this.loadUsers();
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    if (this.props.reloadData !== props.reloadData) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];
    const sortBy: string[] = [];

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.name,
          condition: 'iLike',
        })
      );
    }

    filters.push(
      JSON.stringify({
        field: 'stage',
        value: ['Draft'],
        condition: 'in',
      })
    );

    filters.push(
      JSON.stringify({
        field: 'state_id',
        value: localStorage.getItem('current state'),
      })
    );

    this.setState({activeFilters: {...filters}});

    const sortByField = this.state.sortBy?.field ?? 'name';

    sortBy.push(
      JSON.stringify({
        field: sortByField,
        direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
      })
    );

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    this.runsService
      ?.getRuns(params)
      .then(data => {
        const dataRecords = (data.records as Run[]) ?? [];

        this.setState({
          items: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async loadStops() {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'run.stage',
        value: 'Draft',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.stopsService
      ?.getStops(params)
      .then(data => {
        const dataRecords = (data.records as Stop[]) ?? [];

        this.setState({
          stops: dataRecords,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, stop records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async loadFreightOrders() {
    const filters: string[] = [];
    const sortBy: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'run.stage',
        value: 'Draft',
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const dataRecords = (data.records as FreightOrder[]) ?? [];

        this.setState({
          freightOrders: dataRecords,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, order records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  loadUsers() {
    const params: QueryParameter = {
      aggregate: false,
    };
    this.usersService
      ?.getUsers(params)
      .then(data => {
        const dataRecords = (data.records as User[]) ?? [];

        this.setState({
          users: dataRecords,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, user records load failed, please try again.');
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stopTypingDetection);
  };

  nameBodyTemplate(rowData: Run) {
    return <span>{rowData.name}</span>;
  }

  stopsBodyTemplate(run: Run) {
    const runStops = run.stops?.filter(s => s !== null) ?? [];
    if (runStops.length > 0) {
      const ids = runStops.map(s => s.id?.toString() ?? '');
      const allStops = this.state.stops;
      const stops = allStops.filter(s => ids.includes(s.id?.toString() ?? ''));
      const state = localStorage.getItem('current state') ?? '';
      const tooltipElement = (
        <div className="p-d-flex p-flex-column">
          {stops.map(stop => {
            const tasks = stop.tasks?.filter(t => t !== null) ?? [];
            const executedTasks = tasks.filter(t => t.executed_on !== null);

            const lastTask = executedTasks.sort(
              (a, b) =>
                (b.executed_on ? new Date(b.executed_on.toString()).getTime() : 0) -
                (a.executed_on ? new Date(a.executed_on.toString()).getTime() : 0)
            )[0];

            const lastTaskDate = lastTask?.executed_on ? new Date(lastTask.executed_on) : undefined;

            const user = this.state.users.find(u => u.id === lastTask?.executed_by_id);
            const doneValue = lastTaskDate
              ? `${user?.username ?? ''} ${DateTime.fromJSDate(lastTaskDate).toFormat(formats.dateTime)}`
              : '';

            const location = stop.stop_location;
            let locationName = '';
            if (location) {
              if (location.state_id === state) {
                locationName = location.name;
              } else {
                locationName = `${location.name} ${location.state_id}`;
              }
            }

            return (
              <div key={stop.id} className="p-d-flex p-flex-row p-col-12 p-p-0 p-mb-1">
                <span className="p-d-flex p-col-4 p-p-0">{locationName}</span>
                <span className="p-d-flex p-col-2 p-p-0">{`${stop.stage}`}</span>
                <span className="p-d-flex p-jc-end p-col-2 p-p-0">{`${executedTasks.length}/${tasks.length}`}</span>
                <span className="p-d-flex p-jc-end p-col-4 p-p-0">{`${doneValue}`}</span>
              </div>
            );
          })}
        </div>
      );

      return (
        <>
          <Tooltip className="custom-tooltip" target={`#stops-${run.id}`} position="right">
            {tooltipElement}
          </Tooltip>
          <span id={`stops-${run.id}`}>{stops.length}</span>
        </>
      );
    } else {
      return 0;
    }
  }

  ordersBodyTemplate(run: Run) {
    const orders =
      this.state.freightOrders.filter(
        fo => fo !== null && fo.runs?.map(r => r.id?.toString()).includes(run.id?.toString() ?? '')
      ) ?? [];
    if (orders.length > 0) {
      const tooltipElement = (
        <div className="p-d-flex p-flex-column">
          {orders.map(freightOrder => {
            return (
              <div key={freightOrder.id} className="p-d-flex p-flex-row p-col-12 p-p-0 p-mb-1">
                <span className="p-d-flex p-col-2 p-p-0">{`${freightOrder.owner_company?.account_number ?? ''}`}</span>
                <span className="p-d-flex p-col-4 p-p-0">{`${
                  (freightOrder.order?.shipping_address as Address).suburb ?? ''
                }`}</span>
                <span className="p-d-flex p-col-4 p-p-0">{`${freightOrder.order?.reference}`}</span>
                <span className="p-d-flex p-jc-end p-col-2 p-p-0">{`${
                  freightOrder.shipment_items?.filter(si => si !== null)?.length ?? 0
                }`}</span>
              </div>
            );
          })}
        </div>
      );

      return (
        <>
          <Tooltip className="custom-tooltip" target={`#orders-${run.id}`} position="right">
            {tooltipElement}
          </Tooltip>
          <span id={`orders-${run.id}`}>{orders.length}</span>
        </>
      );
    } else {
      return 0;
    }
  }

  draggableBodyTemplate(rowData: FreightOrder) {
    return (
      <React.Fragment>
        <div id={rowData.id?.toString()} className="drag-el">
          <FontAwesomeIcon icon={faBars} />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const nameFilter = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    return (
      <div id="run_list_page_container" className="page-container">
        <TwoDataTable
          pageSizeIdentifier={'run_list_page_container'}
          sizeIdentifiers={[]}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={this.state.activeFilters}
          dataKey="id"
          selectedItems={[]}
        >
          <Column body={this.draggableBodyTemplate} />
          <Column
            header="Name"
            field="name"
            filter
            body={this.nameBodyTemplate}
            filterElement={nameFilter}
            sortable
            showFilterMenu={false}
            style={{width: '150px'}}
            className={'p-jc-center'}
          />

          <Column
            header="Stops"
            body={this.stopsBodyTemplate}
            showFilterMenu={false}
            style={{width: '150px'}}
            className={'p-jc-center'}
          />
          <Column
            header="Orders"
            body={this.ordersBodyTemplate}
            showFilterMenu={false}
            style={{width: '150px'}}
            className={'p-jc-center'}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default ScheduleRunListComponent;
