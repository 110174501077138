import React from 'react';
import {AppContext, AppMenuItem, AppMenuItemTemplate, MessageService, ToastService} from 'two-app-ui';
import {History} from 'history';
import {
  FreightOrder,
  FreightOrderPatch,
  FreightStage,
  OrderPatch,
  QueryParameter,
  Run,
  Stop,
  Task,
  TimeLineEvent,
  TleContentUnassigned,
  TleLink,
} from 'two-core';
import StopListComponent from '../Stops/StopListComponent';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import FreightOrdersService from '../../services/FreightOrdersService';
import OrdersService from '../../services/OrdersService';
import StopsService from '../../services/StopsService';
import TasksService from '../../services/TasksService';
import TlesService from '../../services/TlesService';
import RemoveStopWarningDialog from './RemoveStopWarningDialog';
import RescheduleStopDialog from './RescheduleStopDialog';

interface Props {
  run: Run;
  history: History;
  toast: React.RefObject<Toast>;
}

interface State {
  run: Run;
  selectedItems: Stop[];
  freightOrders: FreightOrder[];
  showRemoveStopWarningDialog: boolean;
  showRescheduleStopDialog: boolean;
}

class RunStops extends React.Component<Props, State> {
  static contextType = AppContext;

  stopsService: StopsService | null = null;
  tasksService: TasksService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  ordersService: OrdersService | null = null;
  tlesService: TlesService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      run: {
        state_id: '',
        stage: 'Draft',
        name: '',
      },
      selectedItems: [],
      freightOrders: [],
      showRemoveStopWarningDialog: false,
      showRescheduleStopDialog: false,
    };

    this.initMenuItems = this.initMenuItems.bind(this);
    this.delete = this.delete.bind(this);
    this.deleteStops = this.deleteStops.bind(this);
    this.hideRescheduleStopDialog = this.hideRescheduleStopDialog.bind(this);
    this.hideRemoveStopWarningDialog = this.hideRemoveStopWarningDialog.bind(this);
  }

  componentDidMount() {
    this.stopsService = this.context.stopsService;
    this.tasksService = this.context.tasksService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.tlesService = this.context.tlesService;
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;

    this.setRun(this.props.run);
  }

  setRun(run: Run) {
    this.setState({
      run: run,
    });
    this.loadFreightOrders(run.id?.toString() ?? '');
  }

  async loadFreightOrders(runId: string) {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'run.id',
        value: [runId],
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      aggregate: true,
      filters: filters,
    };

    this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const freightOrders = (data?.records as FreightOrder[]) ?? [];
        this.setState({freightOrders: freightOrders});
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  initMenuItems(selectedItems: Stop[]): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItemsCount = selectedItems.length;

    if (selectedItemsCount > 0) {
      const removeMenu: AppMenuItem = {
        label: 'Remove',
        faIcon: ['far', 'times'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showRemoveStopWarningDialog(selectedItems);
        },
      };
      menuItems.push(removeMenu);

      const rescheduleMenu: AppMenuItem = {
        label: 'Reschedule',
        faIcon: ['far', 'calendar-alt'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showRescheduleStopDialog(selectedItems);
        },
      };
      menuItems.push(rescheduleMenu);
    }

    return menuItems;
  }

  hideRemoveStopWarningDialog() {
    this.setState({
      showRemoveStopWarningDialog: false,
      selectedItems: [],
    });
  }

  showRescheduleStopDialog(stops: Stop[]) {
    this.setState({
      showRescheduleStopDialog: true,
      selectedItems: stops,
    });
  }

  hideRescheduleStopDialog() {
    this.setState({
      showRescheduleStopDialog: false,
    });
  }

  showRemoveStopWarningDialog(stops: Stop[]) {
    const result = stops.every(stop => {
      const pickupStopTasks = stop.tasks?.filter(t => t !== null && t.type === 'pickup') ?? [];
      if (pickupStopTasks.length > 0) {
        return false;
      }
      return true;
    });

    if (!result) {
      this.setState({showRemoveStopWarningDialog: true, selectedItems: stops});
    } else {
      this.delete(stops);
    }
  }

  async delete(stops: Stop[]) {
    const runId = this.state.run.id?.toString() ?? '';
    const freightOrders = this.state.freightOrders;

    const tasks: Task[] = [];
    const stopFreightOrdersIds: string[] = [];
    stops.forEach(stop => {
      const stopTasks = stop.tasks?.filter(t => t !== null) ?? [];
      tasks.push(...stopTasks);

      const ids = stopTasks.map(t => t.freight_order_id ?? '');
      stopFreightOrdersIds.push(...ids);
    });

    const stopFreightOrders: FreightOrder[] = freightOrders.filter(fo => stopFreightOrdersIds.includes(fo.id ?? ''));

    this.deleteStopsAndTasks(tasks, stops, stopFreightOrders, runId);
  }

  async deleteStopsAndTasks(tasks: Task[], allStops: Stop[], freightOrders: FreightOrder[], runId: string) {
    const tlePromises: Promise<void>[] = [];
    const orderPromises: Promise<void>[] = [];
    const stops: Stop[] = [];

    const taskIds = tasks.map(t => t.id);
    allStops.map(stop => {
      const stopTasks = stop.tasks?.filter(t => t !== null && !taskIds.includes(t.id)) ?? [];
      if (stopTasks.length === 0) {
        stops.push(stop);
      }
    });

    const deletePromise = this.deleteTasks(tasks).then(() => {
      return this.deleteStops(stops);
    });
    const state = localStorage.getItem('current state')!;

    freightOrders.forEach(order => {
      if (order.route![state].stage !== 'At Warehouse') {
        const orderPromise = this.updateFreightOrderStage(order, 'At Warehouse');
        orderPromises.push(orderPromise);
      }

      const tle = this.createDeassignedTleEntity(order, runId.toString());
      const promise = this.createTle(order, tle);

      if (promise) {
        tlePromises.push(promise);
      }
    });

    deletePromise
      .then(() => {
        this.hideRemoveStopWarningDialog();
        Promise.all(orderPromises);
        Promise.all(tlePromises);
        this.toastService?.showSuccess(this.props.toast, 'Orders removed from run successfully.');
        MessageService.sendMessage(messages.stopUpdate);
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, Orders delete from run failed, please try again.');
      });
  }

  async deleteTasks(tasks: Task[]) {
    Promise.all(
      tasks.map(async (task: Task) => {
        if (task.id) {
          return this.tasksService?.deleteTask(task.id.toString());
        }
      })
    );
  }

  async deleteStops(stops: Stop[]) {
    Promise.all(
      stops.map(async (stop: Stop) => {
        if (stop.id) {
          return this.stopsService?.deleteStop(stop.id.toString());
        }
      })
    );
  }

  async updateFreightOrderStage(freightOrder: FreightOrder, stage: FreightStage) {
    const state = localStorage.getItem('current state')!;
    const routePart = {...freightOrder.route![state], stage: stage};
    const route = {...freightOrder.route, [state]: routePart};

    const updatedOrder: FreightOrderPatch = {
      route: route,
    };

    await this.freightOrdersService?.updateFreightOrder(freightOrder.id?.toString() ?? '', updatedOrder);
  }

  createDeassignedTleEntity(freightOrder: FreightOrder, runId: string) {
    const tleLink: TleLink = {link_type: 'run', linked_id: runId};
    const content: TleContentUnassigned = {
      message: 'Order was deassigned from a run',
      links: [tleLink],
    };

    const tle: TimeLineEvent = {
      event_type: 'unassigned',
      entity_type: 'order',
      recorded_by: this.getCurrentUserId(),
      entity_id: freightOrder.id ?? '',
      content: content,
      recorded_at: new Date(),
    };
    return tle;
  }

  createTle(freightOrder: FreightOrder, tle: TimeLineEvent) {
    return this.tlesService?.createTle(tle).then(data => {
      if (data) {
        const updatedOrder: OrderPatch = {
          last_event_id: data.id,
        };
        this.ordersService?.updateOrder(freightOrder.id ?? '', updatedOrder);
      }
    });
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';

    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }
  render() {
    const {run} = this.props;

    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <StopListComponent
          key={'run' + run.id}
          id={'_run_stops_' + run.id}
          run={run}
          customMenuItems={stops => this.initMenuItems(stops)}
        />
        <RemoveStopWarningDialog
          showDialog={this.state.showRemoveStopWarningDialog}
          onHide={() => this.hideRemoveStopWarningDialog()}
          deleteStops={() => this.delete(this.state.selectedItems)}
        />
        <RescheduleStopDialog
          showDialog={this.state.showRescheduleStopDialog}
          onHide={() => this.hideRescheduleStopDialog()}
          run={this.state.run}
          toast={this.props.toast}
          stops={this.state.selectedItems}
        />
      </div>
    );
  }
}

export default RunStops;
