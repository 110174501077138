export const messages = {
  containerUpdate: 'container-update',
  stopUpdate: 'stop-update',
  runCreate: 'run-create',
  runOrderCreate: 'run-order-create',
  taskUpdate: 'task-update',
  alarmEdit: 'alarm-edit',
  alarmUpdated: 'alarm-updated',
  locationUpdate: 'location-updated',
  runUpdated: 'run-updated',
  orderUpdated: 'order-updated',
  ordersAssignToRun: 'orders-assign-to-run',
  runOrdersRemoved: 'run-orders-removed',
  proofOrdersChanged: 'proof-orders-changed',
  proofChanged: 'proof-changed',
  removeOrderClicked: 'remove-order-clicked',
  reloadTaskList: 'reload-task-list',
  reloadScheduler: 'reload-scheduler',
};
