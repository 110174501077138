import {Column} from 'primereact/column';
import React from 'react';
import {AppContext, TwoDataTable} from 'two-app-ui';
import {ShipmentItem} from 'two-core';

interface Props {
  boxes: ShipmentItem[];
}

interface State {
  loading: boolean;
  total_boxes: number;
}

class OrderBoxes extends React.Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      total_boxes: this.props.boxes.length ?? 0,
    };

    this.dimensionsTemplate = this.dimensionsTemplate.bind(this);
  }

  dimensionsTemplate(rowData: ShipmentItem) {
    const {height, width, depth} = rowData.dimensions;
    return <span>{height + 'x' + width + 'x' + depth}</span>;
  }

  stageBodyTemplate(rowData: ShipmentItem) {
    return (
      <span className={`stage-badge stage-${(rowData?.stage ?? '').toLowerCase().replaceAll(' ', '-')}`}>
        {rowData?.stage}
      </span>
    );
  }

  render() {
    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="boxes_list_page" className="page-container">
          <TwoDataTable
            pageSizeIdentifier={'boxes_list_page'}
            selectedItems={[]}
            heightToScroll="500px"
            activeFilters={{}}
            loading={this.state.loading}
            value={this.props.boxes}
            totalRecords={this.state.total_boxes}
            sortField="id"
            showPaging={false}
          >
            <Column header="Count" field="count" style={{flex: '1 1'}} />
            <Column header="Stage" field="stage" style={{flex: '1 1'}} body={this.stageBodyTemplate} />
            <Column header="Content" field="content" style={{flex: '1 1'}} />
            <Column header="Dimension" field="dimensions" style={{flex: '1 1'}} body={this.dimensionsTemplate} />
          </TwoDataTable>
        </div>
      </div>
    );
  }
}

export default OrderBoxes;
