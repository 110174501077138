import React from 'react';
import {Card} from 'primereact/card';
import {Toast} from 'primereact/toast';
import {Divider} from 'primereact/divider';
import {AlarmReferenceComponent, AppContext, getCompanyDescription, ToastService} from 'two-app-ui';
import {DateTime} from 'luxon';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faChevronDown, faExternalLink} from '@fortawesome/pro-light-svg-icons';
import {FreightOrder, QueryParameter, FactoryAlarm, Address} from 'two-core';
import {
  faPrint,
  faArrowCircleRight,
  faStickyNote,
  faBars,
  faFileInvoiceDollar,
  faRedo,
} from '@fortawesome/pro-regular-svg-icons';
import {formats} from '../../config/formats';
import FreightOrdersService from '../../services/FreightOrdersService';
import AlarmsService from '../../services/AlarmsService';
import RunReferenceComponent from '../Reference/RunReferenceComponent';

library.add(
  faChevronDown,
  faExternalLink,
  faPrint,
  faArrowCircleRight,
  faStickyNote,
  faBars,
  faFileInvoiceDollar,
  faRedo
);

interface Props {
  freightOrder: FreightOrder;
}

interface State {
  alarms: FactoryAlarm[];
}

class OrderDetail extends React.Component<Props, State> {
  static contextType = AppContext;

  alarmItems: string;
  alarmsService: AlarmsService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;

  constructor(props: Props) {
    super(props);
    this.state = {
      alarms: [],
    };

    this.toast = React.createRef();
    this.alarmItems = '';
    this.priorityTemplate = this.priorityTemplate.bind(this);
  }

  componentDidMount() {
    this.alarmsService = this.context.alarmsService;
    this.toastService = this.context.toastService;

    this.loadAlarms(this.props.freightOrder);
  }

  async loadAlarms(order: FreightOrder) {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'referenced_entity',
        value: 'FreightOrder',
        condition: 'like',
      })
    );

    filters.push(
      JSON.stringify({
        field: 'reference_id',
        value: order.id,
        condition: 'like',
      })
    );

    //some columns have different name in database and map for server
    const sortBy = JSON.stringify({
      field: 'created_on',
      direction: 'ASC',
    });

    const params: QueryParameter = {
      filters: filters,
      orderBys: [sortBy],
    };

    this.alarmsService
      ?.getAlarms(params)
      .then(data => {
        const alarms = data.records as FactoryAlarm[];

        this.setState({
          alarms: alarms,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, alarm records load failed, please try again.');
      });
  }

  priorityTemplate(priority: number | undefined) {
    let priorityText = '';
    switch (priority) {
      case 1:
        priorityText = '!';
        break;
      case 2:
        priorityText = '!!';
        break;
      case 3:
        priorityText = '!!!';
        break;
    }
    return <span>{priorityText}</span>;
  }

  render() {
    const freightOrder = this.props.freightOrder;
    const factoryOrder = this.props.freightOrder.factory_order;
    const order = this.props.freightOrder.order;
    const state = localStorage.getItem('current state') ?? '';
    const stage = freightOrder?.route?.[state].stage ?? '';
    const location = freightOrder.location;

    let locationName = '';
    if (location) {
      if (location.state_id === state) {
        locationName = location.name;
      } else {
        locationName = `${location.name} ${location.state_id}`;
      }
    }

    const companyName = getCompanyDescription(freightOrder.owner_company);

    return (
      <Card className={'p-d-flex order-detail-item'}>
        <div className="p-d-flex">
          <label htmlFor="reference" className="p-col-12 p-md-3">
            reference
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order?.reference ?? ''}</span>
          </div>
        </div>

        {!!companyName?.length && (
          <>
            <div className="p-d-flex">
              <label htmlFor="owner" className="p-col-12 p-md-3">
                owner
              </label>
              <div className="p-col-12 p-md-9">
                <span>{companyName}</span>
              </div>
            </div>
          </>
        )}

        <div className="p-d-flex">
          <label htmlFor="product_line" className="p-col-12 p-md-3">
            product line
          </label>
          <div className="p-col-12 p-md-9">
            <span>{factoryOrder?.product_line ?? 'Shutters'}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="priority" className="p-col-12 p-md-3">
            priority
          </label>
          <div className="p-col-12 p-md-9">{this.priorityTemplate(order?.priority)}</div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="type" className="p-col-12 p-md-3">
            type
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order?.type}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="size" className="p-col-12 p-md-3">
            size
          </label>
          <div className="p-col-12 p-md-9">
            <span>{freightOrder.shipment_items?.length}</span>
          </div>
        </div>

        <div className="p-pl-4 p-pr-4">
          <Divider />
        </div>

        {factoryOrder?.completed_at ? (
          <div className="p-d-flex">
            <label htmlFor="completed_on" className="p-col-12 p-md-3">
              completed on
            </label>
            <div className="p-col-12 p-md-9">
              <span>{DateTime.fromJSDate(factoryOrder.completed_at).toLocaleString()}</span>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="p-d-flex">
          <label htmlFor="production_stage" className="p-col-12 p-md-3">
            freight stage
          </label>
          <div className={'p-col-12 p-md-9'}>
            <span>{stage}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="container" className="p-col-12 p-md-3">
            container
          </label>
          <div className="p-col-12 p-md-9">
            <span>{freightOrder.container?.id}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="location" className="p-col-12 p-md-3">
            current location
          </label>
          <div className="p-col-12 p-md-9">
            <span>{locationName}</span>
          </div>
        </div>

        {freightOrder.at_current_location_since ? (
          <div className="p-d-flex">
            <label htmlFor="submitted" className="p-col-12 p-md-3">
              at current location since
            </label>
            <div className="p-col-12 p-md-9">
              <span>
                {`${
                  freightOrder.at_current_location_since
                    ? DateTime.fromISO(freightOrder.at_current_location_since.toString()).toFormat(formats.date)
                    : ''
                }`}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order?.shipping_address ? (
          <div className="p-d-flex">
            <label htmlFor="shipping_address" className="p-col-12 p-md-3">
              shipping to
            </label>
            <div className="p-col-12 p-md-12 p-lg-9">
              <span>
                {(order.shipping_address as Address).street} {(order.shipping_address as Address).state}{' '}
                {(order.shipping_address as Address).suburb}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {!!freightOrder?.delivery_note?.length && (
          <div className="p-d-flex">
            <label htmlFor="shipping_address" className="p-md-3">
              delivery note
            </label>
            <div className="p-m-2">{freightOrder.delivery_note}</div>
          </div>
        )}

        <div className="p-pl-4 p-pr-4">
          <Divider />
        </div>

        {order?.submitted_by || order?.submitted_at ? (
          <div className="p-d-flex">
            <label htmlFor="submitted" className="p-col-12 p-md-3">
              submitted
            </label>
            <div className="p-col-12 p-md-9">
              <span>
                {`${order?.submitted_by ?? ''} ${
                  order?.submitted_at ? DateTime.fromISO(order.submitted_at.toString()).toFormat(formats.date) : ''
                }`}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order?.approved_by || order?.approved_at ? (
          <div className="p-d-flex">
            <label htmlFor="approved" className="p-col-12 p-md-3">
              approved
            </label>
            <div className="p-col-12 p-md-9">
              <span>
                {`${order?.approved_by ?? ''} ${
                  order?.approved_at ? DateTime.fromISO(order.approved_at.toString()).toFormat(formats.date) : ''
                }`}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {(freightOrder?.delivered_by || freightOrder?.delivered_at) && (
          <div className="p-d-flex">
            <label htmlFor="approved" className="p-col-12 p-md-3">
              delivered
            </label>
            <div className="p-col-12 p-md-9">
              <span>
                {`${freightOrder?.delivered_by ?? ''} ${
                  freightOrder?.delivered_at
                    ? DateTime.fromISO(freightOrder.delivered_at.toString()).toFormat(formats.date)
                    : ''
                }`}
              </span>
            </div>
          </div>
        )}
        <div className="p-pl-4 p-pr-4">
          <Divider />
        </div>

        {this.state.alarms && this.state.alarms.length > 0 && (
          <div className="p-d-flex">
            <label htmlFor="alarms" className="p-col-12 p-md-3">
              alarms
            </label>
            <div className="p-col-12 p-md-9">
              <div className="p-d-flex p-flex-row p-flex-wrap">
                <AlarmReferenceComponent identifier={freightOrder.id ?? ''} factoryAlarms={this.state.alarms ?? []} />
              </div>
            </div>
          </div>
        )}
        {freightOrder.runs && freightOrder.runs.length > 0 && (
          <div className="p-d-flex">
            <label htmlFor="alarms" className="p-col-12 p-md-3">
              runs
            </label>
            <div className="p-col-12 p-md-9">
              <div className="p-d-flex p-flex-row p-flex-wrap">
                <RunReferenceComponent order={freightOrder} />
              </div>
            </div>
          </div>
        )}
      </Card>
    );
  }
}

export default OrderDetail;
