import React from 'react';
import {InputText} from 'primereact/inputtext';
import {Divider} from 'primereact/divider';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {FreightOrder, QueryParameter, Stop, Task, TaskType} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import TasksService from '../../services/TasksService';
import FreightOrdersService from '../../services/FreightOrdersService';
import TaskListComponent from '../Tasks/TaskListComponent';

const typeOptions: TaskType[] = ['drop', 'final-drop', 'other', 'pickup'];

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  stop: Stop;
  notifyRemovedTasks: (tasks: Task[]) => void;
}

interface State {
  task: Task;
  selectedTaskType: TaskType | undefined;
  freightOrdersByLocation: FreightOrder[];
  freightOrdersByDestination: FreightOrder[];
}

class EditTasksDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  tasksService: TasksService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      task: {
        line_up: 1,
        stop_id: 0,
        type: 'pickup',
      },
      selectedTaskType: undefined,
      freightOrdersByLocation: [],
      freightOrdersByDestination: [],
    };

    this.save = this.save.bind(this);
    this.addTask = this.addTask.bind(this);
    this.editTask = this.editTask.bind(this);
    this.loadOrders = this.loadOrders.bind(this);
    this.loadOrdersByDestination = this.loadOrdersByDestination.bind(this);
    this.loadOrdersByLocation = this.loadOrdersByLocation.bind(this);
  }

  componentDidMount() {
    this.tasksService = this.context.tasksService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.toastService = this.context.toastService;
  }

  async loadOrders() {
    this.loadOrdersByDestination();
    this.loadOrdersByLocation();
  }

  async loadOrdersByLocation() {
    const filters: string[] = [];
    const sortBy: string[] = [];

    const locationId = this.props.stop.location_id;

    filters.push(
      JSON.stringify({
        field: 'current_location_id',
        value: locationId,
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      filters: filters,
      aggregate: true,
    };

    this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const dataRecords = ((data?.records as FreightOrder[]) ?? []).filter(se => se !== null);

        this.setState({
          freightOrdersByLocation: dataRecords,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  async loadOrdersByDestination() {
    const filters: string[] = [];
    const sortBy: string[] = [];

    const locationId = this.props.stop.location_id;

    filters.push(
      JSON.stringify({
        field: 'final_destination_id',
        value: locationId,
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      filters: filters,
      aggregate: true,
    };

    this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const dataRecords = ((data?.records as FreightOrder[]) ?? []).filter(se => se !== null);

        this.setState({
          freightOrdersByDestination: dataRecords,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  addTask() {
    this.setEmptyTask();
  }

  editTask(task: Task) {
    this.setState({task: task, selectedTaskType: task.type});
  }

  notifyRemovedTasks(tasks: Task[]) {
    this.props.notifyRemovedTasks(tasks);
  }

  setEmptyTask() {
    const task: Task = {
      line_up: 1,
      stop_id: 0,
      type: 'pickup',
    };

    this.setState({task: task, selectedTaskType: 'pickup'});
  }

  async save() {
    const stop = this.props.stop;
    const stopTasks = stop?.tasks?.filter(t => t !== null).sort((a, b) => b.line_up - a.line_up) ?? [];
    const lastTask = stopTasks[0];
    let taskLineUp = lastTask?.line_up ?? 0;

    const task = this.state.task;
    if (task) {
      task.stop_id = this.props.stop.id ?? 0;
      task.line_up = ++taskLineUp;
      if (task.id) {
        this.updateTask(task);
      } else {
        this.createTask(task);
      }
    }
  }

  async createTask(task: Task) {
    return this.tasksService
      ?.createTask(task)
      .then(data => {
        const stop = this.props.stop;
        const stopTasks = stop.tasks?.filter(t => t !== null) ?? [];
        stopTasks.push(data);
        stop.tasks = stopTasks;

        this.toastService?.showSuccess(this.props.toast, 'Task created successfully.');

        this.setEmptyTask();
        MessageService.sendMessage(messages.taskUpdate);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Task create failed, please try again.');
        console.error('error: ' + error);
      });
  }

  async updateTask(task: Task) {
    const id = task.id;
    if (id) {
      return this.tasksService
        ?.updateTask(id.toString(), task)
        .then(() => {
          this.toastService?.showSuccess(this.props.toast, 'Task updated successfully.');

          this.setEmptyTask();
          MessageService.sendMessage(messages.taskUpdate);
        })
        .catch(error => {
          this.toastService?.showError(this.props.toast, 'Sorry, Task update failed, please try again.');
          console.error('error: ' + error);
        });
    }
  }

  setDescription(value: string) {
    const task = this.state.task;
    const updatedTask = {...task, description: value ?? ''};
    this.setState({task: updatedTask});
  }

  setLineUp(value: number) {
    const task = this.state.task;
    const updatedTask = {...task, line_up: value};
    this.setState({task: updatedTask});
  }

  setOrder(params: DropdownChangeParams) {
    const task = this.state.task;
    const value = params.value as string;
    const updatedTask = {...task, freight_order_id: value};
    this.setState({task: updatedTask});
  }

  setType(params: DropdownChangeParams) {
    const task = this.state.task;
    const value = params.value as TaskType;

    if (value === 'other') {
      task.freight_order_id = undefined;
    }

    const updatedTask = {...task, type: value};
    this.setState({task: updatedTask, selectedTaskType: value});
  }

  render() {
    const {task, selectedTaskType, freightOrdersByDestination, freightOrdersByLocation} = this.state;
    const stop = this.props.stop;
    const location = stop?.stop_location;
    const state = localStorage.getItem('current state') ?? '';

    let locationName = '';
    if (location) {
      if (location.state_id === state) {
        locationName = location.name;
      } else {
        locationName = `${location.name} ${location.state_id}`;
      }
    }

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="type" className="p-col-1">
            type
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <Dropdown
                className="p-d-flex w-100"
                value={task.type}
                options={typeOptions}
                onChange={e => this.setType(e)}
              />
            </span>
          </div>
          {selectedTaskType !== 'other' && (
            <>
              <label htmlFor="order" className="p-col-1">
                order
              </label>
              <div className="p-col-5 p-p-0">
                <span className="p-fluid">
                  <Dropdown
                    className="p-d-flex w-100"
                    value={task.freight_order_id}
                    options={selectedTaskType === 'pickup' ? freightOrdersByLocation : freightOrdersByDestination}
                    onChange={e => this.setOrder(e)}
                    optionLabel={'id'}
                    optionValue={'id'}
                  />
                </span>
              </div>
            </>
          )}
        </div>

        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pb-0">
          <label htmlFor="description" className="p-col-1">
            description
          </label>
          <div className="p-col-11 p-p-0">
            <span className="p-fluid">
              <InputText
                value={task?.description ?? ''}
                onChange={e => {
                  const name = e.target.value;
                  this.setDescription(name);
                }}
              />
            </span>
          </div>
        </div>
        <Divider />
        <TaskListComponent
          key={'task-list-' + this.props.stop?.id ?? 0}
          taskIds={this.props.stop?.tasks?.filter(t => t !== null).map(t => t.id ?? 0) ?? []}
          addTask={() => this.addTask()}
          editTask={task => this.editTask(task)}
          notifyRemovedTasks={tasks => this.notifyRemovedTasks(tasks)}
        />
      </>
    );

    return (
      <TwoDialog
        headerTitle={`Tasks of Stop ${stop?.line_up ?? ''} ${locationName}`}
        showDialog={this.props.showDialog}
        width={80}
        height={80}
        onHide={this.props.onHide}
        onSave={this.save}
        loading={false}
        onShow={this.loadOrders}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default EditTasksDialog;
