import React from 'react';
import {AppContext, MessageService, TwoDialog} from 'two-app-ui';
import {FreightOrder} from 'two-core';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import OrderListComponent from '../Orders/OrderListComponent';
import {messages} from '../../config/messages';
import {Button} from 'primereact/button';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  proofOrders: FreightOrder[];
  onAddOrders: (orders: FreightOrder[]) => void;
  stopId?: number;
}

interface State {
  loading: boolean;
  selectedOrders: FreightOrder[];
}

export class AddOrdersToProofDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersToAddDroppableId = 'orders_to_add';
  addedOrdersDroppableId = 'added_orders_droppable';

  constructor(props: Props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onAddOrders = this.onAddOrders.bind(this);

    this.state = {loading: false, selectedOrders: []};
  }

  componentDidMount() {}

  async loadData() {
    this.setState({loading: true});
    this.setState({loading: false});
  }

  onHide() {
    this.setState({
      loading: false,
      selectedOrders: [],
    });
    this.props.onHide();
  }

  onShow() {
    this.loadData();
  }

  onAddOrders() {
    const {onAddOrders} = this.props;
    const {selectedOrders} = this.state;
    onAddOrders(selectedOrders);
    this.onHide();
  }

  onDragEnd(result: DropResult) {
    const {selectedOrders} = this.state;
    const {source, destination, draggableId} = result;
    const destinationId = destination?.droppableId;
    const sourceId = source?.droppableId;
    if (destinationId === sourceId) {
      return;
    }
    const draggedOrder = JSON.parse(draggableId) as FreightOrder;
    if (draggedOrder) {
      if (destinationId === this.addedOrdersDroppableId) {
        this.setState({selectedOrders: [...selectedOrders, draggedOrder]}, () =>
          MessageService.sendMessage(messages.proofOrdersChanged)
        );
        return;
      }
      if (destinationId === this.ordersToAddDroppableId) {
        this.setState({selectedOrders: selectedOrders.filter(order => order.id !== draggedOrder.id)}, () =>
          MessageService.sendMessage(messages.proofOrdersChanged)
        );
        return;
      }
    }
  }

  render() {
    const {showDialog, proofOrders, stopId} = this.props;
    const {selectedOrders} = this.state;
    const selectedOrderIds = selectedOrders.map(order => order.id!);
    const excludedOrderIds = [...selectedOrderIds, ...proofOrders.map(order => order.id!)];
    const footer = (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="Cancel" className="p-mr-2 p-button-text" onClick={this.onHide} />
        <Button label="Save" className="p-mr-2" onClick={this.onAddOrders} />
      </div>
    );
    return (
      <>
        <TwoDialog
          header="Add Order(s) to proof"
          footer={footer}
          loading={false}
          onHide={this.onHide}
          onShow={this.onShow}
          showDialog={showDialog}
          style={{width: '75vw'}}
          breakpoints={{'768px': '80vw', '576px': '90vw'}}
          draggable={false}
        >
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="p-d-flex p-col-12 p-mb-4 p-mt-2 p-p-0">
              <OrderListComponent
                hideMenu={true}
                isAll={false}
                heightToScroll="300px"
                freightOrderExcludedIds={excludedOrderIds}
                droppableId={this.ordersToAddDroppableId}
                customEmptyMessage="No orders to assign"
                stopId={stopId}
              />
            </div>
            <div className="p-d-flex p-col-12 p-mb-4 p-mt-2 p-p-0">
              <OrderListComponent
                hideMenu={true}
                isAll={false}
                heightToScroll="300px"
                freightOrderIds={selectedOrderIds}
                droppableId={this.addedOrdersDroppableId}
                customEmptyMessage="No assigned orders"
              />
            </div>
          </DragDropContext>
        </TwoDialog>
      </>
    );
  }
}
