import React from 'react';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {AppContext, TwoDialog} from 'two-app-ui';
import {QueryParameter, Vehicle} from 'two-core';
import {Toast} from 'primereact/toast';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import formats from '../../config/formats';
import VehiclesService from '../../services/VehiclesService';

interface Props {
  runsIds: string[];
  showDialog: boolean;
  onHide: () => void;
  defaultVehicle?: Vehicle;
  defaultDate: Date;
  toast: React.RefObject<Toast>;
  updateScheduleEntry: (id: string, startDate: Date, endDate: Date, vehicleId: number | undefined) => void;
}

interface State {
  vehicle?: Vehicle;
  vehicles: Vehicle[];
  date?: Date;
}

class ReScheduleDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  vehiclesService: VehiclesService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      vehicles: [],
    };

    this.save = this.save.bind(this);
    this.onChangeVehicle = this.onChangeVehicle.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onShow = this.onShow.bind(this);
  }

  async componentDidMount() {
    this.vehiclesService = this.context.vehiclesService;

    this.loadData();
  }

  async loadData() {
    const sortBy: string[] = [];
    sortBy.push(
      JSON.stringify({
        field: 'name',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      aggregate: true,
    };

    await this.vehiclesService
      ?.getVehicles(params)
      .then(data => {
        const dataRecords = (data?.records as Vehicle[]) ?? [];
        const vehicles = dataRecords.map(v => {
          const vehicle = {...v, id: parseInt(v.id?.toString() ?? '')};
          return vehicle;
        });
        this.setState({
          vehicles: vehicles,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  async onShow() {
    await this.setState({
      vehicle: this.props.defaultVehicle,
      date: this.props.defaultDate,
    });
  }

  async onChangeVehicle(e: DropdownChangeParams) {
    this.setState({vehicle: e.value as Vehicle});
  }

  async onChangeDate(e: CalendarChangeParams) {
    this.setState({date: e.value as Date});
  }

  async save() {
    const date = this.state.date ?? this.props.defaultDate;
    for (const runId of this.props.runsIds) {
      this.props.updateScheduleEntry(runId, date, date, this.state.vehicle?.id);
    }
    this.props.onHide();
  }

  render() {
    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-6 p-pl-0 p-pt-0">
          <label htmlFor="vehicle" className="p-col-3">
            vehicle
          </label>
          <div className="p-col-9 p-p-0">
            <Dropdown
              id={'vehicle'}
              className="p-d-flex"
              value={this.state.vehicle}
              options={this.state.vehicles}
              onChange={this.onChangeVehicle}
              optionLabel="name"
            />
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-col-6 p-pr-0 p-pb-0">
          <label htmlFor="date" className="p-col-3">
            date
          </label>
          <div className="p-col-9 p-p-0">
            <Calendar
              id="date"
              value={this.state?.date}
              dateFormat={formats.calendarInputDate}
              onChange={this.onChangeDate}
              showIcon
            />
          </div>
        </div>
      </>
    );
    return (
      <TwoDialog
        headerTitle={'Re-schedule runs'}
        showDialog={this.props.showDialog}
        width={50}
        height={50}
        onHide={this.props.onHide}
        onSave={this.save}
        loading={false}
        onShow={this.onShow}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default ReScheduleDialog;
