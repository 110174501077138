import React from 'react';
import {History} from 'history';
import {Container} from 'two-core';
import {DateTime} from 'luxon';
import formats from '../../config/formats';

interface Props {
  container: Container;
  history: History;
}

interface State {
  container: Container;
}

class ContainerDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      container: {
        supplier: '',
        destination_state_id: '',
        unload_at_id: 0,
        size: '',
      },
    };
  }

  componentDidMount() {
    this.setContainer(this.props.container);
  }

  setContainer(container: Container) {
    this.setState({
      container: container,
    });
  }

  render() {
    const {container} = this.state;
    const state = localStorage.getItem('current state') ?? '';
    const stage = container?.states?.[state] ?? '';

    return (
      container && (
        <>
          <div className="p-d-flex">
            <label htmlFor="stage" className="p-col-12 p-md-3">
              stage
            </label>
            <div className="p-col-12 p-md-9">
              <span>{stage}</span>
            </div>
          </div>

          <div className="p-d-flex">
            <label htmlFor="supplier" className="p-col-12 p-md-3">
              supplier
            </label>
            <div className="p-col-12 p-md-9">
              <span>{container.supplier}</span>
            </div>
          </div>

          <div className="p-d-flex">
            <label htmlFor="supplier" className="p-col-12 p-md-3">
              state
            </label>
            <div className="p-col-12 p-md-9">
              <span>{container.destination_state?.long_name ?? ''}</span>
            </div>
          </div>

          <div className="p-d-flex">
            <label htmlFor="warehouse" className="p-col-12 p-md-3">
              warehouse
            </label>
            <div className="p-col-12 p-md-9">
              <span>{container.unload_at?.name ?? ''}</span>
            </div>
          </div>

          <div className="p-d-flex">
            <label htmlFor="size" className="p-col-12 p-md-3">
              size
            </label>
            <div className="p-col-12 p-md-9">
              <span>{container.size}</span>
            </div>
          </div>

          <div className="p-d-flex">
            <label htmlFor="shipping_route" className="p-col-12 p-md-3">
              shippping route
            </label>
            <div className="p-col-12 p-md-9">
              <span>{container.shipping_route ?? ''}</span>
            </div>
          </div>

          {container.date_leave_factory && (
            <div className="p-d-flex">
              <label htmlFor="delivery_date" className="p-col-12 p-md-3">
                left factory
              </label>
              <div className="p-col-12 p-md-9">
                <span>{DateTime.fromISO(container.date_leave_factory.toString()).toFormat(formats.date)}</span>
              </div>
            </div>
          )}

          {container.eta_to_port && (
            <div className="p-d-flex">
              <label htmlFor="eta" className="p-col-12 p-md-3">
                eta to port
              </label>
              <div className="p-col-12 p-md-9">
                <span>{DateTime.fromISO(container.eta_to_port.toString()).toFormat(formats.date)}</span>
              </div>
            </div>
          )}
        </>
      )
    );
  }
}

export default ContainerDetail;
