import React from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown} from 'primereact/dropdown';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Location} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import LocationsService from '../../services/LocationsService';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  location?: Location;
}

interface State {
  saving: boolean;
  location: Location;
}

class LocationFormDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  locationsService: LocationsService | null = null;
  toastService: ToastService | null = null;
  emptyLocation: Location = {
    type: 'factory',
    name: '',
    address: {
      street: '',
      suburb: '',
      state: '',
      state_short: '',
      postCode: '',
      country: '',
      lat: 0,
      long: 0,
      phoneNumber: '',
    },
    state_id: 'QLD',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      saving: false,
      location: props.location ?? this.emptyLocation, // we make a copy of the location to store the changes the user will do
    };

    this.save = this.save.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.setLocation = this.setLocation.bind(this);
  }

  componentDidMount() {
    this.locationsService = this.context.locationsService;
    this.toastService = this.context.toastService;
  }

  setLocation() {
    this.setState({location: this.props.location ?? this.emptyLocation});
  }

  hideDialog() {
    this.setState({location: this.emptyLocation, saving: false});
    this.props.onHide();
  }

  async save() {
    const location = this.state.location;
    if (location) {
      if (location.id) {
        this.updateLocation(location);
      } else {
        this.createLocation(location);
      }
    }
  }

  async createLocation(location: Location) {
    this.setState({saving: true});

    return this.locationsService
      ?.createLocation(location)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Location created successfully.');

        this.hideDialog();
        MessageService.sendMessage(messages.locationUpdate);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Location create failed, please try again.');
        console.error('error: ' + error);
        this.setState({saving: false});
      });
  }

  async updateLocation(location: Location) {
    this.setState({saving: true});

    return this.locationsService
      ?.updateLocation(location?.id?.toString() ?? '', location)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Location updated successfully.');

        this.hideDialog();
        MessageService.sendMessage(messages.locationUpdate);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Location update failed, please try again.');
        console.error('error: ' + error);
        this.setState({saving: false});
      });
  }

  handleInputChange(title: string, value: string) {
    const location = this.state.location;
    if (location) {
      const updatedLocation = {
        ...location,
        [title]: value,
      };
      this.setState({location: updatedLocation});
    }
  }

  setAddressValue(title: string, value: string) {
    const location = this.state.location;
    if (location) {
      const address = location.address;
      const updatedAddress = {...address, [title]: value};
      const updatedLocation = {
        ...location,
        address: updatedAddress,
      };
      this.setState({location: updatedLocation});
    }
  }

  locationBody(location: Location) {
    const address = location?.address;
    return (
      <>
        <div className="p-field p-grid">
          <label htmlFor="suburb" className="p-col-2">
            suburb
          </label>
          <div className="p-col-10">
            <InputText
              id="suburb"
              value={address?.suburb ?? ''}
              onChange={e => {
                const value = e.target.value;
                this.setAddressValue('suburb', value);
              }}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label htmlFor="street" className="p-col-2">
            street
          </label>
          <div className="p-col-10">
            <InputText
              value={address?.street ?? ''}
              onChange={e => {
                const value = e.target.value;
                this.setAddressValue('street', value);
              }}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label htmlFor="postCode" className="p-col-2">
            post code
          </label>
          <div className="p-col-10">
            <InputText
              value={address?.postCode ?? ''}
              onChange={e => {
                const value = e.target.value;
                this.setAddressValue('postCode', value);
              }}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label htmlFor="state" className="p-col-2">
            state
          </label>
          <div className="p-col-10">
            <Dropdown
              options={['ACT', 'NSW', 'QLD', 'VIC', 'SA', 'WA']}
              value={address?.state ?? ''}
              onChange={e => {
                const value = e.target.value;
                this.setAddressValue('state', value);
              }}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label htmlFor="phoneNumber" className="p-col-2">
            phone
          </label>
          <div className="p-col-10">
            <InputText
              value={address?.phoneNumber ?? ''}
              onChange={e => {
                const value = e.target.value;
                this.setAddressValue('phoneNumber', value);
              }}
            />
          </div>
        </div>
      </>
    );
  }

  dealershipBody(location: Location) {
    const address = location?.address;
    return (
      <>
        <div className="p-field p-grid">
          <label htmlFor="company" className="p-col-2">
            company
          </label>
          <div className="p-col-10">{location?.dealership?.name ?? ''}</div>
        </div>
        <div className="p-field p-grid">
          <label htmlFor="company" className="p-col-2">
            account number
          </label>
          <div className="p-col-10">{location?.dealership?.account_number ?? ''}</div>
        </div>
        <div className="p-field p-grid">
          <label htmlFor="address" className="p-col-2">
            address
          </label>
          <div className="p-col-10">
            {`${address?.street ?? ''}, ${address?.suburb ?? ''}, ${address?.postCode ?? ''}, ${address?.state ?? ''}`}
          </div>
        </div>
        <div className="p-field p-grid">
          <label htmlFor="phone" className="p-col-2">
            phone
          </label>
          <div className="p-col-10">{address?.phoneNumber ?? ''}</div>
        </div>
      </>
    );
  }

  render() {
    const {location} = this.state;

    const dialogBody = (
      <div className="p-fluid w-100 p-mx-2">
        <div className="p-field p-grid">
          <label htmlFor="name" className="p-col-2">
            name
          </label>
          <div className="p-col-10">
            <InputText
              value={location?.name ?? ''}
              onChange={e => {
                const value = e.target.value;
                this.handleInputChange('name', value);
              }}
            />
          </div>
        </div>
        {this.state.location.type === 'dealership' ? (
          <div className="p-field p-grid">
            <label htmlFor="type" className="p-col-2">
              type
            </label>
            <div className="p-col-10">
              <span className="p-fluid">{location?.type ?? ''}</span>
            </div>
          </div>
        ) : (
          <div className="p-field p-grid">
            <label htmlFor="name" className="p-col-2">
              type
            </label>
            <div className="p-col-10">
              <Dropdown
                options={['factory', 'warehouse', 'end-customer', 'service', 'other']}
                value={location?.type ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.handleInputChange('type', value);
                }}
              />
            </div>
          </div>
        )}
        {this.state.location.type === 'dealership' ? this.dealershipBody(location) : this.locationBody(location)}
        <div className="p-field p-grid">
          <label htmlFor="street" className="p-col-2">
            instructions
          </label>
          <div className="p-col-10">
            <InputTextarea
              name={'instructions'}
              value={location?.instructions ?? ''}
              onChange={e => {
                const value = e.target.value;
                this.handleInputChange('instructions', value);
              }}
              rows={5}
            />
          </div>
        </div>
      </div>
    );

    return (
      <TwoDialog
        headerTitle={location?.id ? 'Edit Location' : 'Add Location'}
        showDialog={this.props.showDialog}
        width={50}
        onShow={this.setLocation}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={this.state.saving}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default LocationFormDialog;
