import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {QueryParameter, Run, Stop, Task} from 'two-core';
import {Toast} from 'primereact/toast';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {messages} from '../../config/messages';
import RunsService from '../../services/RunsService';
import StopsService from '../../services/StopsService';
import TasksService from '../../services/TasksService';

interface Props {
  run: Run;
  stops: Stop[];
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
}

interface State {
  run: Run;
  selectedRun: Run | undefined;
  runs: Run[];
  stops: Stop[];
  loading: boolean;
}

class RescheduleStopDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  runsService: RunsService | null = null;
  stopsService: StopsService | null = null;
  tasksService: TasksService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      run: {
        name: '',
        stage: 'Draft',
        state_id: '',
      },
      selectedRun: undefined,
      runs: [],
      stops: [],
      loading: false,
    };

    this.save = this.save.bind(this);
    this.loadData = this.loadData.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
  }

  componentDidMount() {
    this.runsService = this.context.runsService;
    this.stopsService = this.context.stopsService;
    this.tasksService = this.context.tasksService;
    this.toastService = this.context.toastService;
  }

  setRun(run: Run) {
    this.setState({
      run: run,
    });
  }

  async loadData() {
    this.setRun(this.props.run);
    this.loadStops();

    this.setState({loading: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'state_id',
        value: localStorage.getItem('current state'),
      })
    );

    const sortBy: string[] = [];
    sortBy.push(
      JSON.stringify({
        field: 'name',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      aggregate: true,
    };

    this.runsService
      ?.getRuns(params)
      .then(data => {
        const dataRecords = (data?.records as Run[]) ?? [];

        this.setState({
          runs: dataRecords,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async loadStops() {
    this.setState({loading: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'run_id',
        value: this.props.run.id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.stopsService
      ?.getStops(params)
      .then(data => {
        const dataRecords = (data?.records as Stop[]) ?? [];

        this.setState({
          stops: dataRecords,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  save() {
    this.setState({loading: true});
    const selectedRun = this.state.selectedRun;
    const runId = selectedRun?.id;
    if (runId) {
      const stops = this.props.stops;
      const runStops = selectedRun.stops?.filter(s => s !== null) ?? [];
      const promises = stops.map(stop => {
        const runStop = runStops.find(s => s.location_id?.toString() === stop.location_id?.toString());
        if (!runStop) {
          const updatedStop: Stop = {...stop, run_id: runId};
          return this.stopsService?.updateStop(stop.id?.toString() ?? '', updatedStop);
        } else {
          const stopWithTasks = this.state.stops.find(s => s.id?.toString() === stop.id?.toString());
          const stopTasks = stopWithTasks?.tasks?.filter(t => t !== null) ?? [];
          const updatedTask = stopTasks.map(task => {
            const updatedTask: Task = {...task, stop_id: runStop.id ?? 0};
            return updatedTask;
          });
          return this.updateTasks(updatedTask).then(() => {
            return this.stopsService?.deleteStop(stop.id?.toString() ?? '');
          });
        }
      });

      Promise.all(promises)
        .then(() => {
          this.toastService?.showSuccess(this.props.toast, 'Stop updated successfully.');
          this.setState({loading: false});
          MessageService.sendMessage(messages.stopUpdate);
          this.hideDialog();
        })
        .catch(error => {
          this.toastService?.showError(this.props.toast, 'Sorry, Stop update failed, please try again.');
          this.setState({loading: false});
          console.log(error);
        });
    } else {
      this.toastService?.showWarn(this.props.toast, 'Sorry, run is not selected.');
    }
  }

  async updateTasks(tasks: Task[]) {
    Promise.all(
      tasks.map(task => {
        if (task.id) {
          return this.tasksService?.updateTask(task.id.toString(), task);
        }
        return;
      })
    );
  }

  setSelectedRun(e: DropdownChangeParams) {
    const value = e.value;
    this.setState({selectedRun: value});
  }

  hideDialog() {
    this.setState({
      run: {name: '', stage: 'Draft', state_id: ''},
      selectedRun: undefined,
      runs: [],
      loading: false,
    });
    this.props.onHide();
  }

  render() {
    const {run, selectedRun, runs} = this.state;

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0 p-pb-0">
          <label htmlFor="run" className="p-col-2">
            new run
          </label>
          <div className="p-col-10 p-p-0">
            <span className="p-fluid">
              <Dropdown
                className="p-d-flex w-100"
                filter
                value={selectedRun}
                options={runs}
                onChange={e => this.setSelectedRun(e)}
                optionLabel="name"
                showClear
              />
            </span>
          </div>
        </div>
      </>
    );

    return (
      <TwoDialog
        headerTitle={'Move Stop from Run ' + run.name}
        showDialog={this.props.showDialog}
        width={40}
        height={40}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={this.state.loading}
        onShow={this.loadData}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}

export default RescheduleStopDialog;
