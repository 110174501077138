import React from 'react';
import {Button} from 'primereact/button';
import {AppContext, TwoDialog} from 'two-app-ui';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  deleteStops: () => void;
}

class RemoveStopWarningDialog extends React.Component<Props> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);

    this.hideDialog = this.hideDialog.bind(this);
  }

  hideDialog() {
    this.props.onHide();
  }

  render() {
    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="No" className={'p-mr-2 p-button-text'} onClick={() => this.hideDialog()} />
        <Button
          label="Yes"
          onClick={() => {
            this.props.deleteStops();
          }}
          autoFocus
        />
      </div>
    );

    const dialogBody = (
      <div className="p-d-flex">
        <span className="p-fluid">
          You are about to remove a stop in which orders should have been picked-up. If you continue, drop tasks for
          those orders will be removed as well. Do you wish to continue?
        </span>
      </div>
    );

    return (
      <TwoDialog
        headerTitle={'Remove Run Stop Warning'}
        showDialog={this.props.showDialog}
        width={40}
        height={40}
        onHide={this.hideDialog}
        loading={false}
        footer={footer}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default RemoveStopWarningDialog;
