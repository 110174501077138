import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, FreightOrder, FreightOrderPatch, QueryParameter} from 'two-core';

class FreightOrdersService extends ApiService {
  endpoint: string;
  endpointGroup: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.freightOrders ?? '';
    this.endpointGroup = config().endpoints.freightOrdersGroupped ?? '';
  }

  async getFreightOrders(params: QueryParameter): Promise<ApiListResponse> {
    if (!params.filters) {
      params.filters = [];
    }
    params.filters.push(
      JSON.stringify({
        field: `route->>'${localStorage.getItem('current state')}'`,
        condition: 'isNotNull',
      })
    );
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateFreightOrder(id: string, data: FreightOrder | FreightOrderPatch): Promise<FreightOrder> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as FreightOrder);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async getFreightOrdersGrouppedByDeliveryDocket(params: QueryParameter): Promise<ApiListResponse> {
    if (!params.filters) {
      params.filters = [];
    }

    return this.get(this.endpointGroup, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async removeFreightOrderTasks(freightOrder: FreightOrder) {
    return this.delete(`${this.endpoint}/${freightOrder.id}/tasks`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default FreightOrdersService;
