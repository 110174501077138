import React from 'react';
import {History} from 'history';
import {Location} from 'two-core';
import './LocationDetail.scss';

interface Props {
  location: Location;
  history: History;
}

interface State {
  location: Location;
}

class LocationDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      location: {
        address: {
          country: '',
          lat: 0,
          long: 0,
          postCode: '',
          state: '',
          state_short: '',
          street: '',
          suburb: '',
          phoneNumber: '',
        },
        name: '',
        state_id: '',
        type: 'other',
      },
    };
  }

  componentDidMount() {
    this.setLocation(this.props.location);
  }

  setLocation(location: Location) {
    this.setState({
      location: location,
    });
  }

  render() {
    const {location} = this.state;
    return (
      <>
        <div className="p-d-flex">
          <label htmlFor="name" className="p-col-12 p-md-3">
            name
          </label>
          <div className="p-col-12 p-md-9">
            <span>{location.name}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="type" className="p-col-12 p-md-3">
            type
          </label>
          <div className="p-col-12 p-md-9">
            <span>{location.type}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="suburb" className="p-col-12 p-md-3">
            suburb
          </label>
          <div className="p-col-12 p-md-9">
            <span>{location.address.suburb}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="street" className="p-col-12 p-md-3">
            street
          </label>
          <div className="p-col-12 p-md-9">
            <span>{location.address.street}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="postCode" className="p-col-12 p-md-3">
            post code
          </label>
          <div className="p-col-12 p-md-9">
            <span>{location.address.postCode}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="state" className="p-col-12 p-md-3">
            state
          </label>
          <div className="p-col-12 p-md-9">
            <span>{location.address.state}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label htmlFor="state" className="p-col-12 p-md-3">
            phone number
          </label>
          <div className="p-col-12 p-md-9">
            <span>{location.address.phoneNumber}</span>
          </div>
        </div>
        {location.instructions ? (
          <div className="p-d-flex">
            <label htmlFor="state" className="p-col-12 p-md-3">
              instructions
            </label>
            <div className="p-col-12 p-md-9">
              <span>{location.instructions}</span>
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default LocationDetail;
