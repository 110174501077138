import React from 'react';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {QueryParameter, Run} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';

import RunsService from '../../services/RunsService';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  runId: number;
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  run: Run | undefined;
}

class EditDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  runsService: RunsService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      run: undefined,
    };

    this.save = this.save.bind(this);
    this.loadData = this.loadData.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
  }

  componentDidMount() {
    this.runsService = this.context.runsService;
    this.toastService = this.context.toastService;
  }

  async loadData() {
    this.setState({loading: true});

    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: this.props.runId,
        condition: '=',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.runsService
      ?.getRuns(params)
      .then(data => {
        const dataRecords = (data?.records as Run[]) ?? [];
        const run = dataRecords[0];

        this.setState({
          run: run,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({loading: false});
        console.error(error);
      });
  }

  async save() {
    const run = this.state.run;
    if (run) {
      this.updateRun(run);
    } else {
      this.toastService?.showError(this.props.toast, 'Sorry, Run update failed, please try again.');
    }
  }

  async updateRun(run: Run) {
    this.setState({loading: true});

    const id = run.id;
    if (id) {
      return this.runsService
        ?.updateRun(id.toString(), run)
        .then(() => {
          this.toastService?.showSuccess(this.props.toast, 'Run updated successfully.');

          this.hideDialog();
          MessageService.sendMessage(messages.runUpdated);
        })
        .catch(error => {
          this.toastService?.showError(this.props.toast, 'Sorry, Run update failed, please try again.');
          this.setState({loading: false});
          console.error('error: ' + error);
        });
    }
  }

  hideDialog() {
    this.setState({run: undefined, loading: false});
    this.props.onHide();
  }

  setName(value: string) {
    const run = this.state.run;
    if (run) {
      const updatedTask: Run = {...run, stage: run.stage, name: value ?? ''};
      this.setState({run: updatedTask});
    }
  }

  render() {
    const {run} = this.state;

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0 p-pb-0">
          <label htmlFor="name" className="p-col-1">
            name
          </label>
          <div className="p-col-5 p-p-0">
            <InputText
              className="p-d-flex w-100"
              value={run?.name ?? ''}
              onChange={e => {
                const desc = e.target.value;
                this.setName(desc);
              }}
            />
          </div>
        </div>
      </>
    );
    return (
      <TwoDialog
        headerTitle={'Edit Run Name'}
        showDialog={this.props.showDialog}
        width={50}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={this.state.loading}
        onShow={this.loadData}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default EditDialog;
