import React from 'react';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faSirenOn} from '@fortawesome/pro-regular-svg-icons';
import {AppContext, ReferenceComponent, ReferenceType} from 'two-app-ui';
import {FreightOrder, FreightOrderAggregate, FreightProof, QueryParameter} from 'two-core';
import FreightOrdersService from '../../services/FreightOrdersService';

library.add(faSirenOn);

interface Props {
  proof: FreightProof;
}

interface State {
  orders: FreightOrder[];
}

class FreightOrdersProofReference extends React.Component<Props, State> {
  static contextType = AppContext;

  freightOrdersService?: FreightOrdersService;

  constructor(props: Props) {
    super(props);
    this.state = {orders: []};
  }

  async componentDidMount() {
    this.freightOrdersService = this.context.freightOrdersService;
    this.loadOrders = this.loadOrders.bind(this);
  }

  tooltipElement(orders: FreightOrder[]) {
    return (
      <table>
        {orders.map((freightOrder: FreightOrder) => (
          <tr key={freightOrder.id}>
            <td>{freightOrder.owner_company?.name}</td>
            <td>
              <strong>{freightOrder.order?.reference}</strong>
            </td>
            <td className="p-text-nowrap">{freightOrder.order?.id}</td>
          </tr>
        ))}
      </table>
    );
  }

  onTooltipShow = async () => {
    const {proof} = this.props;
    const {orders} = this.state;
    if (!proof.order_ids?.length) {
      return;
    }
    if (!orders.length) {
      const orders = await this.loadOrders(proof.order_ids);
      if (orders) {
        this.setState(() => ({orders: orders}));
      }
    }
  };

  async loadOrders(ids: string[]) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: ids,
        condition: 'in',
      })
    );

    const aggregate: FreightOrderAggregate[] = ['order', 'owner_company'];
    const params: QueryParameter = {
      filters: filters,
      aggregate,
    };
    return this.freightOrdersService?.getFreightOrders(params).then(data => {
      return (data.records ?? []) as FreightOrder[];
    });
  }

  render() {
    const {proof} = this.props;
    const {orders} = this.state;
    const orderIds = proof.order_ids;
    if (!orderIds?.length) {
      return <></>;
    }
    let label;
    if (orderIds.length > 1) {
      label = `Multiple (${orderIds.length})`;
    } else {
      label = orderIds[0];
    }
    return (
      <ReferenceComponent
        key={'freight-orders-proof-reference-component-' + proof.id}
        label={label}
        index={'freight-orders-proof-reference-' + proof.id}
        faIcon={['fas', 'list']}
        type={ReferenceType.DEFAULT}
        tooltipElement={this.tooltipElement(orders)}
        onTooltipShow={this.onTooltipShow}
      />
    );
  }
}

export default FreightOrdersProofReference;
