import React from 'react';
import {AppContext, AppMenuItem, AppMenuItemTemplate, MessageService, ToastService} from 'two-app-ui';
import {Run, Stop, Task} from 'two-core';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import FreightOrdersService from '../../services/FreightOrdersService';
import OrdersService from '../../services/OrdersService';
import StopsService from '../../services/StopsService';
import TasksService from '../../services/TasksService';
import TlesService from '../../services/TlesService';
import TaskListComponent from '../Tasks/TaskListComponent';
import AddTaskDialog from './AddTaskDialog';
import {messages} from '../../config/messages';

interface Props {
  run: Run;
  toast: React.RefObject<Toast>;
}

interface State {
  stops: Stop[];
  selectedItems: Task[];
  showEditTaskDialog: boolean;
}

class RunOthers extends React.Component<Props, State> {
  static contextType = AppContext;

  stopsService: StopsService | null = null;
  tasksService: TasksService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  ordersService: OrdersService | null = null;
  tlesService: TlesService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      stops: [],
      selectedItems: [],
      showEditTaskDialog: false,
    };

    this.initMenuItems = this.initMenuItems.bind(this);
  }

  componentDidMount() {
    this.stopsService = this.context.stopsService;
    this.tasksService = this.context.tasksService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.tlesService = this.context.tlesService;
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;
  }

  initMenuItems(selectedItems: Task[]): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItemsCount = selectedItems.length;

    const addMoreMenu: AppMenuItem = {
      label: 'Add',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAddTaskDialog();
      },
    };
    menuItems.push(addMoreMenu);

    if (selectedItemsCount > 0) {
      const removeMenu: AppMenuItem = {
        label: 'Remove',
        faIcon: ['far', 'times'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.deleteTasks(selectedItems);
        },
      };
      menuItems.push(removeMenu);
    }
    return menuItems;
  }

  showAddTaskDialog() {
    this.setState({showEditTaskDialog: true});
  }

  async deleteTasks(tasks: Task[]) {
    Promise.all(
      tasks.map(async (task: Task) => {
        return this.tasksService?.deleteTask(task.id!.toString());
      })
    )
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Tasks deleted successfully.');
        MessageService.sendMessage(messages.runUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, tasks delete failed, please try again.');
        console.error(error);
      });
  }

  onEditDialogHide = () => {
    this.setState({showEditTaskDialog: false});
  };

  render() {
    const {run} = this.props;
    const {showEditTaskDialog} = this.state;
    const taskIds = run.tasks?.filter(task => !task.freight_order_id).map(task => task.id!);

    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        {run.id && <TaskListComponent taskIds={taskIds} customMenuItems={this.initMenuItems} inRunDetail={true} />}
        <AddTaskDialog showDialog={showEditTaskDialog} onHide={this.onEditDialogHide} run={run} />
      </div>
    );
  }
}

export default RunOthers;
