import React, {ReactNode} from 'react';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Location, MapOf, QueryParameter, Run, Stop, StopPatch, Task, TaskPatch} from 'two-core';
import {Toast} from 'primereact/toast';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {messages} from '../../config/messages';
import TasksService from '../../services/TasksService';
import {SelectButton, SelectButtonChangeParams, SelectButtonChangeTargetOptions} from 'primereact/selectbutton';
import {TaskType} from 'two-core/build/cjs/src/task';
import LocationsService from '../../services/LocationsService';
import StopsService from '../../services/StopsService';

type DialogType = 'PickupAndDrop' | 'Single';
const dialogTypeSelectOptions: SelectButtonChangeTargetOptions[] = [
  {name: 'Pick-up & Drop Tasks', value: 'PickupAndDrop', id: 'PickupAndDrop'},
  {name: 'Single Task', value: 'Single', id: 'Single'},
];

interface Props {
  showDialog: boolean;
  onHide: () => void;
  run: Run;
}

interface State {
  loading: boolean;
  dialogType: DialogType;
  locations: Location[];
  taskPatchesMap: MapOf<TaskPatch>; // taskType => taskPatch
  selectedLocationIdsMap: MapOf<string>; // taskType => locationId
}

class AddTaskDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  toastService: ToastService | null = null;
  tasksService: TasksService | null = null;
  locationsService: LocationsService | null = null;
  stopsService: StopsService | null = null;

  toast: React.RefObject<Toast>;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      dialogType: 'Single',
      locations: [],
      taskPatchesMap: {},
      selectedLocationIdsMap: {},
    };

    this.onSave = this.onSave.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onTypeChanged = this.onTypeChanged.bind(this);

    this.toast = React.createRef();
  }

  async componentDidMount() {
    this.toastService = this.context.toastService;
    this.tasksService = this.context.tasksService;
    this.locationsService = this.context.locationsService;
    this.stopsService = this.context.stopsService;

    await this.loadLocationsOptions();
  }

  async loadLocationsOptions() {
    this.setState({loading: true});

    const params: QueryParameter = {
      orderBys: [JSON.stringify({field: 'name'})],
      filters: [
        JSON.stringify({
          field: 'state_id',
          value: localStorage.getItem('current state'),
        }),
      ],
      aggregate: false,
    };

    await this.locationsService
      ?.getLocations(params)
      .then(async data => {
        this.setState({
          locations: data.records as Location[],
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, locations load failed, please try again.');
        console.error(error);
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  async onSave() {
    this.setState({loading: true});
    const {taskPatchesMap, dialogType, selectedLocationIdsMap} = this.state;
    //validation
    let errorMessage = '';
    if (dialogType === 'Single') {
      if (!taskPatchesMap['other']?.description || !selectedLocationIdsMap['other']) {
        errorMessage = 'Some fields are empty!';
      }
    } else {
      if (
        !taskPatchesMap['pickup']?.description ||
        !selectedLocationIdsMap['pickup'] ||
        !taskPatchesMap['drop']?.description ||
        !selectedLocationIdsMap['drop']
      ) {
        errorMessage = 'Some fields are empty!';
      } else if (selectedLocationIdsMap['pickup'] === selectedLocationIdsMap['drop']) {
        errorMessage = 'Locations cannot be the same!';
      }
    }
    if (errorMessage) {
      this.toastService?.showError(this.toast, errorMessage);
      this.setState({loading: false});
      return;
    }

    const promises = [];

    //create new task(s)
    if (dialogType === 'Single') {
      promises.push(this.createTask('other'));
    } else {
      promises.push(this.createTask('pickup'));
      promises.push(this.createTask('drop'));
    }
    Promise.all(promises).then(() => {
      this.toastService?.showSuccess(this.toast, 'Task(s) saved.');
      this.onHide();
      MessageService.sendMessage(messages.runUpdated);
    });
  }

  async createTask(taskType: TaskType) {
    const {taskPatchesMap, selectedLocationIdsMap} = this.state;
    const {run} = this.props;

    const task = taskPatchesMap[taskType];
    const selectedLocationId = selectedLocationIdsMap[taskType];
    task.type = taskType;
    const runStops: Stop[] = run.stops!.sort((a, b) => b.line_up - a.line_up);
    const runTasks: Task[] = run.tasks!;

    const taskStop = runStops.find(stop => +selectedLocationId === stop.location_id);
    let lastLineUp = 0;
    let stopId = taskStop?.id;
    if (taskStop) {
      const stopTasks = runTasks.filter(task => task.stop_id === taskStop.id).sort((a, b) => b.line_up - a.line_up);
      const lastStopTask = stopTasks[0];
      lastLineUp = lastStopTask?.line_up ?? 0;
    } else {
      const lastStop = runStops[0];
      const lastStopLineUp = lastStop?.line_up ?? 0;
      const newStop = await this.createStop({
        stage: 'Planned',
        line_up: lastStopLineUp,
        run_id: run.id,
        location_id: +selectedLocationId,
      });

      stopId = newStop!.id;
    }
    task.line_up = ++lastLineUp;
    task.stop_id = stopId;

    return await this.tasksService
      ?.createTask(task)
      .then((newTask: Task) => {
        return newTask;
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Task create failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  async createStop(stop: StopPatch) {
    return await this.stopsService
      ?.createStop(stop)
      .then(async newStop => {
        return newStop;
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Stop create failed, please try again.');
        console.error('error: ' + error);
      });
  }

  onShow() {
    //clean old data
    this.setState({
      taskPatchesMap: {},
    });
  }

  onHide() {
    this.setState({
      loading: false,
    });
    this.props.onHide();
  }

  onTypeChanged(e: SelectButtonChangeParams) {
    if (e.value) {
      this.setState({dialogType: e.value});
    }
  }

  onLocationChanged(e: DropdownChangeParams, type: TaskType) {
    const selectedLocationIdsMap = {
      ...this.state.selectedLocationIdsMap,
      [type]: e.value,
    };
    this.setState({selectedLocationIdsMap: selectedLocationIdsMap});
  }

  onDescriptionChanged(e: React.ChangeEvent<HTMLInputElement>, type: TaskType) {
    const taskPatchesMap = {...this.state.taskPatchesMap};
    taskPatchesMap[type] = {
      ...taskPatchesMap[type],
      description: e.target.value,
    };
    this.setState({taskPatchesMap: taskPatchesMap});
  }

  renderDialogTypeSelectRow(dialogType: DialogType, run: Run): ReactNode {
    return (
      <div className="p-col">
        <div className="p-d-flex p-ai-center">
          <div className="p-mr-2">
            <label>I want to add</label>
          </div>
          <div className="p-mr-2">
            <SelectButton
              optionLabel="name"
              optionValue="value"
              value={dialogType}
              options={dialogTypeSelectOptions}
              onChange={this.onTypeChanged}
            />
          </div>
          <div className="p-mr-2">
            <label>to the run</label>
            <span className="p-ml-2">{run.name}</span>
          </div>
        </div>
      </div>
    );
  }

  renderTaskType(typeName: string) {
    return (
      <div className="p-col">
        <div className="p-d-flex p-ai-center">
          <div className="p-mr-2">
            <label>{typeName}</label>
          </div>
        </div>
      </div>
    );
  }

  renderTaskRow(type: TaskType, task?: Task): ReactNode {
    const {locations, taskPatchesMap, selectedLocationIdsMap} = this.state;
    const taskPatch = taskPatchesMap?.[type] ?? {};
    const selectedLocationId = selectedLocationIdsMap?.[type] ?? '';
    const description = taskPatch.description ?? task?.description ?? '';
    return (
      <div className="p-col">
        <div className="p-grid p-ai-center">
          <div className="p-col-1">
            <label className="p-ml-3">location</label>
          </div>
          <div className="p-col-2">
            <Dropdown
              className={'w-100'}
              value={selectedLocationId}
              options={locations}
              onChange={e => this.onLocationChanged(e, type)}
              optionLabel="name"
              optionValue="id"
              filter
              filterBy="name"
            />
          </div>
          <div className="p-col-2">
            <label>task description</label>
          </div>
          <div className="p-col-7">
            <InputText className={'w-100'} value={description} onChange={e => this.onDescriptionChanged(e, type)} />
          </div>
        </div>
      </div>
    );
  }

  renderDialogBody(): ReactNode {
    const {dialogType} = this.state;
    const {run} = this.props;

    const rows: ReactNode[] = [];
    rows.push(this.renderDialogTypeSelectRow(dialogType, run));
    if (dialogType === 'Single') {
      rows.push(this.renderTaskRow('other'));
    } else {
      rows.push(this.renderTaskType('Pick-up'));
      rows.push(this.renderTaskRow('pickup'));
      rows.push(this.renderTaskType('Drop'));
      rows.push(this.renderTaskRow('drop'));
    }

    return <div className="p-grid p-dir-col w-100">{rows.map(row => row)}</div>;
  }

  render() {
    const {loading} = this.state;
    const {showDialog} = this.props;
    const headerTitle = 'Add OTHER Task(s)';

    return (
      <>
        <TwoDialog
          headerTitle={headerTitle}
          showDialog={showDialog}
          width={70}
          onHide={this.onHide}
          onSave={this.onSave}
          loading={loading}
          onShow={this.onShow}
        >
          {this.renderDialogBody()}
        </TwoDialog>
        <Toast ref={this.toast} />
      </>
    );
  }
}
export default AddTaskDialog;
