import React from 'react';
import {Fieldset} from 'primereact/fieldset';
import {DropdownChangeParams} from 'primereact/dropdown';
import {InputSwitch, InputSwitchChangeParams} from 'primereact/inputswitch';
import './LocationFilterBoxComponent.scss';
interface Props {
  newBoxes: number;
  newLocations: number;
  plannedBoxes: number;
  plannedLocations: number;
  showAllLocations: boolean;
  handleFilterChange: (e: InputSwitchChangeParams | DropdownChangeParams) => void;
}

const LocationFilterboxComponent = ({
  newBoxes,
  newLocations,
  plannedBoxes,
  plannedLocations,
  showAllLocations,
  handleFilterChange,
}: Props) => {
  return (
    <Fieldset id="filter-box" className="line-filter p-mb-3 p-p-1 w-100">
      <div className="p-d-flex p-flex-row p-flex-wrap">
        <div className="p-d-flex p-col-12 p-xs-12 p-sm-12 p-md-6 p-lg-4 p-as-center p-jc-center p-p-1">
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1 filter-box-span">New</span>
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-0">{`(${newBoxes} / ${newLocations})`}</span>
        </div>
        <div className="p-d-flex p-col-12 p-xs-12 p-sm-12 p-md-6 p-lg-4 p-as-center p-jc-center p-p-1">
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1 filter-box-span">Planned</span>
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-0">{`(${plannedBoxes} / ${plannedLocations})`}</span>
        </div>
        <div className="p-d-flex p-xs-12 p-sm-12 p-col-12 p-md-6 p-lg-4 p-as-center p-jc-center p-p-1">
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1 filter-box-span">Show All Locations</span>
          <div className="p-col-12 p-md-6 p-lg-6 p-p-0 p-as-center">
            <InputSwitch
              name="showAllLocations"
              className={'p-as-center'}
              checked={showAllLocations}
              onChange={e => handleFilterChange(e)}
            />
          </div>
        </div>
      </div>
    </Fieldset>
  );
};

export default LocationFilterboxComponent;
