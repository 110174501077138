import React from 'react';
import {History} from 'history';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import {Run} from 'two-core';

interface Props {
  run: Run;
  history: History;
}

interface State {
  run: Run;
}

class RunDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      run: {
        name: '',
        stage: 'Draft',
        state_id: '',
      },
    };
  }

  componentDidMount() {
    this.setRun(this.props.run);
  }

  setRun(run: Run) {
    this.setState({
      run: run,
    });
  }

  render() {
    const {run} = this.state;
    return (
      <>
        <div className="p-d-flex">
          <label htmlFor="name" className="p-col-12 p-md-3">
            name
          </label>
          <div className="p-col-12 p-md-9">
            <span>{run.name}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="stage" className="p-col-12 p-md-3">
            stage
          </label>
          <div className="p-col-12 p-md-9">
            <span>{run.stage}</span>
          </div>
        </div>

        {run.vehicle && (
          <div className="p-d-flex">
            <label htmlFor="vehicle" className="p-col-12 p-md-3">
              vehicle
            </label>
            <div className="p-col-12 p-md-9">
              <span>{run.vehicle.name}</span>
            </div>
          </div>
        )}

        {run.schedule_entry && (
          <>
            <div className="p-d-flex">
              <label htmlFor="start_at" className="p-col-12 p-md-3">
                start
              </label>
              <div className="p-col-12 p-md-9">
                <span>{DateTime.fromISO(run.schedule_entry.start_at.toString()).toFormat(formats.date)}</span>
              </div>
            </div>

            <div className="p-d-flex">
              <label htmlFor="end_at" className="p-col-12 p-md-3">
                end
              </label>
              <div className="p-col-12 p-md-9">
                <span>{DateTime.fromISO(run.schedule_entry.end_at.toString()).toFormat(formats.date)}</span>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default RunDetail;
