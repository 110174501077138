import React from 'react';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import {UsersService, AppContext, ToastService, TwoDialog} from 'two-app-ui';
import {Container, FreightOrder, FreightOrderPatch, Location, QueryParameter, User, Vehicle} from 'two-core';
import {Toast} from 'primereact/toast';
import LocationsService from '../../services/LocationsService';
import update from 'immutability-helper';
import FreightOrdersService from '../../services/FreightOrdersService';
import OrdersService from '../../services/OrdersService';
import TlesService from '../../services/TlesService';
import ContainersService from '../../services/ContainersService';
import VehiclesService from '../../services/VehiclesService';
import {DateTime} from 'luxon';
import {InputTextarea} from 'primereact/inputtextarea';
import formats from '../../config/formats';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  onHideAndRefresh: () => void;
  toast: React.RefObject<Toast>;
  freightOrderId: string | undefined;
}

interface State {
  loading: boolean;
  freightOrderPatch: FreightOrderPatch | undefined;
  freightOrder: FreightOrder | undefined;
  containers: Container[];
  locations: Location[];
  vehicles: Vehicle[];
  users: User[];
}

class EditFreightOrderDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  freightOrdersService: FreightOrdersService | null = null;
  ordersService: OrdersService | null = null;
  tlesService: TlesService | null = null;
  containersService: ContainersService | null = null;
  locationsService: LocationsService | null = null;
  vehiclesService: VehiclesService | null = null;
  usersService: UsersService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      freightOrderPatch: {},
      freightOrder: undefined,
      containers: [],
      locations: [],
      vehicles: [],
      users: [],
    };

    this.loadFreightOrder = this.loadFreightOrder.bind(this);
    this.updateFreightOrder = this.updateFreightOrder.bind(this);
    this.updateFreightOrder = this.updateFreightOrder.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.hideAndRefresh = this.hideAndRefresh.bind(this);
  }

  async componentDidMount() {
    this.freightOrdersService = this.context.freightOrdersService;
    this.ordersService = this.context.ordersService;
    this.tlesService = this.context.tlesService;
    this.containersService = this.context.containersService;
    this.locationsService = this.context.locationsService;
    this.vehiclesService = this.context.vehiclesService;
    this.usersService = this.context.usersService;
    this.toastService = this.context.toastService;
    this.loadContainerList();
    this.loadVehicleList();
    this.loadUserList();
  }

  clearDialog() {
    this.setState({
      freightOrder: undefined,
      freightOrderPatch: {},
      loading: false,
    });
  }

  hideDialog() {
    this.clearDialog();
    this.props.onHide();
  }

  hideAndRefresh() {
    this.clearDialog();
    this.props.onHideAndRefresh();
  }

  getCurrentUserId(): string {
    const unparsedUser: string = localStorage.getItem('user') ?? '';

    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  async loadFreightOrder() {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: this.props.freightOrderId,
        condition: '=',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    return this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const dataRecords = (data.records as FreightOrder[]) ?? [];
        const freightOrder = dataRecords[0];

        this.loadLocationList(freightOrder.owner_company?.id);

        this.setState({
          freightOrder: freightOrder,
          loading: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, order load failed, please try again.');
        this.setState({loading: false});
      });
  }

  async loadContainerList() {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: `states->>'${localStorage.getItem('current state')}'`,
        value: 'Done',
        condition: '<>',
      })
    );
    const params: QueryParameter = {
      filters: filters,
    };
    return this.containersService
      ?.getContainers(params)
      .then(data => {
        const dataRecords = (data.records as Container[]) ?? [];
        this.setState({
          containers: dataRecords,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, containers load failed, please try again.');
      });
  }

  async loadLocationList(dealershipId: string | undefined) {
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };
    return this.locationsService
      ?.getLocations(params)
      .then(data => {
        const receivedLocations: Location[] = (data.records as Location[]) ?? [];
        const twoLocationTypes = ['warehouse', 'factory'];
        const locations = receivedLocations.filter(
          location => twoLocationTypes.includes(location.type) || location.dealership_id === dealershipId
        );

        this.setState({
          locations: locations,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, locations load failed, please try again.');
      });
  }

  async loadVehicleList() {
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };
    return this.vehiclesService
      ?.getVehicles(params)
      .then(data => {
        const dataRecords = (data.records as Vehicle[]) ?? [];
        this.setState({
          vehicles: dataRecords,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, vehicles load failed, please try again.');
      });
  }

  async loadUserList() {
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };
    return this.usersService
      ?.getUsers(params)
      .then(data => {
        const dataRecords = (data.records as User[]) ?? [];
        this.setState({
          users: dataRecords,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, users load failed, please try again.');
      });
  }

  async updateFreightOrder() {
    this.setState({loading: true});
    const freightOrder = this.state.freightOrder;

    const freightOrderPatch = this.state.freightOrderPatch as FreightOrderPatch;

    return this.freightOrdersService
      ?.updateFreightOrder(freightOrder?.id ?? '', freightOrderPatch)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Freight Order updated successfully.');
        this.hideAndRefresh();
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, Time line event create failed, please try again.');
        this.hideDialog();
      });
  }

  setSelectedContainer(e: DropdownChangeParams) {
    const containerId = e.value;
    const freightOrderToUpdate = this.state.freightOrder as FreightOrder;
    freightOrderToUpdate.container_id = containerId;
    const freightOrderPatch = this.state.freightOrderPatch as FreightOrderPatch;
    freightOrderPatch.container_id = containerId;
    this.setState({
      freightOrder: freightOrderToUpdate,
      freightOrderPatch: freightOrderPatch,
    });
  }

  setSelectedFinalLocation(e: DropdownChangeParams) {
    const locationId = e.value;
    const freightOrderToUpdate = this.state.freightOrder as FreightOrder;
    freightOrderToUpdate.final_destination_id = locationId;
    const freightOrderPatch = this.state.freightOrderPatch as FreightOrderPatch;
    freightOrderPatch.final_destination_id = locationId;
    this.setState({
      freightOrder: freightOrderToUpdate,
      freightOrderPatch: freightOrderPatch,
    });
  }

  setSelectedCurrentLocation(e: DropdownChangeParams) {
    const locationId = e.value;
    const freightOrderToUpdate = this.state.freightOrder as FreightOrder;
    freightOrderToUpdate.current_location_id = locationId;
    const freightOrderPatch = this.state.freightOrderPatch as FreightOrderPatch;
    freightOrderPatch.current_location_id = locationId;
    this.setState({
      freightOrder: freightOrderToUpdate,
      freightOrderPatch: freightOrderPatch,
    });
  }

  setSelectedVehicle(e: DropdownChangeParams) {
    const vehicleId = e.value;
    const freightOrderToUpdate = this.state.freightOrder as FreightOrder;
    freightOrderToUpdate.vehicle_id = vehicleId;
    const freightOrderPatch = this.state.freightOrderPatch as FreightOrderPatch;
    freightOrderPatch.vehicle_id = vehicleId;
    this.setState({
      freightOrder: freightOrderToUpdate,
      freightOrderPatch: freightOrderPatch,
    });
  }

  setSelectedUser(e: DropdownChangeParams) {
    const userId = e.value;
    const freightOrderToUpdate = this.state.freightOrder as FreightOrder;
    freightOrderToUpdate.delivered_by = userId;
    const freightOrderPatch = this.state.freightOrderPatch as FreightOrderPatch;
    freightOrderPatch.delivered_by = userId;
    this.setState({
      freightOrder: freightOrderToUpdate,
      freightOrderPatch: freightOrderPatch,
    });
  }

  onDateChange(e: CalendarChangeParams) {
    const newState = update(this.state, {
      freightOrder: {
        $merge: {
          [e.target.name]: e.target.value,
        },
      },
      freightOrderPatch: {
        $merge: {
          [e.target.name]: e.target.value,
        },
      },
    });
    this.setState(newState);
  }

  onDeliveryNoteChange(text: string) {
    const newState = update(this.state, {
      freightOrder: {
        $merge: {
          delivery_note: text,
        },
      },
      freightOrderPatch: {
        $merge: {
          delivery_note: text,
        },
      },
    });
    this.setState(newState);
  }

  userDropdownTemplate = (user: User) => {
    if (user) {
      return (
        <span>
          {user.full_name} ({user.username})
        </span>
      );
    }
    return 'empty';
  };

  render() {
    const {freightOrder} = this.state;
    const state = localStorage.getItem('current state')!;
    const stage = freightOrder?.route?.[state].stage ?? '';

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label className="p-col-2">freight stage</label>
          <div className="p-col-4">
            <span className="p-fluid">{stage}</span>
          </div>
          <label htmlFor="container" className="p-col-2">
            container
          </label>
          <div className="p-col-4 p-p-0">
            <Dropdown
              className="p-d-flex w-100"
              name={'container'}
              value={freightOrder?.container_id}
              options={this.state.containers}
              optionLabel={'id'}
              optionValue={'id'}
              filter
              filterBy={'id'}
              onChange={e => this.setSelectedContainer(e)}
            />
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0">
          <label htmlFor="final_destination" className="p-col-2">
            final destination
          </label>
          <div className="p-col-4 p-p-0">
            <Dropdown
              className="p-d-flex w-100"
              name={'final_destination'}
              value={freightOrder?.final_destination_id}
              options={this.state.locations}
              optionLabel={'name'}
              optionValue={'id'}
              filter
              filterBy={'name'}
              onChange={e => this.setSelectedFinalLocation(e)}
            />
          </div>
          <label htmlFor="vehicle" className="p-col-2">
            vehicle
          </label>
          <div className="p-col-4 p-p-0">
            <Dropdown
              className="p-d-flex w-100"
              name={'vehicle'}
              value={freightOrder?.vehicle_id}
              options={this.state.vehicles}
              optionLabel={'name'}
              optionValue={'id'}
              filter
              filterBy={'name'}
              onChange={e => this.setSelectedVehicle(e)}
            />
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0">
          <label htmlFor="location" className="p-col-2">
            current location
          </label>
          <div className="p-col-4 p-p-0">
            <Dropdown
              className="p-d-flex w-100"
              name={'location'}
              value={freightOrder?.current_location_id}
              options={this.state.locations}
              optionLabel={'name'}
              optionValue={'id'}
              filter
              filterBy={'name'}
              onChange={e => this.setSelectedCurrentLocation(e)}
            />
          </div>
          <label htmlFor="at_current_location_since" className="p-col-2">
            at current location since
          </label>
          <div className="p-col-4 p-p-0">
            <Calendar
              id="at_current_location_since"
              name={'at_current_location_since'}
              value={
                typeof freightOrder?.at_current_location_since === 'string'
                  ? DateTime.fromISO(freightOrder?.at_current_location_since).toJSDate()
                  : freightOrder?.at_current_location_since
              }
              onChange={e => this.onDateChange(e)}
              dateFormat={formats.calendarInputDate}
              showIcon
            />
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0">
          <label htmlFor="handed_over" className="p-col-2">
            handed over
          </label>
          <div className="p-col-4 p-p-0">
            <Calendar
              id="handed_over"
              name={'handed_over'}
              value={
                typeof freightOrder?.handed_over === 'string'
                  ? DateTime.fromISO(freightOrder?.handed_over).toJSDate()
                  : freightOrder?.handed_over
              }
              onChange={e => this.onDateChange(e)}
              dateFormat={formats.calendarInputDate}
              showIcon
            />
          </div>
          <label htmlFor="delivered_at" className="p-col-2">
            delivered at
          </label>
          <div className="p-col-4 p-p-0">
            <Calendar
              id="delivered_at"
              name={'delivered_at'}
              value={
                typeof freightOrder?.delivered_at === 'string'
                  ? DateTime.fromISO(freightOrder?.delivered_at).toJSDate()
                  : freightOrder?.delivered_at
              }
              onChange={e => this.onDateChange(e)}
              dateFormat={formats.calendarInputDate}
              showIcon
            />
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pb-0">
          <label htmlFor="user" className="p-col-2">
            delivered by
          </label>
          <div className="p-col-4 p-p-0">
            <Dropdown
              className="p-d-flex w-100"
              name={'user'}
              value={freightOrder?.delivered_by}
              options={this.state.users}
              optionLabel={'id'}
              optionValue={'id'}
              filter
              filterBy={'username'}
              onChange={e => this.setSelectedUser(e)}
              itemTemplate={this.userDropdownTemplate}
              valueTemplate={this.userDropdownTemplate}
              filterPlaceholder={'Filter by username'}
            />
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pb-0">
          <label htmlFor="deliveryNote" className="p-col-2">
            delivery note
          </label>
          <div className="p-col-8 p-p-0">
            <InputTextarea
              className="p-d-flex w-100"
              name={'delivery_note'}
              value={freightOrder?.delivery_note}
              onChange={e => {
                this.onDeliveryNoteChange(e.target.value);
              }}
              rows={2}
            />
          </div>
        </div>{' '}
      </>
    );

    return (
      <TwoDialog
        headerTitle={'Edit Freight Order ' + (freightOrder ? freightOrder?.id : '')}
        showDialog={this.props.showDialog}
        width={70}
        onHide={this.hideDialog}
        onSave={this.updateFreightOrder}
        loading={this.state.loading}
        onShow={this.loadFreightOrder}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default EditFreightOrderDialog;
