import React from 'react';
import {Divider} from 'primereact/divider';
import {AppContext, AppMenuItem, AppMenuItemTemplate, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {
  FreightOrder,
  Location,
  MapOf,
  OrderPatch,
  QueryParameter,
  Run,
  RunPatch,
  Stop,
  Task,
  TaskType,
  TimeLineEvent,
  TleContentAssigned,
  TleLink,
} from 'two-core';
import {Toast} from 'primereact/toast';
import {Subscription} from 'rxjs';
import OrderListComponent from '../Orders/OrderListComponent';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import StopsService from '../../services/StopsService';
import TasksService from '../../services/TasksService';
import FreightOrdersService from '../../services/FreightOrdersService';
import {messages} from '../../config/messages';
import LocationsService from '../../services/LocationsService';
import OrdersService from '../../services/OrdersService';
import TlesService from '../../services/TlesService';
import {
  currentStartingLocationsOption,
  differentDestinationsOption,
  finalDestinationsOption,
  getFinalLocationOptions,
  startingLocationOptions,
  toHandoverLocationOption,
  twoWarehouseOption,
} from '../Orders/AssignOrdersToRunDialog';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import RunsService from '../../services/RunsService';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  run: Run;
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  freightOrdersIdsExcluded: string[];
  freightOrdersToAssign: FreightOrder[];
  stops: Stop[];
  locations: Location[];
  locationsForDestination: Location[];
  selectedStartingLocationOption: string;
  selectedFinalLocationOption: string;
  selectedStartingWarehouseLocation?: Location;
  warehouseLocations: Location[];
  selectedFinalWarehouseLocation?: Location;
  ordersDestinationLocationsIdMap: MapOf<number>; //orderId => locationId
}

class AddOrdersToRunDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  stopsService: StopsService | null = null;
  tasksService: TasksService | null = null;
  locationsService: LocationsService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  ordersService: OrdersService | null = null;
  tlesService: TlesService | null = null;
  toastService: ToastService | null = null;
  runsService: RunsService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      freightOrdersIdsExcluded: [],
      freightOrdersToAssign: [],
      ordersDestinationLocationsIdMap: {},
      stops: [],
      locations: [],
      locationsForDestination: [],
      selectedStartingLocationOption: currentStartingLocationsOption,
      selectedFinalLocationOption: finalDestinationsOption,
      selectedStartingWarehouseLocation: undefined,
      warehouseLocations: [],
      selectedFinalWarehouseLocation: undefined,
    };

    this.loadExcludedFreightOrders = this.loadExcludedFreightOrders.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.loadData = this.loadData.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.updateRun = this.updateRun.bind(this);
  }

  componentDidMount() {
    this.stopsService = this.context.stopsService;
    this.tasksService = this.context.tasksService;
    this.locationsService = this.context.locationsService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.tlesService = this.context.tlesService;
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;
    this.runsService = this.context.runsService;
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();
  }

  async loadLocations() {
    const sortBy: string[] = [];

    sortBy.push(
      JSON.stringify({
        field: 'name',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
    };

    return this.locationsService?.getLocations(params).then(data => {
      const dataRecords = (data?.records as Location[]) ?? [];
      const allLocations: Location[] = dataRecords.map(location => {
        //todo temporary fix -> this should be deleted after registration of parser for Number data type on backend
        location.id = +location.id!;
        return location;
      });

      const warehouseLocations = allLocations.filter(l => l.type === 'warehouse' || l.type === 'factory');

      const finalDestinationLocation = this.finalDestinationTemplate();
      const locationsWithFinalDestination = [finalDestinationLocation];
      locationsWithFinalDestination.push(...dataRecords);

      this.setState({
        locations: locationsWithFinalDestination,
        warehouseLocations: warehouseLocations,
        selectedStartingWarehouseLocation: undefined,
        selectedFinalWarehouseLocation: warehouseLocations[0],
      });
    });
  }

  async loadFreightOrders(filters: string[]) {
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    const result = await this.freightOrdersService?.getFreightOrders(params);
    const dataRecords = (result?.records as FreightOrder[]) ?? [];
    return dataRecords;
  }

  async loadData() {
    await this.loadExcludedFreightOrders();
    await this.loadLocations();
    await this.loadStopsByRunId(this.props.run.id);
    this.setState({loading: false});
  }

  async loadExcludedFreightOrders() {
    const runId = this.props.run.id;
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'run.id',
        value: new Array(runId),
        condition: 'in',
      })
    );

    const dataRecords = (await this.loadFreightOrders(filters)) as FreightOrder[];
    const freightOrdersExcludedIds = (dataRecords.filter(o => o !== null) ?? []).map(o => o.id ?? '');

    this.setState({freightOrdersIdsExcluded: freightOrdersExcludedIds});
  }

  prepareOrdersToStops() {
    const freightOrders: FreightOrder[] = this.state.freightOrdersToAssign;
    const startingWarehouseLocation = this.state.selectedStartingWarehouseLocation;
    const destinationLocationsIdMap = this.state.ordersDestinationLocationsIdMap;

    const updatedOrders: FreightOrder[] = [];
    freightOrders.forEach(order => {
      const fo = {...order};
      if (startingWarehouseLocation) {
        fo.current_location_id = startingWarehouseLocation.id;
      } else {
        const state = localStorage.getItem('current state')!;
        if (order.route![state].stage === 'In Container') {
          fo.current_location_id = order.container?.unload_at_id;
        }
      }
      const orderDestinationLocationId = destinationLocationsIdMap[order.id ?? ''];
      fo.destination_id = !orderDestinationLocationId ? order.final_destination_id : orderDestinationLocationId;

      updatedOrders.push(fo);
    });

    return updatedOrders;
  }

  async updateRun() {
    this.setState({loading: true});
    const run = this.props.run;
    if (!run) {
      this.toastService?.showError(this.props.toast, 'Sorry, Orders assign failed, please try again.');
      this.setState({loading: false});
      return;
    }

    const updatedOrders = this.prepareOrdersToStops();

    //create stops map and set last line up number
    const stopsMap: MapOf<Stop> = {}; // location_id => Stop
    let lastLineUp = 0;
    if (run.stops) {
      for (const stop of run.stops) {
        if (stop) {
          stopsMap[stop.location_id] = stop;
          lastLineUp < stop.line_up && (lastLineUp = stop.line_up);
        }
      }
    }

    //now we will create new tasks in stops
    for (const order of updatedOrders) {
      if (!order.current_location_id || !order.destination_id) {
        this.setState({loading: false});
        this.toastService?.showError(this.props.toast, 'Sorry, Orders assign failed, please try again.');
        return;
      }
      //task for pickup stop
      let pickupStop = stopsMap[order.current_location_id];
      if (!pickupStop) {
        //create new stop
        pickupStop = this.createStopEntity(++lastLineUp, run.id!, order.current_location_id);
        stopsMap[order.current_location_id] = pickupStop;
      }
      // add new task to the pickup stop
      let pickupTaskLastLineUp = 0;
      if (pickupStop.tasks) {
        //this stop has some new task(s)
        pickupTaskLastLineUp = pickupStop.tasks.reduce((a, b) => (a.line_up > b.line_up ? a : b))?.line_up ?? 0;
      } else if (pickupStop.id) {
        //check already saved tasks
        pickupTaskLastLineUp =
          run.tasks?.filter(task => task.stop_id === pickupStop.id).reduce((a, b) => (a.line_up > b.line_up ? a : b))
            ?.line_up ?? 0;
      }

      const newPickupTask = this.createTaskEntity(++pickupTaskLastLineUp, pickupStop, order, 'pickup');
      if (!pickupStop.tasks) {
        pickupStop.tasks = [];
      }
      pickupStop.tasks.push(newPickupTask);

      //task for destination stop
      let destinationStop = stopsMap[order.destination_id];
      if (!destinationStop) {
        //create new stop
        destinationStop = this.createStopEntity(++lastLineUp, run.id!, order.destination_id);
        stopsMap[order.destination_id] = destinationStop;
      }
      // add new task to the destination stop
      let destinationTaskLastLineUp = 0;
      if (destinationStop.tasks) {
        //this stop has some new task(s)
        destinationTaskLastLineUp =
          destinationStop.tasks.reduce((a, b) => (a.line_up > b.line_up ? a : b))?.line_up ?? 0;
      } else if (destinationStop.id) {
        //check already saved tasks
        destinationTaskLastLineUp =
          run.tasks
            ?.filter(task => task.stop_id === destinationStop.id)
            .reduce((a, b) => (a.line_up > b.line_up ? a : b))?.line_up ?? 0;
      }

      const newTask = this.createTaskEntity(++destinationTaskLastLineUp, destinationStop, order, 'drop');
      if (!destinationStop.tasks) {
        destinationStop.tasks = [];
      }
      destinationStop.tasks.push(newTask);
    }

    const updatedRun: RunPatch = {
      stops: Object.values(stopsMap),
    };
    this.runsService
      ?.updateRun(run.id!.toString(), updatedRun)
      .then(() => {
        this.toastService?.showSuccess(
          this.props.toast,
          `Orders assigned to run ${this.props.run?.name ?? ''} successfully.`
        );
        this.setState({loading: false});
        MessageService.sendMessage(messages.runOrderCreate);
        MessageService.sendMessage(messages.runUpdated);
        this.hideDialog();
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, Orders assign failed, please try again.');
        this.setState({loading: false});
      });
  }

  async loadStopsByRunId(runId: number | undefined) {
    if (runId) {
      const filters: string[] = [];
      filters.push(
        JSON.stringify({
          field: 'run_id',
          value: new Array(runId),
          condition: 'in',
        })
      );
      const dataRecords = (await this.loadStops(filters)) as Stop[];
      this.setState({stops: dataRecords});
    }
  }

  async loadStops(filters: string[]) {
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    const result = await this.stopsService?.getStops(params);
    const dataRecords = (result?.records as Stop[]) ?? [];
    return dataRecords;
  }

  async createStop(stop: Stop, tasks: Task[]) {
    return this.stopsService
      ?.createStop(stop)
      .then(async data => {
        const updatedTasks = tasks.map(task => {
          task.stop_id = data.id ?? 0;
          return task;
        });
        const stateStops = this.state.stops;
        this.setState({stops: [...stateStops, data]});
        return this.createTasks(updatedTasks);
      })
      .catch(error => {
        console.error('error: ' + error);
      });
  }

  async createTasks(tasks: Task[]) {
    tasks.map(task => {
      return this.tasksService
        ?.createTask(task)
        .then(() => {})
        .catch(error => {
          console.error('error: ' + error);
        });
    });
  }

  createStopEntity(lineUp: number, runId: number, locationId: number) {
    const stop: Stop = {
      stage: 'Planned',
      line_up: lineUp,
      run_id: runId,
      location_id: locationId,
    };
    return stop;
  }

  createTaskEntity(lineUp: number, stop: Stop | null, order: FreightOrder, type: TaskType) {
    if (type === 'drop') {
      if (order.final_destination_id === stop?.location_id) {
        type = 'final-drop';
      }
    }
    const task: Task = {
      line_up: lineUp,
      stop_id: stop?.id ?? 0,
      freight_order_id: order.id ?? '',
      type: type,
    };
    return task;
  }

  createAssignedTleEntity(freightOrder: FreightOrder, runId: string) {
    const tleLink: TleLink = {link_type: 'run', linked_id: runId};
    const content: TleContentAssigned = {
      message: 'Order was assigned to a run',
      links: [tleLink],
    };

    const tle: TimeLineEvent = {
      event_type: 'assigned',
      entity_type: 'order',
      recorded_by: this.getCurrentUserId(),
      entity_id: freightOrder.id ?? '',
      content: content,
      recorded_at: new Date(),
    };
    return tle;
  }

  createTle(freightOrder: FreightOrder, tle: TimeLineEvent) {
    return this.tlesService?.createTle(tle).then(data => {
      if (data) {
        const updatedOrder: OrderPatch = {
          last_event_id: data.id,
        };
        this.ordersService?.updateOrder(freightOrder.id ?? '', updatedOrder);
      }
    });
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';

    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  async hideDialog() {
    await this.setState({
      freightOrdersToAssign: [],
    });
    this.props.onHide();
  }

  initAssignMenuItems(selectedOrders: FreightOrder[]): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const runMenu: AppMenuItem = {
      label: 'Assign to Run',
      faIcon: ['fas', 'truck-moving'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.assignOrderToRun(selectedOrders);
      },
    };

    menuItems.push(runMenu);
    return menuItems;
  }

  initRemoveMenuItems(selectedOrders: FreightOrder[]): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const runMenu: AppMenuItem = {
      label: 'Remove from Run',
      faIcon: ['fas', 'truck-moving'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.removeOrderFromRun(selectedOrders);
      },
    };

    menuItems.push(runMenu);
    return menuItems;
  }

  async assignOrderToRun(selectedOrders: FreightOrder[]) {
    const updatedOrdersToAssign = this.state.freightOrdersToAssign;
    const updatedOrdersIdsExcluded = this.state.freightOrdersIdsExcluded;
    for (const selectedOrder of selectedOrders) {
      updatedOrdersToAssign.push(selectedOrder);
      updatedOrdersIdsExcluded.push(selectedOrder.id!);
    }

    await this.setState({
      freightOrdersToAssign: updatedOrdersToAssign,
      freightOrdersIdsExcluded: updatedOrdersIdsExcluded,
    });

    MessageService.sendMessage(messages.ordersAssignToRun);
  }

  async removeOrderFromRun(selectedOrders: FreightOrder[]) {
    const ordersToAssign = this.state.freightOrdersToAssign;
    const ordersIdsExcluded = this.state.freightOrdersIdsExcluded;
    const ids = selectedOrders?.map(o => o.id ?? '');

    const updatedOrdersToAssign = ordersToAssign.filter(order => !ids.includes(order.id!));
    const updatedOrdersIdsExcluded = ordersIdsExcluded.filter(id => !ids.includes(id));
    await this.setState({
      freightOrdersToAssign: updatedOrdersToAssign,
      freightOrdersIdsExcluded: updatedOrdersIdsExcluded,
    });

    MessageService.sendMessage(messages.ordersAssignToRun);
  }

  onDragEnd = (result: DropResult) => {
    const {source, destination} = result;
    const destinationId = destination?.droppableId;
    const sourceId = source?.droppableId;
    const ordersDroppable = 'orders_droppable';
    const ordersToAssignDroppable = 'orders_to_assign_droppable';
    const {selectedFinalLocationOption, selectedFinalWarehouseLocation} = this.state;

    // dropped outside the list
    if (!destination || destinationId === sourceId) {
      return;
    }

    const selectedOrder = JSON.parse(result.draggableId) as FreightOrder;
    if (destinationId === ordersToAssignDroppable) {
      this.assignOrderToRun([selectedOrder]);
      //change order final destination location according to dropdowns
      if (selectedFinalLocationOption === twoWarehouseOption && selectedFinalWarehouseLocation) {
        this.changeOrdersDestinationLocation(selectedFinalWarehouseLocation.id!, selectedOrder.id!);
      } else if (selectedFinalLocationOption === toHandoverLocationOption) {
        const state = localStorage.getItem('current state') ?? '';
        this.changeOrdersDestinationLocation(selectedOrder.route![state].handover_location_id, selectedOrder.id!);
      } else {
        this.changeOrdersDestinationLocation(selectedOrder.final_destination_id ?? 0, selectedOrder.id!);
      }
    } else if (destinationId === ordersDroppable) {
      this.removeOrderFromRun([selectedOrder]);
    }
  };

  changeOrdersDestinationLocation(locationId: number, orderId: string) {
    const value = this.state.ordersDestinationLocationsIdMap;
    value[orderId] = locationId;

    const newValue = {...value};
    this.setState({ordersDestinationLocationsIdMap: newValue});
  }

  finalDestinationTemplate = () => {
    const finalDestinationLocation: Location = {
      name: 'FINAL DESTINATION',
      id: 0,
      type: 'other',
      state_id: '',
      address: {
        lat: 0,
        long: 0,
        street: '',
        suburb: '',
        state: '',
        postCode: '',
        state_short: '',
        country: '',
        phoneNumber: '',
      },
    };

    return finalDestinationLocation;
  };

  setSelectedStartingLocationOption(e: DropdownChangeParams) {
    const value = e.value as string;
    this.setState({
      selectedStartingLocationOption: value,
    });

    if (value === currentStartingLocationsOption) {
      this.setState({selectedStartingWarehouseLocation: undefined});
    } else if (value === twoWarehouseOption) {
      const location = this.state.warehouseLocations[0];
      if (location) {
        this.setState({
          selectedStartingWarehouseLocation: this.state.warehouseLocations[0],
        });
      }
    }
  }
  setSelectedStartingLocation(e: DropdownChangeParams) {
    const location = e.value as Location;
    this.setState({selectedStartingWarehouseLocation: location});
  }

  setSelectedFinalLocationOption(e: DropdownChangeParams) {
    const value = e.value as string;
    this.setState({
      selectedFinalLocationOption: value,
    });

    if (value === finalDestinationsOption) {
      this.changeAllOrdersToFinalLocation();
    } else if (value === twoWarehouseOption) {
      const location = this.state.selectedFinalWarehouseLocation;
      if (location) {
        this.changeAllOrdersToFinalWarehouseLocation(location);
      }
    } else if (value === toHandoverLocationOption) {
      this.changeAllOrdersToHandoverLocation();
    }
  }

  setSelectedFinalLocation(e: DropdownChangeParams) {
    const value = e.value as Location;
    this.setState({selectedFinalWarehouseLocation: value});
    this.changeAllOrdersToFinalWarehouseLocation(value);
  }

  changeAllOrdersToFinalLocation() {
    const destinationIdMap: MapOf<number> = {};
    const orders = this.state.freightOrdersToAssign;

    orders.forEach(order => {
      destinationIdMap[order.id ?? ''] = order.final_destination_id ?? 0;
    });

    this.setState({ordersDestinationLocationsIdMap: destinationIdMap});
  }

  changeAllOrdersToHandoverLocation() {
    const destinationIdMap: MapOf<number> = {};
    const orders = this.state.freightOrdersToAssign;
    const state = localStorage.getItem('current state')!;

    orders.forEach(order => {
      destinationIdMap[order.id!] = order.route![state].handover_location_id ?? 0;
    });

    this.setState({ordersDestinationLocationsIdMap: destinationIdMap});
  }

  changeAllOrdersToFinalWarehouseLocation(location: Location) {
    const value = this.state.ordersDestinationLocationsIdMap;
    const orders = this.state.freightOrdersToAssign ?? [];

    orders.forEach(order => {
      value[order.id ?? ''] = location.id ?? 0;
    });

    const newValue = {...value};
    this.setState({ordersDestinationLocationsIdMap: newValue});
  }

  locationOptionTemplate(location: Location | undefined) {
    const state = localStorage.getItem('current state') ?? '';
    if (location) {
      if (location.state_id === state) {
        return <span>{location.name}</span>;
      }
      return <span>{`${location.name} ${location.state_id}`}</span>;
    }
    return <></>;
  }

  render() {
    const {
      locations,
      selectedStartingLocationOption,
      selectedStartingWarehouseLocation,
      selectedFinalLocationOption,
      warehouseLocations,
      selectedFinalWarehouseLocation,
      freightOrdersToAssign,
      ordersDestinationLocationsIdMap,
    } = this.state;
    const locationsForOder = locations.filter(l => l.id !== 0);
    const freightOrdersIdsToAssign = freightOrdersToAssign.map(order => order.id!);
    const finalLocationOptions = getFinalLocationOptions(freightOrdersToAssign);

    const dialogBody = (
      <div className={'add-orders-detail w-100'} style={{height: '100%'}}>
        <div className="p-d-flex p-col-12 p-p-0">
          <label>{'Drag & Drop the orders you want to add to '}</label>
          <span className="p-ml-2 p-mr-2">{this.props.run?.name ?? ''}</span>
          <label>{' into the bottom table.'}</label>
        </div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="p-d-flex p-col-12 p-mb-4 p-mt-2 p-p-0">
            <OrderListComponent
              key="location-orders"
              id="orders"
              groupId="orders-to-assign"
              hideMenu={true}
              isAll={false}
              heightToScroll="300px"
              freightOrderExcludedStages={['On Board', 'Delivered']}
              freightOrderExcludedIds={this.state.freightOrdersIdsExcluded}
              droppableId={'orders_droppable'}
              customEmptyMessage="No orders"
            />
          </div>

          <Divider />

          <div className="p-d-flex p-m-0">
            <label>{'I want the run '}</label>
            <span className="p-ml-2">{this.props.run?.name ?? ''}</span>
          </div>

          <div className="p-d-flex p-my-2 p-mx-0">
            <label className="p-col-2 p-as-center p-text-center p-mt-2 p-p-0">{'to pick the order(s) below at'}</label>
            <div className="p-d-flex p-col-10 p-lg-4 p-p-0 p-mt-2">
              <Dropdown
                className="p-d-flex w-100"
                value={selectedStartingLocationOption}
                options={startingLocationOptions}
                onChange={e => this.setSelectedStartingLocationOption(e)}
              />
              {selectedStartingLocationOption === twoWarehouseOption && (
                <Dropdown
                  className="p-d-flex w-100 p-ml-2"
                  value={selectedStartingWarehouseLocation}
                  options={warehouseLocations}
                  optionLabel="name"
                  onChange={e => this.setSelectedStartingLocation(e)}
                  valueTemplate={this.locationOptionTemplate}
                  itemTemplate={this.locationOptionTemplate}
                />
              )}
            </div>
            <div className="p-col-2 p-as-center p-text-center p-mt-2">
              <label>{'and to deliver them to'}</label>
            </div>
            <div className="p-d-flex p-col-10 p-lg-4 p-p-0 p-mt-2">
              <Dropdown
                className="p-d-flex w-100"
                value={selectedFinalLocationOption}
                options={finalLocationOptions}
                onChange={e => this.setSelectedFinalLocationOption(e)}
              />
              {selectedFinalLocationOption === twoWarehouseOption && (
                <Dropdown
                  className="p-d-flex w-100 p-ml-2"
                  value={selectedFinalWarehouseLocation}
                  options={warehouseLocations}
                  optionLabel="name"
                  onChange={e => this.setSelectedFinalLocation(e)}
                  valueTemplate={this.locationOptionTemplate}
                  itemTemplate={this.locationOptionTemplate}
                />
              )}
            </div>
          </div>
          <div className="p-d-flex p-col-12 p-p-0">
            <OrderListComponent
              key="key-assigned-orders"
              id="assigned-orders"
              groupId="assigned-orders"
              customMenuItems={orders => this.initRemoveMenuItems(orders)}
              isAll={false}
              heightToScroll="300px"
              freightOrderIds={freightOrdersIdsToAssign}
              ordersDestinationLocations={ordersDestinationLocationsIdMap}
              changeDestinationLocation={(locationId, orderId) =>
                this.changeOrdersDestinationLocation(locationId, orderId)
              }
              locations={locationsForOder}
              droppableId={'orders_to_assign_droppable'}
              customEmptyMessage="No orders"
              runDestinationLocationsDisabled={selectedFinalLocationOption !== differentDestinationsOption}
            />
          </div>
        </DragDropContext>
      </div>
    );

    return (
      <>
        <TwoDialog
          headerTitle={'Add Orders to a Run'}
          showDialog={this.props.showDialog}
          width={80}
          onShow={this.loadData}
          onHide={this.hideDialog}
          onSave={this.updateRun}
          loading={this.state.loading}
          saveButtonTitle="Add"
        >
          {dialogBody}
        </TwoDialog>
      </>
    );
  }
}
export default AddOrdersToRunDialog;
