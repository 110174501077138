import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, Stop, StopPatch} from 'two-core';

class StopsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.stops ?? '';
  }

  async getStops(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateStop(id: string, data: Stop): Promise<Stop> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as Stop);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createStop(data: StopPatch): Promise<Stop> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as Stop);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteStop(id: string): Promise<void> {
    return this.delete(`${this.endpoint}/${id}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default StopsService;
