import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiResponse, QueryParameter, TimeLineEvent} from 'two-core';
import {ApiListResponse} from 'two-core';

class TlesService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints?.tles as string;
  }

  async getTimeLineEvents(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createTle(data: TimeLineEvent): Promise<TimeLineEvent> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as TimeLineEvent);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default TlesService;
