import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import RunListComponent from './RunListComponent';

interface Props {
  id: string;
}

class RunDetailComponent extends React.Component<RouteComponentProps<Props>, {}> {
  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = {};
  }

  render() {
    return <RunListComponent runDetailId={this.props.match.params.id} />;
  }
}

export default withRouter(RunDetailComponent);
