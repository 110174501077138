import React from 'react';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {QueryParameter, Run, RunPatch, ScheduleEntry, Vehicle} from 'two-core';
import {Toast} from 'primereact/toast';
import {Divider} from 'primereact/divider';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import {messages} from '../../config/messages';
import RunsService from '../../services/RunsService';
import VehiclesService from '../../services/VehiclesService';
import SchedulesService from '../../services/SchedulesService';
import formats from '../../config/formats';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  run: Run;
}

interface State {
  loading: boolean;
  run: Run;
  vehicles: Vehicle[];
  selectedVehicleId: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
}

class EditRunDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  runsService: RunsService | null = null;
  vehiclesService: VehiclesService | null = null;
  schedulesService: SchedulesService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      run: {
        stage: 'Draft',
        name: '',
        state_id: '',
      },
      vehicles: [],
      selectedVehicleId: undefined,
      startDate: undefined,
      endDate: undefined,
    };

    this.save = this.save.bind(this);
    this.loadData = this.loadData.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
  }

  componentDidMount() {
    this.runsService = this.context.runsService;
    this.vehiclesService = this.context.vehiclesService;
    this.schedulesService = this.context.schedulesService;
    this.toastService = this.context.toastService;
  }

  async loadData() {
    this.setState({loading: true});

    const sortBy: string[] = [];
    sortBy.push(
      JSON.stringify({
        field: 'name',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      aggregate: true,
    };

    this.vehiclesService
      ?.getVehicles(params)
      .then(data => {
        const dataRecords = (data?.records as Vehicle[]) ?? [];
        const run = this.props.run;
        const startDate = run.schedule_entry?.start_at;
        const endDate = run.schedule_entry?.end_at;
        const vehicleId = run.vehicle?.id;

        this.setState({
          vehicles: dataRecords,
          loading: false,
          run: run,
          selectedVehicleId: vehicleId?.toString(),
          startDate: startDate ? new Date(startDate) : undefined,
          endDate: endDate ? new Date(endDate) : undefined,
        });
      })
      .catch(error => {
        this.setState({loading: false});
        console.error(error);
      });
  }

  async save() {
    const run: Run = this.state.run;
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const vehicle = this.state.selectedVehicleId;
    if (startDate || endDate || vehicle) {
      if (startDate && endDate && vehicle) {
        this.updateRun(run, true);
      } else {
        this.toastService?.showWarn(
          this.props.toast,
          'The dates and vehicle need to be specified all or none for the schedule.'
        );
      }
    } else {
      this.updateRun(run, false);
    }
  }

  async updateRun(run: Run, schedule: boolean) {
    this.setState({loading: true});

    const updatedRun: RunPatch = {
      ...run,
      stage: schedule ? 'Scheduled' : 'Draft',
    };

    return this.runsService
      ?.updateRun(run.id?.toString() ?? '', updatedRun)
      .then(data => {
        this.toastService?.showSuccess(this.props.toast, 'Run updated successfully.');

        const scheduleEntry = run.schedule_entry;
        if (schedule) {
          if (scheduleEntry) {
            return this.updateScheduleEntry(scheduleEntry);
          } else {
            return this.createScheduleEntry(data.id);
          }
        } else {
          if (scheduleEntry) {
            return this.deleteScheduleEntry(scheduleEntry);
          }
        }
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Run update failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  createScheduleEntry(runId: number | undefined) {
    const vehicle = this.state.selectedVehicleId;
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    if (runId && vehicle && startDate && endDate) {
      startDate.setHours(6);
      endDate.setHours(16);
      const scheduleEntry: ScheduleEntry = {
        type: 'run',
        vehicle_id: Number(vehicle),
        run_id: runId ?? 0,
        description: '',
        line_up: 1,
        updated_at: new Date(),
        start_at: startDate,
        end_at: startDate,
      };

      this.schedulesService
        ?.createScheduleEntry(scheduleEntry)
        .then(() => {
          this.toastService?.showSuccess(this.props.toast, 'Run Schedule created successfully.');

          this.hideDialog();
        })
        .catch(error => {
          this.toastService?.showError(this.props.toast, 'Sorry, run schedule create failed, please try again.');
          console.error(error);
          this.setState({loading: false});
        });
    }
  }

  updateScheduleEntry(scheduleEntry: ScheduleEntry) {
    const vehicle = this.state.selectedVehicleId;
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    if (vehicle && startDate && endDate) {
      const updatedScheduleEntry: ScheduleEntry = {
        ...scheduleEntry,
        vehicle_id: Number(vehicle),
        updated_at: new Date(),
        start_at: startDate,
        end_at: endDate,
      };

      this.schedulesService
        ?.updateScheduleEntry(scheduleEntry.id?.toString() ?? '', updatedScheduleEntry)
        .then(() => {
          this.toastService?.showSuccess(this.props.toast, 'Run Schedule updated successfully.');

          this.hideDialog();
        })
        .catch(error => {
          this.toastService?.showError(this.props.toast, 'Sorry, run schedule update failed, please try again.');
          console.error(error);
          this.setState({loading: false});
        });
    }
  }

  deleteScheduleEntry(scheduleEntry: ScheduleEntry) {
    return this.schedulesService
      ?.deleteScheduleEntry(scheduleEntry.id?.toString() ?? '')
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Run Schedule deleted successfully.');

        this.hideDialog();
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, run schedule delete failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  hideDialog() {
    this.setState({
      run: {name: '', stage: 'Draft', state_id: ''},
      selectedVehicleId: undefined,
      startDate: undefined,
      endDate: undefined,
      loading: false,
    });
    this.props.onHide();
    MessageService.sendMessage(messages.runUpdated);
  }

  setName(value: string) {
    const run = this.state.run;
    const updatedRun = {...run, name: value ?? ''};
    this.setState({run: updatedRun});
  }

  onChangeDate(e: CalendarChangeParams) {
    const name = e.target.name;
    const value = e.value as Date;
    if (name === 'start_at') {
      this.setState({startDate: value});
    } else {
      this.setState({endDate: value});
    }
  }

  setSelectedVehicle(e: DropdownChangeParams) {
    const value = e.value;
    this.setState({selectedVehicleId: value});
  }

  render() {
    const {run, vehicles, selectedVehicleId, startDate, endDate} = this.state;

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="name" className="p-col-1">
            name
          </label>
          <div className="p-col-7 p-p-0">
            <span className="p-fluid">
              <InputText
                value={run.name ?? ''}
                onChange={e => {
                  const name = e.target.value;
                  this.setName(name);
                }}
              />
            </span>
          </div>
        </div>
        <Divider />
        <span>Schedule</span>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pb-0">
          <label htmlFor="vehicle" className="p-col-1">
            vehicle
          </label>
          <div className="p-col-3 p-p-0">
            <span className="p-fluid">
              <Dropdown
                className="p-d-flex w-100"
                filter
                value={selectedVehicleId}
                options={vehicles}
                onChange={e => this.setSelectedVehicle(e)}
                optionLabel="name"
                optionValue="id"
                showClear
              />
            </span>
          </div>
          <label htmlFor="start_at" className="p-col-1">
            start
          </label>
          <div className="p-col-3 p-p-0">
            <span className="p-fluid">
              <Calendar
                name="start_at"
                value={startDate}
                onChange={e => this.onChangeDate(e)}
                showIcon
                dateFormat={formats.calendarInputDate}
              />
            </span>
          </div>
          <label htmlFor="end_at" className="p-col-1">
            end
          </label>
          <div className="p-col-3 p-p-0">
            <span className="p-fluid">
              <Calendar
                name="end_at"
                value={endDate}
                onChange={e => this.onChangeDate(e)}
                showIcon
                dateFormat={formats.calendarInputDate}
              />
            </span>
          </div>
        </div>
      </>
    );

    return (
      <TwoDialog
        headerTitle={'Edit Run'}
        showDialog={this.props.showDialog}
        width={50}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={this.state.loading}
        onShow={this.loadData}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default EditRunDialog;
