import {Panel, PanelHeaderTemplateOptions} from 'primereact/panel';
import React, {useEffect, useRef, useState} from 'react';
import {InputSwitch} from 'primereact/inputswitch';
import {FileUpload, FileUploadHandlerParam} from 'primereact/fileupload';
import {Image} from 'primereact/image';
import {Button} from 'primereact/button';
import {faCamera, faChevronUp, faTrashCan, faChevronDown, faCloudArrowDown} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Galleria} from 'primereact/galleria';

interface Props {
  collapsed: boolean;
  disabled: boolean;
  active: boolean;
  photoUrls: string[];
  onVisibilityToggle: (collapse: boolean) => void;
  onFilesSelect: (files: File[]) => void;
  onTempPhotoDelete: (index: number) => void;
  onPhotoDelete: (url: string) => void;
  onTurnOff: () => void;
  onDownload: (url: string) => void;
}

export const PhotosPanel = ({
  collapsed,
  active,
  onVisibilityToggle,
  onTempPhotoDelete,
  photoUrls,
  onFilesSelect,
  onPhotoDelete,
  disabled,
  onTurnOff,
  onDownload,
}: Props) => {
  const fileUploadRef: React.RefObject<FileUpload> = useRef(null);
  const galleryRef: React.RefObject<Galleria> = useRef(null);
  const [galleryItemIndex, setGalleryItemIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (galleryItemIndex !== undefined) {
      galleryRef.current?.show();
    }
  }, [galleryItemIndex]);

  const uploadHandler = (e: FileUploadHandlerParam) => {
    onFilesSelect(e.files);
    fileUploadRef.current?.clear();
  };

  const galleryItemTemplate = (item: React.ReactElement): React.ReactNode => {
    return (
      <>
        <img
          src={item.props.src}
          style={{display: 'block', objectFit: 'contain', maxHeight: '85vh', maxWidth: '100%'}}
        />
        <Button
          onClick={() => onDownload(item.props.src)}
          className="p-button p-link galleria-download-button"
          icon={<FontAwesomeIcon icon={faCloudArrowDown} size="lg" style={{color: '#edebe9'}} />}
        />
      </>
    );
  };

  const onInputSwitchClick = () => {
    if (active) {
      onTurnOff();
    } else {
      onVisibilityToggle(false);
    }
  };

  const panelTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className}`;
    const titleClassName = `${options.titleClassName}`;
    const toggleIcon = <FontAwesomeIcon icon={options.collapsed ? faChevronDown : faChevronUp} size={'xl'} />;

    return (
      <>
        <div className={className}>
          <div className="p-d-flex">
            <div className="p-mr-2" onClick={options.onTogglerClick}>
              {toggleIcon}
            </div>
            <div className="p-ml-2">
              <span className={titleClassName + ' p-mr-2 p-as-center'}>Photos</span>
            </div>
          </div>
          <InputSwitch checked={active} onChange={onInputSwitchClick} />
        </div>
      </>
    );
  };
  return (
    <Panel
      className="photos-panel p-mb-2"
      toggleable
      collapsed={collapsed}
      onToggle={event => onVisibilityToggle(event.value)}
      headerTemplate={panelTemplate}
    >
      <Galleria
        ref={galleryRef}
        value={photoUrls.map((url, index) => (
          <Image
            key={index}
            src={url}
            width="100%"
            height="250px"
            imageStyle={{objectFit: 'contain'} as unknown as string}
          />
        ))}
        activeIndex={galleryItemIndex}
        onItemChange={e => setGalleryItemIndex(e.index)}
        circular
        showItemNavigators
        fullScreen
        showThumbnails={false}
        item={galleryItemTemplate}
        onHide={() => {
          setGalleryItemIndex(undefined);
        }}
      />
      <div className="p-grid">
        {!!photoUrls.length &&
          photoUrls.map((url, index) => (
            <div className="p-col-4" key={index} style={{position: 'relative'}}>
              <img
                src={url}
                width="100%"
                height="250px"
                style={{objectFit: 'contain'}}
                onClick={() => setGalleryItemIndex(index)}
              />
              <Button
                style={{position: 'absolute', bottom: 0, right: 0}}
                className="p-button-lg"
                icon={<FontAwesomeIcon icon={faTrashCan} size="xl" />}
                onClick={url.includes('blob') ? () => onTempPhotoDelete(index) : () => onPhotoDelete(url)}
                disabled={disabled}
              />
            </div>
          ))}
      </div>
      <div className="p-d-flex p-jc-center p-mt-2">
        <FileUpload
          className="photos-upload-button"
          ref={fileUploadRef}
          mode="basic"
          multiple
          accept="image/*"
          auto
          customUpload
          uploadHandler={uploadHandler}
          chooseOptions={{
            icon: <FontAwesomeIcon className="p-mx-auto" icon={faCamera} size="2xl" />,
            className: 'w-100 p-py-1',
            iconOnly: true,
          }}
          style={{width: '200px'}}
          disabled={disabled}
        />
      </div>
    </Panel>
  );
};
