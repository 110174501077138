import React from 'react';
import {Run, Location} from 'two-core';
import {Dropdown} from 'primereact/dropdown';

interface Props {
  disabled: boolean;
  runs: Run[];
  locations: Location[];
  selectedRun?: Run;
  selectedLocation?: Location;
  onRunChange: (run: Run) => void;
  onLocationChange: (location: Location) => void;
}

export const SelectionsPanel = ({
  disabled,
  runs,
  locations,
  selectedLocation,
  selectedRun,
  onLocationChange,
  onRunChange,
}: Props) => {
  return (
    <div>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label className="p-col-12 p-md-2">Run</label>
          <div className="p-col-12 p-md-10">
            <Dropdown
              value={selectedRun}
              options={runs}
              onChange={e => onRunChange(e.value)}
              disabled={disabled}
              optionLabel="name"
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-12 p-md-2">Location</label>
          <div className="p-col-12 p-md-10">
            <Dropdown
              value={selectedLocation}
              options={locations}
              onChange={e => onLocationChange(e.value)}
              disabled={disabled}
              optionLabel="name"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
