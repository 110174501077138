import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter} from 'two-core';

class VehiclesService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.vehicles ?? '';
  }

  async getVehicles(params: QueryParameter): Promise<ApiListResponse> {
    if (!params.filters) {
      params.filters = [];
    }
    params.filters.push(
      JSON.stringify({
        field: 'state_id',
        value: localStorage.getItem('current state'),
      })
    );
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
}

export default VehiclesService;
