import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ReportForContainerList, ReportForRunList, ReportForLocationList, QueryParameter} from 'two-core';

class ReportsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.dashboards ?? '';
  }

  async loadReportForContainerList(): Promise<ReportForContainerList> {
    const state = localStorage.getItem('current state') ?? '';

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'state',
        value: state,
      })
    );
    const params: QueryParameter = {
      filters: filters,
    };

    return this.get(this.endpoint + '/container-list', params)
      .then(data => {
        return Promise.resolve(data as unknown as ReportForContainerList);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async loadReportForRunList(): Promise<ReportForRunList> {
    const state = localStorage.getItem('current state') ?? '';

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'state',
        value: state,
      })
    );
    const params: QueryParameter = {
      filters: filters,
    };

    return this.get(this.endpoint + '/run-list', params)
      .then(data => {
        return Promise.resolve(data as unknown as ReportForRunList);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async loadReportForLocationList(): Promise<ReportForLocationList> {
    const state = localStorage.getItem('current state') ?? '';

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'state',
        value: state,
      })
    );
    const params: QueryParameter = {
      filters: filters,
    };

    return this.get(`${this.endpoint}/location-list`, params)
      .then(data => {
        return Promise.resolve(data as unknown as ReportForLocationList);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
}

export default ReportsService;
