import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faWarehouse,
  faTruckMoving,
  faCalendarAlt,
  faTruckLoading,
  faPeopleCarry,
} from '@fortawesome/free-solid-svg-icons';
import {faContainerStorage} from '@fortawesome/pro-solid-svg-icons';
import {faSirenOn, faList, faPlus, faTimes, faMinus, faPen, faBars} from '@fortawesome/pro-regular-svg-icons';
import {
  TwoAppFrame,
  AppMenuItem,
  AppContext,
  AppMenuItemTemplate,
  AppMenuItemSeparator,
  AuthService,
  MessageService,
  AuthenticatedUser,
  ToastService,
  UsersService,
  FileService,
  TwoToast,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import LocationListComponent from './components/Locations/LocationListComponent';
import LocationsService from './services/LocationsService';
import StatesService from './services/StatesService';
import FreightOrdersService from './services/FreightOrdersService';
import AllOrdersComponent from './components/AllOrders/AllOrdersComponent';
import {DropdownOption, QueryParameter, State, StateForwarder} from 'two-core';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import RunListComponent from './components/Runs/RunListComponent';
import RunsService from './services/RunsService';
import StopsService from './services/StopsService';
import SchedulesService from './services/SchedulesService';
import VehiclesService from './services/VehiclesService';
import ScheduleComponent from './components/Schedules/ScheduleComponent';
import ContainerListComponent from './components/Containers/ContainerListComponent';
import ContainersService from './services/ContainersService';
import AlarmsService from './services/AlarmsService';
import OrderComponent from './components/Order/OrderComponent';
import './scss/App.scss';
import TasksService from './services/TasksService';
import RunDetailComponent from './components/Runs/RunDetailComponent';
import ContainerComponent from './components/Container/ContainerComponent';
import TlesService from './services/TlesService';
import OrdersService from './services/OrdersService';
import LocationComponent from './components/Location/LocationComponent';
import RunComponent from './components/Run/RunComponent';
import ReportsService from './services/ReportsService';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import FreightProofsService from './services/FreightProofsService';
import {Toast} from 'primereact/toast';

library.add(
  faSirenOn,
  faList,
  faContainerStorage,
  faWarehouse,
  faTruckMoving,
  faCalendarAlt,
  faTruckLoading,
  faPeopleCarry,
  faPlus,
  faTimes,
  faMinus,
  faPen,
  faBars
);

const authService = new AuthService();
const usersService = new UsersService(authService);
const locationsService = new LocationsService(authService);
const statesService = new StatesService(authService);
const freightOrdersService = new FreightOrdersService(authService);
const runsService = new RunsService(authService);
const stopsService = new StopsService(authService);
const schedulesService = new SchedulesService(authService);
const vehiclesService = new VehiclesService(authService);
const containersService = new ContainersService(authService);
const alarmsService = new AlarmsService(authService);
const tasksService = new TasksService(authService);
const tlesService = new TlesService(authService);
const ordersService = new OrdersService(authService);
const reportsService = new ReportsService(authService);
const toastService = new ToastService();
const freightProofsService = new FreightProofsService(authService);
const fileService = new FileService(authService);
const toastRef = React.createRef<Toast>();
const twoToast = new TwoToast(toastRef);

interface AppState {
  states: DropdownOption[];
  loadingStates: boolean;
  selectedState?: string;
}

class App extends React.Component<{}, AppState> {
  static contextType = AppContext;

  subscription: Subscription = new Subscription();

  menu: AppMenuItem[];
  //routes: JSX.Element;

  constructor(props: {}) {
    super(props);

    this.state = {
      states: [],
      loadingStates: false,
      selectedState: undefined,
    };

    this.menu = [
      {
        label: 'Alarms',
        faIcon: faSirenOn,
        badgeId: 'alarmBadge',
        to: '/alarms',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return this.getMenuItemTemplate(item, options);
        },
      },
      {
        separator: true,
        template: () => {
          return this.getMenuItemSeparator();
        },
      },
      {
        label: 'Containers',
        faIcon: faContainerStorage,
        badgeId: 'containersBadge',
        to: '/containers',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return this.getMenuItemTemplate(item, options);
        },
      },
      {
        label: 'Locations',
        faIcon: faWarehouse,
        badgeId: 'locationsBadge',
        to: '/locations',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return this.getMenuItemTemplate(item, options);
        },
      },
      {
        label: 'All Orders',
        faIcon: faList,
        badgeId: 'allOrdersBadge',
        to: '/all-orders',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return this.getMenuItemTemplate(item, options);
        },
      },
      {
        separator: true,
        template: () => {
          return this.getMenuItemSeparator();
        },
      },
      {
        label: 'Runs',
        faIcon: faTruckMoving,
        badgeId: 'runsBadge',
        to: '/runs',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return this.getMenuItemTemplate(item, options);
        },
      },
      {
        label: 'Schedules',
        faIcon: faCalendarAlt,
        badgeId: 'schedulesBadge',
        to: '/schedules',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return this.getMenuItemTemplate(item, options);
        },
      },
      {
        separator: true,
        template: () => {
          return this.getMenuItemSeparator();
        },
      },
      {
        label: '3rd Party',
        faIcon: faTruckLoading,
        badgeId: '3rdPartyBadge',
        to: '/3rd-party',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return this.getMenuItemTemplate(item, options);
        },
      },
      {
        label: 'Pick-ups',
        faIcon: faPeopleCarry,
        badgeId: 'pickupsBadge',
        to: '/pick-ups',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return this.getMenuItemTemplate(item, options);
        },
      },
    ];

    this.onValueChange = this.onValueChange.bind(this);
  }

  getMenuItemTemplate(item: AppMenuItem, options: MenuItemOptions) {
    return <AppMenuItemTemplate item={item} options={options} />;
  }

  getMenuItemSeparator() {
    return <AppMenuItemSeparator />;
  }

  async componentDidMount() {
    this.subscription = MessageService.getMessage()
      .pipe(filter(message => message === 'loggedin'))
      .subscribe(() => {
        this.loadStateForwarders();
      });

    this.loadStateForwarders();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  async loadStateForwarders() {
    const userJsonString = localStorage.getItem('user') ?? '';
    const user: AuthenticatedUser = JSON.parse(userJsonString);

    if (user && user.username) {
      const params: QueryParameter = {
        aggregate: false,
        filters: [
          JSON.stringify({
            field: 'user_id',
            value: user?.uuid,
          }),
        ],
      };

      statesService?.getStateForwarders(params).then(async data => {
        const dataRecords = (data?.records as StateForwarder[]) ?? [];
        const stateIds = dataRecords.map(sf => {
          return sf.state_id ?? '';
        });

        this.loadStates(stateIds);
      });
    }
  }

  async loadStates(stateIds: string[]) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: stateIds,
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: false,
    };

    statesService?.getStates(params).then(data => {
      const dataRecords = (data?.records as State[]) ?? [];
      const states = dataRecords.map(record => {
        return {
          label: record.long_name,
          value: record.id,
        } as DropdownOption;
      });

      let currentStateId = localStorage.getItem('current state') ?? '';
      if ((currentStateId === '' || currentStateId === 'undefined') && states.length > 0) {
        currentStateId = states[0].value as string;
      }
      this.setState({states: states, selectedState: currentStateId});

      if (currentStateId !== undefined) {
        localStorage.setItem('current state', currentStateId);
      }

      MessageService.sendMessage('top-selection-loaded');
    });
  }

  async onValueChange(e: DropdownChangeParams) {
    this.setState({selectedState: e.value});
    await localStorage.setItem('current state', e.value);
    MessageService.sendMessage({name: 'top-selection-changed', value: e.value});
  }

  render() {
    const values = {
      locationsService: locationsService,
      statesService: statesService,
      freightOrdersService: freightOrdersService,
      runsService: runsService,
      stopsService: stopsService,
      schedulesService: schedulesService,
      vehiclesService: vehiclesService,
      containersService: containersService,
      alarmsService: alarmsService,
      tasksService: tasksService,
      tlesService: tlesService,
      ordersService: ordersService,
      reportsService: reportsService,
      toastService: toastService,
      authService: authService,
      usersService: usersService,
      freightProofsService: freightProofsService,
      fileService: fileService,
      twoToast: twoToast,
    };

    return (
      <>
        <TwoAppFrame menuItems={this.menu} contextValues={values}>
          <div className="topframe">
            <div className="dropdown-top">
              <Dropdown
                value={localStorage.getItem('current state')}
                options={this.state.states}
                onChange={this.onValueChange}
                placeholder="Select option"
              />
            </div>
          </div>
          <Switch>
            <Route path="/alarms"></Route>
            <Route path="/container/:id">
              <ContainerComponent />
            </Route>
            <Route path="/containers">
              <ContainerListComponent />
            </Route>
            <Route path="/location/:id">
              <LocationComponent />
            </Route>
            <Route path="/locations">
              <LocationListComponent />
            </Route>
            <Route path="/all-orders">
              <AllOrdersComponent />
            </Route>
            <Route path="/order/:id">
              <OrderComponent />
            </Route>
            <Route path="/runs/:id">
              <RunDetailComponent />
            </Route>
            <Route path="/run/:id">
              <RunComponent />
            </Route>
            <Route path="/runs">
              <RunListComponent />
            </Route>
            <Route path="/schedules">
              <ScheduleComponent />
            </Route>
            <Route path="/3rd-party"></Route>
            <Route path="/pick-ups"></Route>
          </Switch>
        </TwoAppFrame>
        <Toast ref={toastRef} />
      </>
    );
  }
}

export default App;
