import React from 'react';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoMessage,
} from 'two-app-ui';
import {
  FreightOrder,
  FreightOrderPatch,
  FreightStage,
  QueryParameter,
  Run,
  RunPatch,
  ScheduleEntry,
  Stop,
  Task,
  Vehicle,
} from 'two-core';
import {MenuItemOptions} from 'primereact/menuitem';
import {Splitter, SplitterPanel} from 'primereact/splitter';
import {Toast} from 'primereact/toast';
import SchedulesService from '../../services/SchedulesService';
import './ScheduleComponent.scss';
import FullCalendar, {
  EventApi,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventInput,
  formatDate,
  SlotLabelContentArg,
} from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimelineView from '@fullcalendar/resource-timeline';
import {ResourceInput, ResourceLabelContentArg, ResourceLaneContentArg} from '@fullcalendar/resource-common';
import interactionPlugin, {Draggable, DropArg, EventDragStopArg, EventResizeDoneArg} from '@fullcalendar/interaction';
import {faExternalLink, faMinus, faPencil} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ReScheduleDialog from './ReScheduleDialog';
import RunsService from '../../services/RunsService';
import ScheduleRunListComponent from './ScheduleRunListComponent';
import VehiclesService from '../../services/VehiclesService';
import TasksService from '../../services/TasksService';
import StopsService from '../../services/StopsService';
import FreightOrdersService from '../../services/FreightOrdersService';
import TlesService from '../../services/TlesService';
import OrdersService from '../../services/OrdersService';
import {Subscription} from 'rxjs';
import EditReminderDialog from '../Runs/EditReminderDialog';
import {messages} from '../../config/messages';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import {Draggable as Drag, Droppable} from 'react-beautiful-dnd';

library.add(faExternalLink, faPencil, faMinus);

interface State {
  events: EventInput[];
  schedules: ScheduleEntry[];
  selectedScheduleEntry?: ScheduleEntry;
  currentRunId?: number;
  showReminderDialog: boolean;
  reminderDialogDate?: Date;
  reminderDialogVehicleId?: number;
  reminderDialogScheduleEntry?: ScheduleEntry;
  showRescheduleDialog: boolean;
  rescheduleDialogDefaultDate?: Date;
  rescheduleDialogRunsIds: string[];
  currentEvents: EventApi[];
  reloadRunList: boolean;
  vehicles: Vehicle[];
  resources: ResourceInput[];
  inProgressRuns: Run[];
  freightOrders: FreightOrder[];
}

class ScheduleComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  schedulesService: SchedulesService | null = null;
  runsService: RunsService | null = null;
  vehiclesService: VehiclesService | null = null;
  tasksService: TasksService | null = null;
  stopsService: StopsService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  tlesService: TlesService | null = null;
  ordersService: OrdersService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;
  calendar: React.RefObject<FullCalendar>;
  subscription: Subscription = new Subscription();

  constructor(props = {}) {
    super(props);
    this.state = {
      events: [],
      schedules: [],
      selectedScheduleEntry: undefined,
      showReminderDialog: false,
      showRescheduleDialog: false,
      rescheduleDialogDefaultDate: undefined,
      rescheduleDialogRunsIds: [],
      currentRunId: undefined,
      currentEvents: [],
      reloadRunList: false,
      vehicles: [],
      resources: [],
      inProgressRuns: [],
      freightOrders: [],
    };

    this.toast = React.createRef();
    this.calendar = React.createRef();

    this.initMenuItems = this.initMenuItems.bind(this);
    this.resourceLaneContent = this.resourceLaneContent.bind(this);
    this.eventContent = this.eventContent.bind(this);
    this.createScheduleEntry = this.createScheduleEntry.bind(this);
    this.deleteScheduleEnties = this.deleteScheduleEnties.bind(this);
    this.updateScheduleEntry = this.updateScheduleEntry.bind(this);
    this.dragStopEvent = this.dragStopEvent.bind(this);
    this.clickEvent = this.clickEvent.bind(this);
    this.initDayMenuItems = this.initDayMenuItems.bind(this);
    this.initReminderDayMenuItems = this.initReminderDayMenuItems.bind(this);
    this.renderResourceContent = this.renderResourceContent.bind(this);
    this.renderSlotContent = this.renderSlotContent.bind(this);
    this.showReminderDialog = this.showReminderDialog.bind(this);
    this.hideReminderDialog = this.hideReminderDialog.bind(this);
  }

  async componentDidMount() {
    this.schedulesService = this.context.schedulesService;
    this.runsService = this.context.runsService;
    this.vehiclesService = this.context.vehiclesService;
    this.tasksService = this.context.tasksService;
    this.stopsService = this.context.stopsService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.ordersService = this.context.ordersService;
    this.tlesService = this.context.tlesService;
    this.toastService = this.context.toastService;

    const draggableEl = document.getElementById('draggable-table');
    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: '.drag-el',
      });
    }

    this.subscription = MessageService.getMessage().subscribe(async message => {
      const castedMessage = message as TwoMessage;
      if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
        this.loadvehicles();
        this.loadRuns();
        this.loadFreightOrders();
      } else {
        if (message === messages.taskUpdate || message === messages.reloadScheduler) {
          this.loadTimelineData();
        }
      }
    });

    this.loadvehicles();
    this.loadRuns();
    this.loadFreightOrders();
  }

  async loadTimelineData() {
    const filters: string[] = [];
    const sortBy: string[] = [];

    sortBy.push(
      JSON.stringify({
        field: 'line_up',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      filters: filters,
      aggregate: true,
    };

    this.schedulesService
      ?.getSchedules(params)
      .then(data => {
        const events: EventInput[] = [];
        const dataRecords = ((data?.records as ScheduleEntry[]) ?? [])
          .filter(se => se !== null)
          .map(se => {
            const startAt = se.start_at ? new Date(se.start_at) : undefined;
            const endAt = se.end_at ? new Date(se.end_at) : undefined;
            if (startAt && endAt) {
              const type = se.type;
              const runStage = se.run_schedule?.stage;
              const calendarDateEvent: EventInput = {
                id: se.id?.toString(),
                title: type === 'run' ? (se.run_schedule?.name ?? '') : (se.task?.description ?? ''),
                start: startAt,
                end: endAt,
                resourceId: se.vehicle_id.toString(),
                className:
                  type === 'run' ? `run-stage-${(runStage ?? '').toLowerCase().replaceAll(' ', '-')}` : 'task-reminder',
                ['order']: se.line_up,
              };

              events.push(calendarDateEvent);
            }
            return {...se, start_at: startAt, end_at: endAt} as ScheduleEntry;
          });

        this.setState({
          schedules: dataRecords,
          events: events,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  async loadRuns() {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'stage',
        value: ['In Progress'],
        condition: 'in',
      })
    );

    filters.push(
      JSON.stringify({
        field: 'state_id',
        value: localStorage.getItem('current state'),
      })
    );

    const params: QueryParameter = {
      filters: filters,

      aggregate: true,
    };

    this.runsService
      ?.getRuns(params)
      .then(data => {
        const dataRecords = (data.records as Run[]) ?? [];
        this.setState({
          inProgressRuns: dataRecords,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  async loadFreightOrders() {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'run.stage',
        value: 'In Progress',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const dataRecords = (data.records as FreightOrder[]) ?? [];

        this.setState({
          freightOrders: dataRecords,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  async loadvehicles() {
    const sortBy: string[] = [];
    sortBy.push(
      JSON.stringify({
        field: 'name',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      aggregate: true,
    };

    this.vehiclesService
      ?.getVehicles(params)
      .then(data => {
        const dataRecords = (data.records as Vehicle[]) ?? [];
        const resources = dataRecords.map(vehicle => {
          const resource: ResourceInput = {
            id: vehicle.id?.toString() ?? '',
            title: vehicle.name,
          };
          return resource;
        });

        this.setState({
          vehicles: dataRecords,
          resources: resources,
        });

        this.loadTimelineData();
      })
      .catch(error => {
        console.error(error);
      });
  }

  initDayMenuItems(day: Date): AppMenuItem[] {
    return [
      {
        label: 'Move All Jobs',
        faIcon: ['far', 'pencil'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.rescheduleDay(day);
        },
      },
      {
        label: 'Clear Day',
        faIcon: ['far', 'times'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.clearDay(day);
        },
      },
    ];
  }

  initReminderDayMenuItems(date?: Date, resourceId?: string): AppMenuItem[] {
    return [
      {
        label: 'Add Reminder',
        faIcon: ['far', 'plus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showReminderDialog(date, resourceId ? +resourceId : undefined);
        },
      },
    ];
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  initMenuItems(day: Date, id: string): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    const scheduleEntry = this.state.schedules.find(s => s.id?.toString() === id);
    const type = scheduleEntry?.type;
    if (type === 'run') {
      const run = scheduleEntry?.run_schedule;

      const runStage = run?.stage;
      if (runStage === 'In Progress') {
        const cancelMenuItem: AppMenuItem = {
          label: 'Roll Back',
          faIcon: ['far', 'times'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.rollBackRun(scheduleEntry);
          },
        };
        menuItems.push(cancelMenuItem);
      }
      if (runStage === 'Scheduled') {
        const reassignMenuItem: AppMenuItem = {
          label: 'Re-Assign',
          faIcon: ['far', 'pencil'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.onRescheduleAction(day, [id], scheduleEntry);
          },
        };
        const unscheduleMenuItem: AppMenuItem = {
          label: 'Un-Schedule',
          faIcon: ['far', 'minus'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.remove(id);
          },
        };
        menuItems.push(reassignMenuItem);
        menuItems.push(unscheduleMenuItem);
      }
    } else {
      const reassignMenuItem: AppMenuItem = {
        label: 'Remove Reminder',
        faIcon: ['far', 'times'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.removeReminder(id);
        },
      };
      menuItems.push(reassignMenuItem);
    }

    return menuItems;
  }

  onRescheduleAction(day: Date, runsIds: string[], scheduleEntry: ScheduleEntry | undefined) {
    if (runsIds.length > 0) {
      this.setState({
        showRescheduleDialog: true,
        rescheduleDialogDefaultDate: day,
        rescheduleDialogRunsIds: runsIds,
        selectedScheduleEntry: scheduleEntry,
      });
    }
  }

  getDayEvents(day: Date): EventInput[] {
    const events = this.state.events;

    const date = formatDate(day, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    const dayEvents = events.filter(
      e =>
        e.start &&
        formatDate(e.start, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }) === date
    );
    return dayEvents;
  }

  rescheduleDay(day: Date) {
    const dayEvents = this.getDayEvents(day);

    if (dayEvents && dayEvents.length > 0) {
      const ids = dayEvents.map(e => {
        return e.id ?? '';
      });
      this.onRescheduleAction(day, ids, undefined);
    } else {
      this.toastService?.showWarn(this.toast, 'There is no events.');
    }
  }

  clearDay(day: Date) {
    const dayEvents = this.getDayEvents(day);

    if (dayEvents && dayEvents.length > 0) {
      const ids = dayEvents.map(e => {
        return e.id ?? '';
      });
      this.deleteScheduleEnties(ids);
    } else {
      this.toastService?.showWarn(this.toast, 'Day is already cleared.');
    }
  }

  removeReminder(id: string) {
    const scheduleEntry = this.state.schedules.find(s => s.id?.toString() === id);

    if (scheduleEntry) {
      this.schedulesService?.deleteScheduleEntry(scheduleEntry.id?.toString() ?? '').then(() => {
        this.toastService?.showSuccess(this.toast, 'Reminder deleted.');
        MessageService.sendMessage(messages.reloadScheduler);
      });
    } else {
      this.toastService?.showError(this.toast, 'Sorry, Task reminder remove failed, please try again.');
    }
  }

  remove(id: string) {
    const ids = [id];
    this.deleteScheduleEnties(ids);
  }

  createScheduleEntry(runId: string, startDate: Date, endDate: Date, vehicleId: number, lineUp: number) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    startDate.setHours(6);
    endDate.setHours(16);
    const scheduleEntry: ScheduleEntry = {
      type: 'run',
      run_id: parseInt(runId),
      description: '',
      line_up: lineUp,
      vehicle_id: vehicleId,
      start_at: start,
      end_at: end,
      updated_at: new Date(),
    };

    this.schedulesService
      ?.createScheduleEntry(scheduleEntry)
      .then(() => {
        const updatedRun: RunPatch = {stage: 'Scheduled'};
        this.updateRun(runId, updatedRun)
          .then(() => {
            this.toastService?.showSuccess(this.toast, 'Run Schedule created successfully.');
            this.loadTimelineData();
            this.reloadRunList();
          })
          .catch(error => {
            this.toastService?.showError(this.toast, 'Sorry, run schedule create failed, please try again.');
            console.error(error);
          });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, run schedule create failed, please try again.');
        console.error(error);
      });
  }

  async updateRun(runId: string, run: RunPatch) {
    return this.runsService
      ?.updateRun(runId, run)
      .then(() => {})
      .catch(error => {
        console.error('error: ' + error);
      });
  }

  updateScheduleEntry(id: string, startDate: Date, endDate: Date, vehicleId: number | undefined): void {
    const scheduleEntry = this.state.schedules.find(s => s.id?.toString() === id);
    if (scheduleEntry) {
      const updateScheduleEntry: ScheduleEntry = {
        ...scheduleEntry,
        start_at: startDate,
        end_at: endDate,
      };

      if (vehicleId) {
        updateScheduleEntry.vehicle_id = vehicleId;
      }

      this.schedulesService
        ?.updateScheduleEntry(scheduleEntry.id?.toString() ?? '', updateScheduleEntry)
        .then(() => {
          this.toastService?.showSuccess(this.toast, 'Run Schedule updated successfully.');
          this.loadTimelineData();
        })
        .catch(error => {
          this.toastService?.showError(this.toast, 'Sorry, run schedule update failed, please try again.');
          this.loadTimelineData();
          console.error(error);
        });
    } else {
      this.toastService?.showError(this.toast, 'Sorry, run schedule update failed, please try again.');
      this.loadTimelineData();
    }
  }

  deleteScheduleEnties(ids: string[]) {
    const scheduleEntries = this.state.schedules.filter(s => ids.includes(s.id?.toString() ?? '')) ?? [];

    Promise.all(
      scheduleEntries.map((scheduleEntry: ScheduleEntry) => {
        return this.schedulesService?.deleteScheduleEntry(scheduleEntry.id?.toString() ?? '').then(() => {
          const updatedRun: RunPatch = {stage: 'Draft'};
          return this.updateRun(scheduleEntry.run_id?.toString() ?? '', updatedRun);
        });
      })
    )
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Run Schedule removed successfully.');
        MessageService.sendMessage(messages.reloadScheduler);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, run schedule remove failed, please try again.');
        console.error(error);
      });
  }

  rollBackRun(scheduleEntry: ScheduleEntry | undefined) {
    const runId = scheduleEntry?.run_id?.toString() ?? '';
    this.updateStopsAndTasks(runId);
  }

  async updateStopsAndTasks(runId: string) {
    const runs = this.state.inProgressRuns;
    const run = runs.find(r => r.id?.toString() === runId);
    if (run) {
      const tasks = run.tasks?.filter(r => r !== null) ?? [];
      const stops = run.stops?.filter(s => s !== null) ?? [];
      const freightOrders = this.state.freightOrders;
      const runFreightOrders = freightOrders.filter(fo => fo.runs?.map(r => r.id?.toString() ?? '').includes(runId));

      const updatedTasks = tasks.map(task => {
        const updatedTask: Task = {
          ...task,
          executed_at: undefined,
          executed_by_id: undefined,
          executed_on: undefined,
        };
        return updatedTask;
      });

      const updatePromise = this.updateTasks(updatedTasks).then(() => {
        const updatedStops = stops.map(stop => {
          const updatedStop: Stop = {...stop, stage: 'Planned'};
          return updatedStop;
        });
        return this.updateStops(updatedStops).then(() => {
          const runPatch: RunPatch = {
            stage: 'Scheduled',
          };
          return this.updateRun(run.id?.toString() ?? '', runPatch).then(() => {
            const state = localStorage.getItem('current state')!;
            return Promise.all(
              runFreightOrders.map(async (freightOrder: FreightOrder) => {
                if (freightOrder.id) {
                  const locationType = freightOrder.location?.type;
                  const newStage: FreightStage =
                    locationType === 'warehouse'
                      ? 'At Warehouse'
                      : locationType === 'factory'
                        ? 'At Factory'
                        : freightOrder.route![state].stage;
                  const routePart = {
                    ...freightOrder.route![state],
                    stage: newStage,
                  };
                  const route = {...freightOrder.route, [state]: routePart};

                  const updatedFreightOrder: FreightOrderPatch = {
                    route: route,
                  };
                  return this.freightOrdersService?.updateFreightOrder(freightOrder.id.toString(), updatedFreightOrder);
                }
                return;
              })
            );
          });
        });
      });

      updatePromise
        .then(() => {
          this.toastService?.showSuccess(this.toast, 'Run rolled back successfully.');
          this.reloadRunList();
          this.loadTimelineData();
        })
        .catch(() => {
          this.toastService?.showError(this.toast, 'Sorry, Run roll back failed, please try again.');
        });
    } else {
      this.toastService?.showError(this.toast, 'Sorry, Run roll back failed, please try again.');
    }
  }

  async updateTasks(tasks: Task[]): Promise<(Task | undefined)[]> {
    return Promise.all(
      tasks.map(async (task: Task) => {
        if (task.id) {
          return this.tasksService?.updateTask(task.id.toString(), task);
        }
        return;
      })
    );
  }

  async updateStops(stops: Stop[]): Promise<(Stop | undefined)[]> {
    return Promise.all(
      stops.map(async (stop: Stop) => {
        if (stop.id) {
          return this.stopsService?.updateStop(stop.id.toString(), stop);
        }
        return;
      })
    );
  }

  handleEvents = (events: EventApi[]) => {
    this.setState({
      currentEvents: events,
    });
  };

  getListStyle = () => ({
    padding: 0,
  });

  eventContent(eventInfo: EventContentArg) {
    const date = eventInfo.event.start;
    const id = eventInfo.event.id;
    const schedules = this.state.schedules;
    const scheduleEntry = schedules.find(s => s.id?.toString() === eventInfo.event.id);
    const type = scheduleEntry?.type;
    const initMenuItems = () => this.initMenuItems(date!, id);

    const event = eventInfo.event;
    const data = JSON.stringify(event);
    let title = '';
    if (type === 'run') {
      title = `${eventInfo.event.title}-${id}-${eventInfo.event.extendedProps['order']}`;
    } else {
      title = `${scheduleEntry?.description}`;
    }

    return (
      date && (
        <Drag key={id} draggableId={data} index={parseInt(id)} disableInteractiveElementBlocking={true}>
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...this.getListStyle(),
              }}
            >
              <AppColumnMenuBodyTemplate
                rowItemIdentifier={eventInfo.event?.id?.toString() ?? ''}
                isDynamicMenuItems={true}
                initMenuItems={initMenuItems}
                selectedItems={[]}
              >
                <>
                  <FontAwesomeIcon className={'p-ml-1 p-mr-2'} icon={['far', 'bars']} />
                  <i className={'p-ml-1'}>{title}</i>
                  {type === 'run' && <FontAwesomeIcon className={'p-ml-2 p-my-auto'} icon={['far', 'external-link']} />}
                </>
              </AppColumnMenuBodyTemplate>
            </div>
          )}
        </Drag>
      )
    );
  }

  resourceLaneContent(args: ResourceLaneContentArg) {
    //create cells with menu
    const calendarView = this.calendar.current?.getApi().view;
    const activeStartDate = calendarView?.activeStart;
    const activeEndDate = calendarView?.activeEnd;
    const cells = [];
    if (activeStartDate && activeEndDate) {
      const dateDifference = activeEndDate.getTime() - activeStartDate.getTime();
      const daysCount = Math.ceil(dateDifference / (1000 * 3600 * 24));
      const cellWidth = 100.0 / daysCount;
      const date = new Date(activeStartDate.getTime());
      while (date < activeEndDate) {
        const resourceId = args.resource.id;

        const cellDate = new Date(date);
        const initMenuItems = () => this.initReminderDayMenuItems(cellDate, resourceId);
        cells.push(
          <div
            className={'cell-menu-container'}
            style={{
              width: `${cellWidth}%`,
            }}
            key={cellDate.toString()}
          >
            <AppColumnMenuBodyTemplate
              rowItemIdentifier={''}
              isDynamicMenuItems={false}
              initMenuItems={initMenuItems}
              alwaysShow={false}
              selectedItems={[]}
            >
              <></>
            </AppColumnMenuBodyTemplate>
          </div>
        );
        date.setDate(date.getDate() + 1);
      }
    }

    return (
      <div className="p-d-flex p-jc-between h-100">
        {cells.map(cell => {
          return cell;
        })}
      </div>
    );
  }

  renderSlotContent(args: SlotLabelContentArg) {
    return <div>{args.text}</div>;
  }

  renderResourceContent(args: ResourceLabelContentArg) {
    return <div>{args.resource.title}</div>;
  }

  clickEvent(event: EventClickArg) {
    if (
      !(
        (event.jsEvent.target as Element).closest('.table-menu-button-container') ||
        (event.jsEvent.target as Element).closest('.p-menuitem')
      )
    ) {
      const schedules = this.state.schedules;
      const scheduleEntry = schedules.find(s => s.id?.toString() === event.event.id);
      const type = scheduleEntry?.type;
      if (type === 'run') {
        const runId = scheduleEntry?.run_id;
        window.open(`/run/${runId}`, '_blank');
      } else {
        const resourceId = scheduleEntry?.vehicle_id;
        const date = scheduleEntry?.start_at;
        this.showReminderDialog(date, resourceId, scheduleEntry);
      }
    }
  }

  dropEvent(event: EventDropArg) {
    const oldEvent = event.oldEvent;
    const newEvent = event.event;

    const startDate = newEvent.start;
    let endDate = newEvent.end;
    const runId = oldEvent?.id;
    const resources = newEvent.getResources();
    const resource = resources && resources.length > 0 ? resources[0] : undefined;
    const resourceId = resource?._resource.id;

    if (runId && startDate) {
      if (!endDate) {
        endDate = new Date(startDate);
        endDate.setHours(startDate.getHours() + 1);
      }

      const vehicleId = resourceId ? parseInt(resourceId) : undefined;
      this.updateScheduleEntry(runId, startDate, endDate ?? startDate, vehicleId);
    } else {
      this.toastService?.showError(this.toast, 'Sorry, run schedule update failed, please try again.');
    }
  }

  dragStopEvent(args: EventDragStopArg) {
    if (this.isDraggedToUnassignedTable(args.jsEvent.clientX, args.jsEvent.clientY)) {
      this.remove(args.event.id);
    }
  }

  drop(event: DropArg) {
    const startDate = event.date;
    const runId = event?.draggedEl?.id;
    const resourceId = event.resource?._resource.id;
    if (runId && resourceId) {
      const vehicleId = parseInt(resourceId);
      const schedules = this.state.schedules;
      const existingSchedule = schedules.find(s => s.run_id?.toString() === runId);
      if (!existingSchedule) {
        const view = event.view.type;
        if (view === 'dayGridMonth') {
          const events = this.state.events;
          const dateEvents = events.filter(e => (e.start as Date).setHours(0, 0, 0) === startDate.setHours(0, 0, 0));
          if (dateEvents && dateEvents.length > 0) {
            const eventsCount = dateEvents.length + 1;
            let start = 0;
            const duration = Math.floor(24 / eventsCount);
            dateEvents.forEach(event => {
              const eventStartDate = (event.start as Date) ?? new Date(startDate);

              eventStartDate.setHours(start, 0, 0);

              const eventEndDate = new Date(eventStartDate);
              eventEndDate.setHours(eventStartDate.getHours() + duration);
              this.updateScheduleEntry(event.id ?? '', eventStartDate, eventEndDate, undefined);
              start = start + duration;
            });

            this.createEvent(startDate, runId, start, duration, vehicleId);
          } else {
            this.createEvent(startDate, runId, 6, 12, vehicleId);
          }
        } else {
          const endDate = new Date(startDate);
          endDate.setHours(startDate.getHours() + 1);

          let lastLineUp =
            schedules
              .filter(s => s.start_at.getDate() === startDate.getDate())
              .reduce((a, b) => (a.line_up > b.line_up ? a : b), {line_up: 0})?.line_up ?? 0;

          const lineUp = ++lastLineUp;
          this.createScheduleEntry(runId, startDate, endDate, vehicleId, lineUp);
        }
      } else {
        this.toastService?.showWarn(this.toast, 'Sorry, run is scheduled already.');
      }
    } else {
      this.toastService?.showError(this.toast, 'Sorry, run schedule create failed, please try again.');
    }
  }

  createEvent(startDate: Date, runId: string, hour: number, duration: number, vehicleId: number) {
    startDate.setHours(hour, 0, 0);

    const endDate = new Date(startDate);
    endDate.setHours(startDate.getHours() + duration);

    const dateSchedules = this.state.schedules
      .filter(s => s.start_at.getDate() === startDate.getDate())
      .sort((a, b) => b.line_up - a.line_up);

    let lastLineUp = dateSchedules?.[0]?.line_up ?? 0;
    const lineUp = ++lastLineUp;

    this.createScheduleEntry(runId, startDate, endDate, vehicleId, lineUp);
  }

  resize(event: EventResizeDoneArg) {
    const oldEvent = event.oldEvent;
    const newEvent = event.event;

    const startDate = newEvent.start;
    let endDate = newEvent.end;
    const runId = oldEvent?.id;

    if (runId && startDate) {
      if (!endDate) {
        endDate = new Date(startDate);
        endDate.setHours(startDate.getHours() + 1);
      }

      this.updateScheduleEntry(runId, startDate, endDate ?? startDate, undefined);
    } else {
      this.toastService?.showError(this.toast, 'Sorry, run schedule update failed, please try again.');
    }
  }

  /**
   * Function for check "trash container" for full calendar's event
   * @param x
   * @param y
   */
  isDraggedToUnassignedTable(x: number, y: number) {
    const unassignedTable: HTMLElement | null = document.getElementById('draggable-table');
    if (unassignedTable) {
      const offsetLeft: number = unassignedTable.offsetLeft;
      const offsetTop: number = unassignedTable.offsetTop;
      const offsetRight: number = offsetLeft + unassignedTable.offsetWidth;
      const offsetBottom: number = offsetTop + unassignedTable.offsetHeight;

      // Compare
      if (x >= offsetLeft && y >= offsetTop && x <= offsetRight && y <= offsetBottom) {
        return true;
      }
    }
    return false;
  }

  reloadRunList() {
    this.setState({reloadRunList: !this.state.reloadRunList});
  }

  showReminderDialog(date?: Date, vehicleId?: number, scheduleEntry?: ScheduleEntry) {
    this.setState({
      showReminderDialog: true,
      reminderDialogDate: date,
      reminderDialogVehicleId: vehicleId,
      reminderDialogScheduleEntry: scheduleEntry,
    });
  }

  hideReminderDialog() {
    this.setState({showReminderDialog: false});
  }

  onDragEnd = (result: DropResult) => {
    const {source, destination} = result;
    const destinationDropId = destination?.droppableId;
    const sourceDropId = source?.droppableId;

    console.log('onDragEnd');
    console.log(result);

    if (!destination || destinationDropId !== sourceDropId) {
      return;
    }

    const sourceId = destination?.index;
    const destinationId = source.index;

    if (!sourceId || !destinationId) {
      this.toastService?.showWarn(this.toast, 'Wrong id');
      return;
    }

    const scheduleEntries = this.state.schedules;
    const sourceScheduleEntry = scheduleEntries.find(se => se.id?.toString() === sourceId.toString());
    const destinationScheduleEntry = scheduleEntries.find(se => se.id?.toString() === destinationId?.toString());

    if (!sourceScheduleEntry || !destinationScheduleEntry) {
      return;
    }

    const day1 = new Date(sourceScheduleEntry.start_at);
    const day2 = new Date(destinationScheduleEntry.start_at);

    day1.setHours(0, 0, 0, 0);
    day2.setHours(0, 0, 0, 0);

    if (day1.getTime() !== day2.getTime()) {
      return;
    }

    const updatedSourceScheduleEntry = {
      ...sourceScheduleEntry,
      line_up: destinationScheduleEntry.line_up,
    };

    const updatedDestinationScheduleEntry = {
      ...destinationScheduleEntry,
      line_up: sourceScheduleEntry.line_up,
    };

    this.schedulesService
      ?.updateScheduleEntry(updatedSourceScheduleEntry.id?.toString() ?? '', updatedSourceScheduleEntry)
      .then(() => {
        this.schedulesService
          ?.updateScheduleEntry(updatedDestinationScheduleEntry.id?.toString() ?? '', updatedDestinationScheduleEntry)
          .then(() => {
            this.toastService?.showSuccess(this.toast, 'Run Schedule updated successfully.');
            this.loadTimelineData();
          });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, run schedule update failed, please try again.');
        console.error(error);
      });
  };

  render() {
    const calendar = (
      <div className="schedule-calendar p-pb-2" style={{height: '100%', width: '100%'}}>
        <FullCalendar
          schedulerLicenseKey={'CC-Attribution-NonCommercial-NoDerivatives'}
          ref={this.calendar}
          plugins={[interactionPlugin, resourceTimelinePlugin, resourceTimelineView]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'resourceTimelineWeek,resourceTimelineMonth',
          }}
          slotDuration={{days: 1}}
          duration={{weeks: 1}}
          slotLabelFormat={{
            weekday: 'short',
            day: 'numeric',
          }}
          firstDay={1}
          initialView="resourceTimelineWeek"
          resourceAreaHeaderContent={'Vehicles'}
          resourceAreaWidth={150}
          resources={this.state.resources}
          editable={true}
          selectable={false}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          droppable={true}
          initialEvents={this.state.events}
          events={this.state.events}
          eventContent={this.eventContent}
          resourceLaneContent={this.resourceLaneContent}
          eventClick={this.clickEvent}
          eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
          eventDrop={arg => this.dropEvent(arg)}
          drop={arg => this.drop(arg)}
          eventResize={arg => this.resize(arg)}
          eventDragStop={this.dragStopEvent}
          slotLabelContent={this.renderSlotContent}
          resourceLabelContent={this.renderResourceContent}
          eventOrder={'order'}
        />
      </div>
    );
    return (
      <>
        <div id="calendar" className="page-container">
          <Splitter style={{height: '100%'}} layout="vertical">
            <SplitterPanel className="p-d-flex splitter-panel-calendar" size={50} minSize={10}>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable
                  droppableId={'calendar'}
                  renderClone={(provided, snapshot, rubric) => {
                    const event = JSON.parse(rubric.draggableId) as EventApi;

                    return (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {event.title}
                      </div>
                    );
                  }}
                >
                  {provided => (
                    <div ref={provided.innerRef}>
                      {calendar}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </SplitterPanel>
            <SplitterPanel className="p-d-flex" size={50} minSize={10}>
              <div id="draggable-table" className="p-d-flex p-pt-2" style={{height: '100%', width: '100%'}}>
                <ScheduleRunListComponent reloadData={this.state.reloadRunList} />
              </div>
            </SplitterPanel>
          </Splitter>
        </div>
        <>
          <ReScheduleDialog
            runsIds={this.state.rescheduleDialogRunsIds}
            showDialog={this.state.showRescheduleDialog}
            onHide={() => {
              this.setState({
                showRescheduleDialog: false,
                selectedScheduleEntry: undefined,
              });
            }}
            defaultVehicle={this.state.selectedScheduleEntry?.vehicle}
            defaultDate={this.state.rescheduleDialogDefaultDate ?? new Date()}
            updateScheduleEntry={this.updateScheduleEntry}
            toast={this.toast}
          />
          <EditReminderDialog
            date={this.state.reminderDialogDate}
            showDialog={this.state.showReminderDialog}
            onHide={this.hideReminderDialog}
            vehicleId={this.state.reminderDialogVehicleId}
            toast={this.toast}
          />
          <Toast ref={this.toast} />
        </>
      </>
    );
  }
}

export default ScheduleComponent;
