import React from 'react';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {MenuItemOptions} from 'primereact/menuitem';
import {MultiSelect} from 'primereact/multiselect';
import {DropdownChangeParams} from 'primereact/dropdown';
import {Toast} from 'primereact/toast';
import {
  AppColumnMenuBodyTemplate,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  TwoDataTable,
  UsersService,
  ToastService,
} from 'two-app-ui';
import {AppContext} from 'two-app-ui';
import {QueryParameter, Task, TaskType, User} from 'two-core';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import TasksService from '../../services/TasksService';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import config from '../../config/config';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';

const typeOptions: TaskType[] = ['drop', 'final-drop', 'other', 'pickup'];

interface Props {
  taskIds: number[] | undefined;
  addTask?: () => void;
  editTask?: (task: Task) => void;
  notifyRemovedTasks?: (tasks: Task[]) => void;
  customMenuItems?: (selectedItems: Task[]) => AppMenuItem[];
  inRunDetail?: boolean;
}

interface State {
  loading: boolean;
  items: Task[];
  selectedItems: Task[];
  totalItems: number;
  pagination: {
    pageSize: number;
    offset: number;
  };
  filters: {
    type: string;
    description: string;
    order: string;
    executed_on: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  users: User[];
}

class TaskListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  tasksService: TasksService | null = null;
  usersService: UsersService | null = null;
  toastService: ToastService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      loading: false,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      filters: {
        type: '',
        description: '',
        order: '',
        executed_on: {
          fromDate: null,
          toDate: null,
        },
      },
      sortBy: null,
      users: [],
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleSelectedItems = this.handleSelectedItems.bind(this);
    this.setChangeSelectedItem = this.setChangeSelectedItem.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.taskBodyTemplate = this.taskBodyTemplate.bind(this);
    this.executedByBodyTemplate = this.executedByBodyTemplate.bind(this);
    this.executedOnBodyTemplate = this.executedOnBodyTemplate.bind(this);
    this.doneBodyTemplate = this.doneBodyTemplate.bind(this);
  }

  async componentDidMount() {
    this.tasksService = this.context.tasksService;
    this.usersService = this.context.usersService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.taskUpdate || message === messages.reloadTaskList) {
        this.loadData();
      }
    });

    this.loadData();
    this.loadUsers();
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];
    const sortBy: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: this.props.taskIds ?? [],
        condition: 'in',
      })
    );

    if (this.state.filters.type && this.state.filters.type.length) {
      filters.push(
        JSON.stringify({
          field: 'type',
          value: this.state.filters.type,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.description) {
      filters.push(
        JSON.stringify({
          field: 'description',
          value: this.state.filters.description,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.order) {
      filters.push(
        JSON.stringify({
          field: 'freight_order.id',
          value: this.state.filters.order,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.executed_on.fromDate) {
      const fromDate = this.state.filters.executed_on.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'executed_on',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.executed_on.toDate) {
      const toDate = this.state.filters.executed_on.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'executed_on',
          value: toDate,
          condition: '<=',
        })
      );
    }

    sortBy.push(
      JSON.stringify({
        field: this.state.sortBy?.field ?? 'line_up',
        direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
      })
    );

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    this.tasksService
      ?.getTasks(params)
      .then(data => {
        const dataRecords = (data?.records as Task[]) ?? [];

        this.handleSelectedItems(dataRecords);
        this.setState({
          items: dataRecords,
          totalItems: data?.total_records ?? 0,
          loading: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
      });
  }

  loadUsers() {
    const params: QueryParameter = {
      aggregate: false,
    };
    this.usersService
      ?.getUsers(params)
      .then(data => {
        const dataRecords = (data?.records as User[]) ?? [];

        this.setState({
          users: dataRecords,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
      });
  }

  initMenuItems(): AppMenuItem[] {
    const selectedItems = this.state.selectedItems;
    const selectedItemsCount = selectedItems.length;

    if (this.props.customMenuItems) {
      return this.props.customMenuItems(selectedItems);
    }
    if (selectedItemsCount > 0) {
      return this.initOtherMenuItems(selectedItems, selectedItemsCount);
    }
    return [];
  }

  initOtherMenuItems(selectedItems: Task[], selectedItemsCount: number): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const editTaskMenu: AppMenuItem = {
      label: 'Edit Task',
      faIcon: ['far', 'pen'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.editTask(selectedItems);
      },
    };

    if (selectedItemsCount === 1) {
      menuItems.push(editTaskMenu);
    }

    const otherMenuItems: AppMenuItem[] = [
      {
        label: 'Add Task',
        faIcon: ['far', 'plus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.addTask();
        },
      },

      {
        label: 'Remove Task',
        faIcon: ['far', 'minus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.delete();
        },
      },
    ];

    menuItems.push(...otherMenuItems);
    return menuItems;
  }

  editTask(selectedItems: Task[]) {
    if (this.props.editTask) {
      this.props.editTask(selectedItems[0]);
      this.setChangeSelectedItems([]);
    }
  }

  addTask() {
    if (this.props.addTask) {
      this.props.addTask();
      this.setChangeSelectedItems([]);
    }
  }

  async delete() {
    const tasks: Task[] = this.state.selectedItems;
    this.deleteTasks(tasks);
    if (this.props.notifyRemovedTasks) {
      this.props?.notifyRemovedTasks(tasks);
    }
  }

  async deleteTasks(tasks: Task[]) {
    Promise.all(
      tasks.map(async (currentTask: Task) => {
        const id = currentTask?.id?.toString() ?? '';

        return this.tasksService
          ?.deleteTask(id)
          .then(() => {
            this.toastService?.showSuccess(this.toast, `Task ${currentTask?.line_up} deleted successfully.`);
          })
          .catch(error => {
            this.toastService?.showError(
              this.toast,
              `Sorry, Task ${currentTask?.line_up} delete failed, please try again.`
            );
            console.error('error: ' + error);
          });
      })
    ).then(() => {
      MessageService.sendMessage(messages.taskUpdate);
      this.setChangeSelectedItems([]);
      return Promise.resolve();
    });
  }

  handleSelectedItems(allItems: Task[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: Task[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Task[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: Task) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stopTypingDetection);
  };

  stopBodyTemplate(rowData: Task) {
    const state = localStorage.getItem('current state') ?? '';
    const location = rowData.location;
    let locationName = '';
    if (location) {
      if (location.state_id === state) {
        locationName = location.name;
      } else {
        locationName = `${location.name} ${location.state_id}`;
      }
    }
    const locationType = location?.type?.charAt(0)?.toUpperCase() ?? '';
    return <span>{`${locationType}: ${locationName}`}</span>;
  }

  taskBodyTemplate(rowData: Task) {
    return (
      <React.Fragment>
        <AppColumnMenuBodyTemplate
          rowItemIdentifier={rowData?.id?.toString() ?? ''}
          isDynamicMenuItems={true}
          initMenuItems={this.initMenuItems}
          selectedItems={this.state.selectedItems}
          handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
        >
          <span>{rowData.type}</span>
        </AppColumnMenuBodyTemplate>
      </React.Fragment>
    );
  }

  executedOnBodyTemplate(rowData: Task) {
    return (
      <span>
        {rowData.executed_on ? DateTime.fromISO(rowData.executed_on.toString()).toFormat(formats.dateTime) : ''}
      </span>
    );
  }

  executedByBodyTemplate(rowData: Task) {
    const user = this.state.users.find(u => u.id === rowData.executed_by_id);
    return <span>{user?.username}</span>;
  }

  doneBodyTemplate(rowData: Task) {
    if (!rowData.executed_on) {
      return '';
    }

    const user = this.state.users.find(u => u.id === rowData.executed_by_id);
    const userName = user?.username ?? '';
    const dateString = DateTime.fromJSDate(new Date(rowData.executed_on)).toFormat(formats.dateTime);
    const doneValue = `${userName} ${dateString}`;
    return <span>{doneValue}</span>;
  }

  render() {
    const {inRunDetail} = this.props;

    const descriptionFilter = (
      <InputText
        name="description"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const orderFilter = (
      <InputText
        name="order"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const typeFilter = (
      <MultiSelect
        value={this.state.filters.type}
        options={typeOptions}
        name="type"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const executedOnFilter = (
      <DateColumnFilter
        name="executed_on"
        value={this.state.filters.executed_on}
        onChange={e => this.onFilterChange(e)}
      />
    );

    return (
      <div id="task_list_page_container" className="page-container">
        <TwoDataTable
          pageSizeIdentifier={undefined}
          heightToScroll={'300px'}
          sizeIdentifiers={[]}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={{}}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e)}
          onSort={e => this.onSort(e)}
          selectionMode={'multiple'}
          selectedItems={this.state.selectedItems}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as Task[])}
          initMenuItems={this.initMenuItems}
        >
          {inRunDetail && (
            <Column header="Stop" body={this.stopBodyTemplate} showFilterMenu={false} style={{width: '150px'}} />
          )}
          <Column
            header="Task"
            field="type"
            sortable
            filter
            filterElement={typeFilter}
            body={this.taskBodyTemplate}
            showFilterMenu={false}
            style={{width: '150px'}}
          />
          <Column
            header="Description"
            field="description"
            sortable
            filter
            filterElement={descriptionFilter}
            showFilterMenu={false}
            style={{width: '200px'}}
          />
          {!inRunDetail && (
            <Column
              header="Order"
              field="freight_order.id"
              sortable
              filter
              filterElement={orderFilter}
              showFilterMenu={false}
              style={{width: '150px'}}
            />
          )}
          {!inRunDetail && (
            <Column
              header="Executed On"
              sortable
              filter
              filterElement={executedOnFilter}
              body={this.executedOnBodyTemplate}
              showFilterMenu={false}
              style={{width: '150px'}}
            />
          )}
          {!inRunDetail && (
            <Column
              header="Executed By"
              body={this.executedByBodyTemplate}
              showFilterMenu={false}
              style={{width: '150px'}}
            />
          )}
          <Column header="Done" body={this.doneBodyTemplate} showFilterMenu={false} style={{width: '150px'}} />
        </TwoDataTable>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default TaskListComponent;
