import React from 'react';
import {InputTextarea} from 'primereact/inputtextarea';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {ScheduleEntry, ScheduleEntryPatch} from 'two-core';
import {Toast} from 'primereact/toast';
import SchedulesService from '../../services/SchedulesService';
import VehiclesService from '../../services/VehiclesService';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  vehicleId?: number;
  date?: Date;
  toast: React.RefObject<Toast>;
  scheduleEntry?: ScheduleEntry;
}

interface State {
  scheduleEntryPatch: ScheduleEntryPatch;
  loading: boolean;
}

class EditReminderDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  schedulesService: SchedulesService | null = null;
  vehiclesService: VehiclesService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      scheduleEntryPatch: {},
      loading: false,
    };

    this.onSave = this.onSave.bind(this);
    this.setDescription = this.setDescription.bind(this);
    this.onShow = this.onShow.bind(this);
  }

  componentDidMount() {
    this.schedulesService = this.context.schedulesService;
    this.toastService = this.context.toastService;
  }

  async onShow() {
    this.setState({
      scheduleEntryPatch: {},
      loading: false,
    });
  }

  async onSave() {
    const {scheduleEntryPatch} = this.state;
    const {scheduleEntry, onHide, vehicleId, date} = this.props;
    const startDate = date;
    const endDate = date ? new Date(date) : undefined;
    startDate?.setHours(6);
    endDate?.setHours(16);

    if (!scheduleEntryPatch?.description) {
      this.toastService?.showSuccess(this.props.toast, 'Description field is empty!');
      return;
    }
    if (scheduleEntry && scheduleEntry.description === scheduleEntryPatch.description) {
      //just hide dialog
      onHide();
      return;
    }
    //save entry
    this.setState({loading: true});
    if (scheduleEntry) {
      await this.updateScheduleEntry(String(scheduleEntry.id), scheduleEntryPatch);
    } else {
      const newScheduleEntry: ScheduleEntryPatch = {
        ...scheduleEntryPatch,
        type: 'other',
        vehicle_id: vehicleId,
        line_up: 0,
        updated_at: new Date(),
        start_at: startDate,
        end_at: endDate,
      };
      await this.createScheduleEntry(newScheduleEntry);
    }
  }

  async createScheduleEntry(scheduleEntryPatch: ScheduleEntryPatch) {
    this.schedulesService
      ?.createScheduleEntry(scheduleEntryPatch)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Schedule entry created successfully.');
        this.props.onHide();
        MessageService.sendMessage(messages.reloadScheduler);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, schedule entry create failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async updateScheduleEntry(id: string, scheduleEntryPatch: ScheduleEntryPatch) {
    this.schedulesService
      ?.updateScheduleEntry(id, scheduleEntryPatch)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Schedule entry updated successfully.');
        this.props.onHide();
        MessageService.sendMessage(messages.reloadScheduler);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, schedule entry update failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  setDescription(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({scheduleEntryPatch: {description: e.target.value}});
  }

  renderDialogBody() {
    const {scheduleEntryPatch} = this.state;
    const {scheduleEntry} = this.props;

    const description = scheduleEntryPatch?.description ?? scheduleEntry?.description ?? '';

    return (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0 custom-padding-top-body">
          <label htmlFor="description" className="p-col-2">
            reminder
          </label>
          <div className="p-col-10 p-p-0">
            <InputTextarea className="p-d-flex w-100" value={description} onChange={this.setDescription} />
          </div>
        </div>
      </>
    );
  }

  render() {
    const {loading} = this.state;
    const {onHide, scheduleEntry} = this.props;

    const headerTitle = scheduleEntry?.id ? 'Edit Reminder' : 'Add Reminder';

    return (
      <TwoDialog
        headerTitle={headerTitle}
        showDialog={this.props.showDialog}
        width={50}
        onHide={onHide}
        onShow={this.onShow}
        onSave={this.onSave}
        loading={loading}
      >
        {this.renderDialogBody()}
      </TwoDialog>
    );
  }
}
export default EditReminderDialog;
