import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  AppContext,
  TwoAction,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  ToastService,
  MessageService,
  TwoMessage,
} from 'two-app-ui';
import './Order.scss';
import OrderDetail from './OrderDetail';
import {
  faCalendarAlt,
  faPencil,
  faMinus,
  faPersonCarryBox,
  faBars,
  faTruckLoading,
  faCamera,
} from '@fortawesome/pro-regular-svg-icons';
import {FreightOrder, QueryParameter, TimeLineEvent} from 'two-core';
import FreightOrdersService from '../../services/FreightOrdersService';
import TlesService from '../../services/TlesService';
import {Toast} from 'primereact/toast';
import OrdersService from '../../services/OrdersService';
import TasksService from '../../services/TasksService';
import EditFreightOrderDialog from './EditFreightOrderDialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Subscription} from 'rxjs';
import OrderBoxes from './OrderBoxes';
import DeliverUndeliverDialog, {DeliverUndeliverDialogType} from '../Orders/DeliverUndeliverDialog';
import ProofList from '../Proofs/ProofList';

library.add(faCalendarAlt);

interface RouteProps {
  id: string;
}

interface State {
  loadingOrder: boolean;
  loadingSecondaryView: boolean;
  showFreightOrderEditDialog: boolean;
  freightOrder: FreightOrder;
  showDeliverDialog: boolean;
  deliveryDialogType: DeliverUndeliverDialogType;
  events: TimeLineEvent[];
  items: TwoTimelineItem[];
}

class OrderComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;

  freightOrdersService: FreightOrdersService | null = null;
  ordersService: OrdersService | null = null;
  tlesService: TlesService | null = null;
  tasksService: TasksService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;
  subscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingOrder: false,
      loadingSecondaryView: false,
      showFreightOrderEditDialog: false,
      freightOrder: {
        state_id: '',
      },
      showDeliverDialog: false,
      deliveryDialogType: 'deliver',
      events: [],
      items: [],
    };

    this.toast = React.createRef();

    this.hideFreightOrderEditDialog = this.hideFreightOrderEditDialog.bind(this);
    this.hideAndRefreshFreightOrderEditDialog = this.hideAndRefreshFreightOrderEditDialog.bind(this);
    this.onDeliverUndeliverContinue = this.onDeliverUndeliverContinue.bind(this);
    this.onDeliverUndeliverCancel = this.onDeliverUndeliverCancel.bind(this);
  }

  componentDidMount() {
    this.tlesService = this.context.tlesService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.tasksService = this.context.tasksService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      const castedMessage = message as TwoMessage;
      if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
        this.props.history.push('/all-orders');
      }
    });

    const id = this.props.match.params.id;
    this.loadOrder(id);
    this.loadEvents(id);
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  loadOrder(orderId: string) {
    this.setState({loadingOrder: true});

    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: orderId,
        condition: '=',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const dataRecords = (data?.records as FreightOrder[]) ?? [];
        //fix for array with undefined element
        if (dataRecords[0]) {
          const freightOrder: FreightOrder = {
            ...dataRecords[0],
            shipment_items: dataRecords[0].shipment_items?.filter(item => item) ?? [],
          };

          this.setState({
            freightOrder: freightOrder,
            loadingOrder: false,
          });
        }
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, order load failed, please try again.');
        this.setState({loadingOrder: false});
      });
  }

  loadEvents(id: string) {
    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'order',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tlesService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];

        const items = events.map(event => {
          const item: TwoTimelineItem = {event: event};
          return item;
        });

        this.setState({
          events: events,
          loadingSecondaryView: false,
          items: items,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, order events load failed, please try again.');
        this.setState({loadingSecondaryView: false});
      });
  }

  getActions(freightOrder: FreightOrder): TwoAction[] {
    const editDetailAction = [];
    const state = localStorage.getItem('current state')!;
    const stage = freightOrder?.route?.[state].stage ?? '';

    if (stage !== 'Delivered') {
      editDetailAction.push({
        icon: faPencil,
        label: 'Edit',
        main: true,
        action: () => {
          this.setState({showFreightOrderEditDialog: true});
        },
      });
      editDetailAction.push({
        icon: faPersonCarryBox,
        label: 'Deliver',
        main: false,
        action: () => {
          this.showDeliverUndeliverDialog('deliver');
        },
      });
      editDetailAction.push({
        icon: faMinus,
        label: 'Remove From Run',
        main: false,
        action: () => {
          this.removeFreightOrderFromRun(freightOrder);
        },
      });
    } else {
      editDetailAction.push({
        icon: faTruckLoading,
        label: 'Un-Deliver',
        main: true,
        action: () => {
          this.showDeliverUndeliverDialog('undeliver');
        },
      });
    }

    return editDetailAction;
  }

  removeFreightOrderFromRun(freightOrder: FreightOrder) {
    this.freightOrdersService
      ?.removeFreightOrderTasks(freightOrder)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Order removed successfully.');
        this.setState({loadingOrder: false});
        this.loadOrder(freightOrder.id as string);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Orders remove failed, please try again.');
        console.error('error: ' + error);
        this.setState({loadingOrder: false});
      });
  }

  /**
   * Causes the deliver / undeliver dialog to show with the correct action type.
   * And saves the action type into state, so the continue method knows what to do
   * if triggered.
   * @param dialogType
   */
  showDeliverUndeliverDialog(dialogType: DeliverUndeliverDialogType): void {
    this.setState({showDeliverDialog: true, deliveryDialogType: dialogType});
  }

  onDeliverUndeliverContinue(): void {
    this.setState({showDeliverDialog: false});
    this.loadOrder(this.state.freightOrder.id!);
  }

  onDeliverUndeliverCancel(): void {
    this.setState({showDeliverDialog: false});
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';

    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  hideFreightOrderEditDialog() {
    this.setState({showFreightOrderEditDialog: false});
  }

  hideAndRefreshFreightOrderEditDialog() {
    this.hideFreightOrderEditDialog();
    this.loadOrder(this.state.freightOrder.id as string);
    this.loadEvents(this.state.freightOrder.id as string);
  }

  render() {
    const {freightOrder, items} = this.state;

    return freightOrder ? (
      <>
        <TwoEntityComponent title={freightOrder.id ?? ''} actions={this.getActions(freightOrder)}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingOrder ? <OrderDetail freightOrder={freightOrder} /> : <ProgressSpinner />}
          </TwoEntityPanel>

          <TwoEntityPanel label="Bom" icon={faBars} tooltip="Boxes">
            {!this.state.loadingSecondaryView ? (
              <OrderBoxes
                boxes={this.state.freightOrder.shipment_items ? this.state.freightOrder.shipment_items : []}
              />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="Proofs" icon={faCamera} tooltip="Proofs">
            {!this.state.loadingSecondaryView ? <ProofList order={freightOrder}></ProofList> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            {!this.state.loadingSecondaryView ? (
              <TwoTimeline key={freightOrder?.id} items={items} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
        </TwoEntityComponent>

        <DeliverUndeliverDialog
          showDialog={this.state.showDeliverDialog}
          type={this.state.deliveryDialogType}
          onCancel={this.onDeliverUndeliverCancel}
          onContinue={this.onDeliverUndeliverContinue}
          toast={this.toast}
          orders={[this.state.freightOrder]}
        />

        <EditFreightOrderDialog
          toast={this.toast}
          freightOrderId={this.state.freightOrder.id}
          showDialog={this.state.showFreightOrderEditDialog}
          onHide={this.hideFreightOrderEditDialog}
          onHideAndRefresh={this.hideAndRefreshFreightOrderEditDialog}
        />
        <Toast ref={this.toast} />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(OrderComponent);
