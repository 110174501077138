import config from '../config/config';
import {ApiListResponse, ApiResponse, FreightProof, QueryParameter} from 'two-core';
import {AuthService, FileService} from 'two-app-ui';
import axios, {AxiosResponse} from 'axios';

class FreightProofsService extends FileService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.freightProofs ?? '';
  }

  async getFreightProofs(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async createFreightProof(data: Partial<FreightProof>): Promise<FreightProof> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as FreightProof);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updateFreightProof(id: number, data: Partial<FreightProof>): Promise<FreightProof> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as FreightProof);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async uploadPhoto(proofId: number, file: File): Promise<AxiosResponse> {
    const bucket = config().aws.proofBucket ?? '';
    const filePath = `freight_proofs/${proofId}/photos`;
    return this.uploadFile(bucket, filePath, file);
  }

  async deletePhoto(proofId: number, url: string) {
    const fileName = url.split('photos/')[1];
    const filePath = `freight_proofs/${proofId}/photos/${fileName}`;
    const bucket = config().aws.proofBucket ?? '';
    return await this.deleteFile(bucket, filePath);
  }

  async uploadSignature(proofId: number, file: File): Promise<AxiosResponse> {
    const bucket = config().aws.proofBucket ?? '';
    const filePath = `freight_proofs/${proofId}/signature`;
    return this.uploadFile(bucket, filePath, file);
  }

  async getPhotoBlobUrl(url: string) {
    try {
      if (url.includes('blob')) {
        return url;
      } else {
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          responseType: 'blob',
        });
        return URL.createObjectURL(response.data);
      }
    } catch (e) {
      console.error('Error getting photo blob url: ', e);
      return Promise.reject();
    }
  }
}

export default FreightProofsService;
