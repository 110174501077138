import {Panel, PanelHeaderTemplateOptions} from 'primereact/panel';
import React, {CSSProperties} from 'react';
import {InputSwitch} from 'primereact/inputswitch';
import {InputText} from 'primereact/inputtext';
import SignatureCanvas from 'react-signature-canvas';
import {Button} from 'primereact/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {Image} from 'primereact/image';

interface Props {
  collapsed: boolean;
  disabled: boolean;
  active: boolean;
  name: string;
  signatureFileUrl: string;
  onVisibilityToggle: (collapse: boolean) => void;
  onNameChange: (value: string) => void;
  signatureCanvasRef: React.RefObject<SignatureCanvas>;
  onTurnOff: () => void;
}

export const SignaturePanel = ({
  collapsed,
  disabled,
  active,
  name,
  signatureFileUrl,
  onVisibilityToggle,
  onNameChange,
  signatureCanvasRef,
  onTurnOff,
}: Props) => {
  const panelTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className}`;
    const titleClassName = `${options.titleClassName}`;
    const toggleIcon = <FontAwesomeIcon icon={options.collapsed ? faChevronDown : faChevronUp} size={'xl'} />;

    return (
      <>
        <div className={className}>
          <div className="p-d-flex">
            <div className="p-mr-2" onClick={options.onTogglerClick}>
              {toggleIcon}
            </div>
            <div className="p-ml-2">
              <span className={titleClassName + ' p-mr-2 p-as-center'}>Signature</span>
            </div>
          </div>
          <InputSwitch checked={active} onChange={onInputSwitchClick} />
        </div>
      </>
    );
  };

  const onInputSwitchClick = () => {
    if (active) {
      onTurnOff();
    } else {
      onVisibilityToggle(false);
    }
  };

  const onSignatureDelete = () => {
    signatureCanvasRef.current?.clear();
  };

  const canvasStyle: CSSProperties = {
    width: '100%',
    height: '300px',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '5px',
  };
  if (disabled) {
    canvasStyle.pointerEvents = 'none';
    canvasStyle.cursor = 'not-allowed';
  }

  return (
    <Panel
      toggleable
      collapsed={collapsed}
      onToggle={event => onVisibilityToggle(event.value)}
      headerTemplate={panelTemplate}
      className="p-mb-2"
    >
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label className="p-col-6">Full Name</label>
          <div className="p-col-6">
            <InputText
              type="text"
              value={name}
              onChange={event => onNameChange(event.target.value)}
              disabled={disabled}
            />
          </div>
        </div>
        <div>
          {signatureFileUrl.length ? (
            <div className="p-d-flex p-jc-center">
              <Image src={signatureFileUrl} imageStyle={{maxWidth: '100%', maxHeight: '300px'} as unknown as string} />
            </div>
          ) : (
            <div style={{position: 'relative'}}>
              <SignatureCanvas ref={signatureCanvasRef} canvasProps={{style: canvasStyle}} />
              <div className="p-mb-1" style={{position: 'absolute', bottom: 0, right: 0}}>
                <Button
                  className="p-button-lg"
                  icon={<FontAwesomeIcon icon={faTrashCan} size="xl" />}
                  onClick={onSignatureDelete}
                  disabled={disabled}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Panel>
  );
};
