export const config = () => {
  const freightService = process.env.REACT_APP_FREIGHT_SERVICE;
  const tleService = process.env.REACT_APP_TLE_SERVICE;
  const orderService = process.env.REACT_APP_ORDER_SERVICE;
  const factoryService = process.env.REACT_APP_FACTORY_SERVICE;
  return {
    system: {
      stopTypingDetection: 1000,
    },
    endpoints: {
      tles: tleService + 'tles',
      orders: orderService + 'orders',
      freightOrders: freightService + 'freight-orders',
      freightOrdersGroupped: freightService + 'freight-orders-groupped',
      locations: freightService + 'locations',
      runs: freightService + 'runs',
      stops: freightService + 'stops',
      alarms: factoryService + 'alarms',
      states: freightService + 'states',
      stateForwarders: freightService + 'state-forwarders',
      schedules: freightService + 'schedule-entries',
      vehicles: freightService + 'vehicles',
      containers: freightService + 'containers',
      tasks: freightService + 'tasks',
      dashboards: freightService + 'dashboards',
      freightProofs: freightService + 'freight-proof',
    },
    aws: {
      proofBucket: process.env.REACT_APP_FILES_PROOF_BUCKET_NAME,
    },
  };
};
export default config;
