import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, ScheduleEntry, ScheduleEntryPatch} from 'two-core';

class SchedulesService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.schedules ?? '';
  }

  async getSchedules(params: QueryParameter): Promise<ApiListResponse> {
    if (!params.filters) {
      params.filters = [];
    }
    params.filters.push(
      JSON.stringify({
        field: 'vehicle.state_id',
        value: localStorage.getItem('current state'),
      })
    );
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateScheduleEntry(id: string, data: ScheduleEntryPatch): Promise<ScheduleEntry> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as ScheduleEntry);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createScheduleEntry(data: ScheduleEntryPatch): Promise<ScheduleEntry> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as ScheduleEntry);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteScheduleEntry(id: string): Promise<void> {
    return this.delete(`${this.endpoint}/${id}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default SchedulesService;
