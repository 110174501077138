import React from 'react';
import {AppContext, ToastService, TwoDialog} from 'two-app-ui';
import {FreightOrder, Location, MapOf, QueryParameter, Run} from 'two-core';
import {Button} from 'primereact/button';
import LocationsService from '../../services/LocationsService';
import {Toast} from 'primereact/toast';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  onContinue: () => void;
  onRePlan: () => void;
  orders: FreightOrder[];
  run: Run;
}

interface State {
  locationsMap: MapOf<Location>;
  loading: boolean;
  orders: FreightOrder[];
  run: Run;
}

class OrderTasksOnRunDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  locationsService: LocationsService | null = null;
  toastService: ToastService | null = null;
  toast: React.RefObject<Toast>;

  constructor(props: Props) {
    super(props);

    this.state = {
      locationsMap: {},
      loading: false,
      orders: [],
      run: {
        name: '',
        stage: 'Draft',
        state_id: '',
      },
    };

    this.toast = React.createRef();
    this.setOrders = this.setOrders.bind(this);
  }

  componentDidMount() {
    this.locationsService = this.context.locationsService;
  }

  async setOrders() {
    await this.setState({
      orders: this.props.orders,
    });
    this.loadLocations();
  }

  getLocationIds() {
    const locationIds: number[] = [];
    const {orders} = this.state;
    if (orders.length) {
      orders.forEach(order => {
        if (order.current_location_id && !locationIds.includes(order.current_location_id)) {
          locationIds.push(order.current_location_id);
        }
        if (order.final_destination_id && !locationIds.includes(order.final_destination_id)) {
          locationIds.push(order.final_destination_id);
        }
      });
    }

    return locationIds;
  }

  loadLocations() {
    this.setState({loading: true});
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: this.getLocationIds(),
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
      showAll: true,
    };
    this.locationsService
      ?.getLocations(params)
      .then(data => {
        const locations = data.records as Location[];
        const locationsMap: MapOf<Location> = {};
        for (const location of locations) {
          locationsMap[location.id!] = location;
        }
        this.setState({
          locationsMap: locationsMap,
          loading: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, Location load failed, please try again.');
        this.setState({
          loading: false,
        });
      });
  }

  hideDialog() {
    this.setState({
      orders: [],
      run: {
        name: '',
        stage: 'Draft',
        state_id: '',
      },
    });
    this.props.onHide();
  }

  render() {
    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button
          label="choose different run"
          className={'p-mr-2 p-button-text'}
          onClick={() => {
            this.hideDialog();
          }}
        />
        <Button
          label="re-plan"
          className={'p-mr-2 p-button-text'}
          onClick={() => {
            this.props.onRePlan();
          }}
        />
        <Button
          label="continue without"
          onClick={() => {
            this.props.onContinue();
          }}
          autoFocus
        />
      </div>
    );

    return (
      <TwoDialog
        headerTitle={'Orders have task on this run'}
        showDialog={this.props.showDialog}
        width={50}
        onHide={() => {
          this.hideDialog();
        }}
        onShow={this.setOrders}
        loading={false}
        footer={footer}
      >
        <div className={'p-d-block'}>
          <p>
            These orders are already assigned to the run you just chose <strong>{this.props.run.name}</strong>.
          </p>
          {this.state.locationsMap &&
            this.props.orders.map(order => {
              return (
                <p key={order.id}>
                  <strong>{order.id}</strong> {order.order?.reference} from:
                  {order.current_location_id &&
                    this.state.locationsMap[order.current_location_id] &&
                    this.state.locationsMap[order.current_location_id].name}{' '}
                  to:
                  {order.final_destination_id &&
                    this.state.locationsMap[order.final_destination_id] &&
                    this.state.locationsMap[order.final_destination_id].name}
                </p>
              );
            })}
          <p>Do you want to re-plan them, or continue without them?</p>
        </div>
      </TwoDialog>
    );
  }
}
export default OrderTasksOnRunDialog;
