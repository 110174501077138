import React from 'react';
import {Tooltip} from 'primereact/tooltip';
import {Column} from 'primereact/column';
import {DataTablePageParams} from 'primereact/datatable';
import {AppContext, MessageService, TwoDataTable, ToastService, TwoDialog} from 'two-app-ui';
import {
  FreightOrder,
  OrderPatch,
  QueryParameter,
  Run,
  Stop,
  Task,
  TimeLineEvent,
  TleContentUnassigned,
  TleLink,
} from 'two-core';
import {Toast} from 'primereact/toast';
import {Subscription} from 'rxjs';
import StopsService from '../../services/StopsService';
import TasksService from '../../services/TasksService';
import FreightOrdersService from '../../services/FreightOrdersService';
import {messages} from '../../config/messages';
import OrdersService from '../../services/OrdersService';
import TlesService from '../../services/TlesService';
import './RemoveOrdersFromRunDialog.scss';
import {getFreightOrderAgeInDays} from '../../utils/FreightOrderUtil';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  run: Run;
  stops: Stop[];
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  freightOrders: FreightOrder[];
  selectedFreightOrders: FreightOrder[];
  totalItems: number;
  pagination: {
    pageSize: number;
    offset: number;
  };
}

class RemoveOrderFromRunDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  stopsService: StopsService | null = null;
  tasksService: TasksService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  ordersService: OrdersService | null = null;
  tlesService: TlesService | null = null;
  toastService: ToastService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      freightOrders: [],
      selectedFreightOrders: [],
      totalItems: 0,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
    };

    this.save = this.save.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.ageBodyTemplate = this.ageBodyTemplate.bind(this);
    this.sizeBodyTemplate = this.sizeBodyTemplate.bind(this);
  }

  componentDidMount() {
    this.stopsService = this.context.stopsService;
    this.tasksService = this.context.tasksService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.tlesService = this.context.tlesService;
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();
  }

  async loadData() {
    this.setState({loading: true});
    const stops = this.props.stops.filter(s => s !== null);
    const ids: string[] = [];
    stops.forEach(stop => {
      const stopTasks = stop.tasks?.filter(t => t !== null) ?? [];
      const freightOrderIds = stopTasks.map(task => {
        return task.freight_order_id ?? '';
      });
      ids.push(...freightOrderIds.filter(i => !ids.includes(i)));
    });

    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: ids,
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
    };

    this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const dataRecords = (data?.records as FreightOrder[]) ?? [];

        this.setState({
          freightOrders: dataRecords,
          loading: false,
          totalItems: data?.total_records ?? 0,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  setChangeSelectedItems(items: FreightOrder[]) {
    this.setState({selectedFreightOrders: items});
  }

  hideDialog() {
    this.setState({
      freightOrders: [],
      selectedFreightOrders: [],
    });
    this.props.onHide();
  }

  save() {
    const selectedFreightOrders = this.state.selectedFreightOrders;
    const selectedFreightOrdersIds = selectedFreightOrders.map(fo => {
      return fo.id;
    });
    const stops = this.props.stops.filter(s => s !== null);
    const tasks: Task[] = [];
    stops.forEach(stop => {
      const stopTasks =
        stop.tasks?.filter(t => t !== null && selectedFreightOrdersIds.includes(t.freight_order_id)) ?? [];
      tasks.push(...stopTasks);
    });

    this.deleteStopsAndTasks(tasks, stops, selectedFreightOrders, this.props.run.id?.toString() ?? '');
  }

  async deleteStopsAndTasks(tasks: Task[], allStops: Stop[], freightOrders: FreightOrder[], runId: string) {
    this.setState({loading: true});
    const tlePromises: Promise<void>[] = [];

    const stops: Stop[] = [];

    const taskIds = tasks.map(t => t.id);
    allStops.map(stop => {
      const stopTasks = stop.tasks?.filter(t => t !== null && !taskIds.includes(t.id)) ?? [];
      if (stopTasks.length === 0) {
        stops.push(stop);
      }
    });

    const deletePromise = this.deleteTasks(tasks).then(() => {
      return this.deleteStops(stops);
    });

    freightOrders.forEach(order => {
      const tle = this.createDeassignedTleEntity(order, runId.toString());
      const promise = this.createTle(order, tle);

      if (promise) {
        tlePromises.push(promise);
      }
    });

    deletePromise
      .then(() => {
        Promise.all(tlePromises);
        this.toastService?.showSuccess(this.props.toast, 'Orders removed from run successfully.');
        this.setState({loading: false});
        MessageService.sendMessage(messages.stopUpdate);
        this.hideDialog();
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, Orders delete from run failed, please try again.');
        this.setState({loading: false});
      });
  }

  async deleteTasks(tasks: Task[]) {
    Promise.all(
      tasks.map(async (task: Task) => {
        if (task.id) {
          return this.tasksService?.deleteTask(task.id.toString());
        }
      })
    );
  }

  async deleteStops(stops: Stop[]) {
    Promise.all(
      stops.map(async (stop: Stop) => {
        if (stop.id) {
          return this.stopsService?.deleteStop(stop.id.toString());
        }
      })
    );
  }

  createDeassignedTleEntity(freightOrder: FreightOrder, runId: string) {
    const tleLink: TleLink = {link_type: 'run', linked_id: runId};
    const content: TleContentUnassigned = {
      message: 'Order was deassigned from a run',
      links: [tleLink],
    };

    const tle: TimeLineEvent = {
      event_type: 'unassigned',
      entity_type: 'order',
      recorded_by: this.getCurrentUserId(),
      entity_id: freightOrder.id ?? '',
      content: content,
      recorded_at: new Date(),
    };
    return tle;
  }

  createTle(freightOrder: FreightOrder, tle: TimeLineEvent) {
    return this.tlesService?.createTle(tle).then(data => {
      if (data) {
        const updatedOrder: OrderPatch = {
          last_event_id: data.id,
        };
        this.ordersService?.updateOrder(freightOrder.id ?? '', updatedOrder);
      }
    });
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';

    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  ageBodyTemplate(freightOrder: FreightOrder) {
    return getFreightOrderAgeInDays(freightOrder);
  }

  sizeBodyTemplate(freightOrder: FreightOrder) {
    const orderShipmentItems = freightOrder.shipment_items?.filter(si => si !== null) ?? [];

    const tooltipElement = (
      <div className="p-d-flex p-flex-column">
        {orderShipmentItems &&
          orderShipmentItems.map((shipmentItem, i) => {
            const dimension = shipmentItem.dimensions ?? {};

            return (
              <div key={'tooltip-row-' + i} className="p-d-flex p-flex-row p-col-12 p-p-0 p-mb-1">
                <span className="p-d-flex p-as-center p-col-2 p-p-0">{`${shipmentItem.index} of ${shipmentItem.count}:`}</span>
                <span className="p-d-flex p-as-center p-col-5 p-p-0">{`${shipmentItem.content} `}</span>
                <span className="p-d-flex p-as-center p-jc-end p-col-3 p-p-0">{` ${dimension.height}x${dimension.width}x${dimension.depth} `}</span>
                <span className="p-d-flex p-as-center p-jc-end p-col-2 p-p-0">{`${dimension.weight ?? 0} kg`}</span>
              </div>
            );
          })}
      </div>
    );

    return (
      <>
        <Tooltip className="custom-tooltip" target={`#size-${freightOrder.id}`} position="right">
          {tooltipElement}
        </Tooltip>
        <span id={`size-${freightOrder.id}`}>{orderShipmentItems.length}</span>
      </>
    );
  }

  render() {
    const dialogBody = (
      <>
        <div className="p-col-12 p-p-0 p-mb-2">
          <span className={'bold-run-name-span'}>Select the order you want to remove from&nbsp;</span>
          <strong> {this.props.run?.name ?? ''} </strong>
        </div>
        <div className="p-col-12 p-p-0 w-100">
          <TwoDataTable
            key={'freight-orders-to-remove-key'}
            id={'freight-orders-to-remove'}
            heightToScroll={undefined}
            sizeIdentifiers={[]}
            loading={this.state.loading}
            value={this.state.freightOrders}
            totalRecords={this.state.totalItems}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            activeFilters={{}}
            onPage={e => this.onPageChange(e)}
            selectionMode={'multiple'}
            selectedItems={this.state.selectedFreightOrders}
            handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as FreightOrder[])}
          >
            <Column header="Order" field="id" style={{width: '150px'}} />
            <Column header="Reference" field="order.reference" style={{width: '150px'}} />
            <Column header="Dealer" field="owner_company.account_number" style={{width: '100px'}} />

            <Column header="Size" field="order.size" body={this.sizeBodyTemplate} style={{width: '100px'}} />
            <Column header="Age" body={this.ageBodyTemplate} style={{width: '100px'}} />
          </TwoDataTable>
        </div>
      </>
    );
    return (
      <>
        <TwoDialog
          headerTitle={'Remove orders from Run'}
          showDialog={this.props.showDialog}
          visible={this.props.showDialog}
          width={80}
          onShow={this.loadData}
          onHide={this.hideDialog}
          onSave={this.save}
          loading={this.state.loading}
          saveButtonTitle="Remove"
        >
          {dialogBody}
        </TwoDialog>
      </>
    );
  }
}
export default RemoveOrderFromRunDialog;
