import {Column} from 'primereact/column';
import React from 'react';
import {AppContext, AppMenuItem, AppMenuItemTemplate, MessageService, TwoDataTable, TwoToast} from 'two-app-ui';
import {FreightOrder, FreightProof, FreightProofAggregate, Location} from 'two-core';
import {DataTablePageParams} from 'primereact/datatable';
import {messages} from '../../config/messages';
import FreightProofsService from '../../services/FreightProofsService';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import {Checkbox} from 'primereact/checkbox';
import {MenuItemOptions} from 'primereact/menuitem';
import {faPlusCircle} from '@fortawesome/pro-regular-svg-icons';
import {AddEditProofDialog} from '../Proof/AddEditProofDialog/AddEditProofDialog';
import {Subscription} from 'rxjs';
import FreightOrdersProofReference from '../Reference/FreightOrdersProofReference';

interface Props {
  order?: FreightOrder;
  location?: Location;
}

interface State {
  loading: boolean;
  proofs: FreightProof[];
  selectedProofs: FreightProof[];
  totalProofs: number;
  pagination: {
    pageSize: number;
    offset: number;
  };
  showAddEditProofDialog: boolean;
}

class ProofList extends React.Component<Props, State> {
  static contextType = AppContext;
  freightProofsService?: FreightProofsService;
  twoToast?: TwoToast;
  subscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      proofs: [],
      selectedProofs: [],
      totalProofs: 0,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      showAddEditProofDialog: false,
    };
  }

  async componentDidMount() {
    this.freightProofsService = this.context.freightProofsService;
    this.twoToast = this.context.twoToast;

    this.takenAtBodyTemplate = this.takenAtBodyTemplate.bind(this);
    this.signedBodyTemplate = this.signedBodyTemplate.bind(this);
    this.photosBodyTemplate = this.photosBodyTemplate.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.locationNameTemplate = this.locationNameTemplate.bind(this);

    this.loadData();
    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.proofChanged) {
        this.loadData();
      }
    });
  }

  async loadData() {
    const {order, location} = this.props;
    this.setState({loading: true});
    const proofsResult = await this.loadProofs(order, location);
    this.setState({loading: false, proofs: proofsResult?.proofs ?? [], totalProofs: proofsResult?.totalProofs ?? 0});
  }

  loadProofs(order?: FreightOrder, location?: Location) {
    const filters: string[] = [];
    if (order?.id) {
      filters.push(
        JSON.stringify({
          field: 'order_to_freight_proof.order_id',
          value: order.id,
        })
      );
    }

    if (location?.id) {
      filters.push(
        JSON.stringify({
          field: 'location_id',
          value: location.id,
        })
      );
    }

    const aggregate: FreightProofAggregate[] = ['location', 'order_ids', 'run'];

    return this.freightProofsService
      ?.getFreightProofs({filters, aggregate})
      .then(data => {
        const dataRecords = (data?.records as FreightProof[]) ?? [];
        return {proofs: dataRecords, totalProofs: data.total_records};
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, orders load failed, please try again.');
        console.error(error);
        return undefined;
      });
  }

  async onPageChange(e: DataTablePageParams) {
    this.setState({pagination: {offset: e.first, pageSize: e.rows}}, () => {
      this.loadData();
    });
  }

  locationNameTemplate(rowData: FreightProof) {
    return <span>{rowData.location?.name}</span>;
  }

  ordersTemplate(proof: FreightProof) {
    return <FreightOrdersProofReference proof={proof} />;
  }

  takenAtBodyTemplate(rowData: FreightProof) {
    const formattedTakenAt = rowData.taken_at
      ? DateTime.fromISO(rowData.taken_at.toString()).toFormat(formats.dateTime)
      : '';
    return <span>{formattedTakenAt}</span>;
  }

  signedBodyTemplate(rowData: FreightProof) {
    return <Checkbox checked={!!rowData.signee_name?.length} />;
  }

  photosBodyTemplate(rowData: FreightProof) {
    return <Checkbox checked={!!rowData.photos?.length} />;
  }

  initMenuItems() {
    const {location} = this.props;
    if (location) {
      return [];
    }
    return [
      {
        label: 'Add New',
        faIcon: faPlusCircle,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => this.setState({showAddEditProofDialog: true, selectedProofs: []}),
      },
    ];
  }

  render() {
    const {order, location} = this.props;
    const {loading, proofs, totalProofs, selectedProofs, pagination, showAddEditProofDialog} = this.state;
    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="proof_list_page" className="page-container">
          <TwoDataTable
            pageSizeIdentifier={'proof_list_page'}
            selectedItems={selectedProofs}
            rows={pagination.pageSize}
            first={pagination.offset}
            loading={loading}
            value={proofs}
            totalRecords={totalProofs}
            activeFilters={[]}
            onPage={e => this.onPageChange(e as DataTablePageParams)}
            initMenuItems={() => this.initMenuItems()}
            onRowClick={eventParams =>
              this.setState({selectedProofs: [eventParams.data], showAddEditProofDialog: true})
            }
          >
            <Column header="Taken At" field="taken_at" body={this.takenAtBodyTemplate} />
            <Column header="Run" field="run.name" />
            <Column header="Location" field="location.name" body={this.locationNameTemplate} hidden={!!location} />
            <Column header="Orders" field="order_ids" body={this.ordersTemplate} hidden={!!order} />
            <Column header="Signed" field="signed" body={this.signedBodyTemplate} />
            <Column header="Photos" field="photos.length" body={this.photosBodyTemplate} />
          </TwoDataTable>
          {showAddEditProofDialog && (
            <AddEditProofDialog
              showDialog={showAddEditProofDialog}
              onHide={() => this.setState({showAddEditProofDialog: false})}
              orderId={order?.id}
              locationId={location?.id}
              proofId={selectedProofs[0]?.id}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ProofList;
