import React from 'react';
import OrderListComponent from '../Orders/OrderListComponent';

class AllOrdersComponet extends React.Component<{}> {
  constructor(props = {}) {
    super(props);
    this.state = {};
  }

  render() {
    return <OrderListComponent isAll={true} />;
  }
}

export default AllOrdersComponet;
