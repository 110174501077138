import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, Container, ContainerPatch, QueryParameter} from 'two-core';

class ContainersService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.containers ?? '';
  }

  async getContainers(params: QueryParameter): Promise<ApiListResponse> {
    if (!params.filters) {
      params.filters = [];
    }
    params.filters.push(
      JSON.stringify({
        field: `states->>'${localStorage.getItem('current state')}'`,
        condition: 'isNotNull',
      })
    );

    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateContainer(id: string, data: ContainerPatch): Promise<Container> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as Container);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default ContainersService;
