import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, Run} from 'two-core';
import {RunPatch} from 'two-core';

class RunsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.runs ?? '';
  }

  async getRuns(params: QueryParameter): Promise<ApiListResponse> {
    if (!params.filters) {
      params.filters = [];
    }
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async createRun(data: Run): Promise<Run> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as Run);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updateRun(id: string, data: RunPatch): Promise<Run> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as Run);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteRun(id: string): Promise<void> {
    return this.delete(`${this.endpoint}/${id}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default RunsService;
