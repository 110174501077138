import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  MessageService,
  TwoAction,
  TwoEntityComponent,
  TwoEntityPanel,
  ToastService,
  TwoMessage,
} from 'two-app-ui';
import {faCalendarAlt, faPencil, faList, faTruck, faExchange, faWalkieTalkie} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {QueryParameter, Run} from 'two-core';
import {Toast} from 'primereact/toast';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import RunsService from '../../services/RunsService';
import RunDetail from './RunDetail';
import EditRunDialog from './EditRunDialog';
import RunStops from './RunStops';
import RunOrders from './RunOrders';
import {ProgressSpinner} from 'primereact/progressspinner';
import RunOthers from './RunOthers';

library.add(faCalendarAlt, faPencil, faList, faTruck, faExchange, faWalkieTalkie);

interface RouteProps {
  id: string;
}

interface State {
  run: Run | undefined;
  loadingRun: boolean;
  loadingSecondaryView: boolean;
  showEditDialog: boolean;
}

class RunComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;

  runsService: RunsService | null = null;
  toastService: ToastService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingRun: false,
      loadingSecondaryView: false,
      run: undefined,
      showEditDialog: false,
    };

    this.showEditRunDialog = this.showEditRunDialog.bind(this);
    this.hideEditRunDialog = this.hideEditRunDialog.bind(this);

    this.toast = React.createRef();
  }

  componentDidMount() {
    this.runsService = this.context.runsService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.runUpdated) {
        this.loadRun();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          this.props.history.push('/runs');
        }
      }
    });

    this.loadRun();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.messageSendSubscription.unsubscribe();
  }

  loadRun() {
    const id = this.props.match.params.id;

    this.setState({loadingRun: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    filters.push(
      JSON.stringify({
        field: 'state_id',
        value: localStorage.getItem('current state'),
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.runsService
      ?.getRuns(params)
      .then(data => {
        const run = (data.records as Run[])[0];
        this.setState({
          run: run,
          loadingRun: false,
        });
        MessageService.sendMessage(messages.reloadTaskList);
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, Run load failed, please try again.');
        this.setState({
          loadingRun: false,
        });
      });
  }

  setLoadingSecondaryView(value: boolean) {
    this.setState({loadingSecondaryView: value});
  }

  showEditRunDialog() {
    this.setState({showEditDialog: true});
  }

  hideEditRunDialog() {
    this.setState({showEditDialog: false});
  }

  getActions(): TwoAction[] {
    const editDetailAction = {
      icon: faPencil,
      label: 'Edit',
      main: true,
      action: () => {
        this.showEditRunDialog();
      },
    };

    return [editDetailAction];
  }

  render() {
    const {run} = this.state;

    return run ? (
      <>
        <TwoEntityComponent title={run.name} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingRun ? <RunDetail run={run} history={this.props.history} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Stops" icon={faTruck} tooltip="Stops">
            {!this.state.loadingSecondaryView ? (
              <RunStops run={run} history={this.props.history} toast={this.toast} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="Orders" icon={faList} tooltip="Orders">
            {!this.state.loadingSecondaryView ? (
              <RunOrders run={run} history={this.props.history} toast={this.toast} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="Others" icon={faWalkieTalkie} tooltip="Others">
            {!this.state.loadingSecondaryView ? <RunOthers run={run} toast={this.toast} /> : <ProgressSpinner />}
          </TwoEntityPanel>
        </TwoEntityComponent>
        <EditRunDialog
          run={run}
          toast={this.toast}
          showDialog={this.state.showEditDialog}
          onHide={this.hideEditRunDialog}
        />
        <Toast ref={this.toast} />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(RunComponent);
