import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  MessageService,
  TwoAction,
  ToastService,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  TwoMessage,
} from 'two-app-ui';
import {faCalendarAlt, faTruckLoading, faList, faPersonCarryBox} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {Container, FreightOrder, FreightOrderPatch, FreightStage, QueryParameter, TimeLineEvent} from 'two-core';
import {Toast} from 'primereact/toast';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import ContainersService from '../../services/ContainersService';
import ContainerDetail from './ContainerDetail';
import ContainerOrders from './ContainerOrders';
import FreightOrdersService from '../../services/FreightOrdersService';
import OrdersService from '../../services/OrdersService';
import TlesService from '../../services/TlesService';
import {ProgressSpinner} from 'primereact/progressspinner';
import TasksService from '../../services/TasksService';
import DeliverUndeliverDialog from '../Orders/DeliverUndeliverDialog';

library.add(faCalendarAlt, faTruckLoading, faList);

interface RouteProps {
  id: string;
}

interface State {
  container: Container | undefined;
  loadingContainer: boolean;
  loadingSecondaryView: boolean;
  showDeliverDialog: boolean;
  items: TwoTimelineItem[];
  events: TimeLineEvent[];
}

class ContainerComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;

  containersService: ContainersService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  ordersService: OrdersService | null = null;
  tlesService: TlesService | null = null;
  toastService: ToastService | null = null;
  tasksService: TasksService | null = null;

  subscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingContainer: false,
      loadingSecondaryView: false,
      container: undefined,
      showDeliverDialog: false,
      items: [],
      events: [],
    };

    this.toast = React.createRef();
    this.onDeliverUndeliverContinue = this.onDeliverUndeliverContinue.bind(this);
    this.onDeliverUndeliverCancel = this.onDeliverUndeliverCancel.bind(this);
  }

  componentDidMount() {
    this.containersService = this.context.containersService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.tlesService = this.context.tlesService;
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;

    const id = this.props.match.params.id;
    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.containerUpdate) {
        this.loadContainer(id);
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          this.props.history.push('/containers');
        }
      }
    });

    this.loadContainer(id);
    this.loadEvents(id);
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  loadContainer(id: string) {
    this.setState({loadingContainer: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.containersService
      ?.getContainers(params)
      .then(data => {
        const container = (data.records as Container[])[0];
        this.setState({
          container: container,
          loadingContainer: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, Container load failed, please try again.');
        this.setState({
          loadingContainer: false,
        });
      });
  }

  loadEvents(id: string) {
    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'container',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tlesService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];

        const items = events.map(event => {
          const item: TwoTimelineItem = {event: event};
          return item;
        });

        this.setState({
          events: events,
          loadingSecondaryView: false,
          items: items,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, order events load failed, please try again.');
        this.setState({loadingSecondaryView: false});
      });
  }

  setLoadingSecondaryView(value: boolean) {
    this.setState({loadingSecondaryView: value});
  }

  setUnloadedFreightOrders() {
    const container = this.state.container;
    if (container) {
      const state = localStorage.getItem('current state')!;
      const freightOrders =
        container.freight_orders?.filter(fo => fo !== null && fo.route![state].stage === 'In Container') ?? [];

      Promise.all(
        freightOrders.map((freightOrder: FreightOrder) => {
          const unloadLocationId = container.unload_at_id ?? freightOrder.container?.unload_at_id;

          if (unloadLocationId) {
            const newStage: FreightStage =
              Number(unloadLocationId) === Number(freightOrder.final_destination_id) ? 'Delivered' : 'At Warehouse';
            const routePart = {...freightOrder.route![state], stage: newStage};
            const route = {...freightOrder.route, [state]: routePart};

            const updateOrder: FreightOrderPatch = {
              route: route,
              current_location_id: unloadLocationId,
              at_current_location_since: new Date(),
            };

            return this.freightOrdersService?.updateFreightOrder(freightOrder.id ?? '', updateOrder);
          }
          return;
        })
      )
        .then(() => {
          MessageService.sendMessage(messages.containerUpdate);
          this.toastService?.showSuccess(this.toast, 'Orders unloaded successfully.');
        })
        .catch(error => {
          this.toastService?.showError(this.toast, 'Sorry, Orders unload failed, please try again.');
          console.error('error: ' + error);
        });
    } else {
      this.toastService?.showError(this.toast, 'Unload failed. Please, refresh the page and try again.');
    }
  }

  getActions(stage: string): TwoAction[] {
    const editDetailAction = {
      icon: faTruckLoading,
      label: 'Unload All',
      main: true,
      action: () => {
        this.setUnloadedFreightOrders();
      },
    };
    const deliveryAll = {
      icon: faPersonCarryBox,
      label: 'Delivery All',
      main: false,
      action: () => {
        this.setState({showDeliverDialog: true});
      },
    };
    if (stage !== 'Done') {
      return [editDetailAction, deliveryAll];
    }
    return [];
  }

  onDeliverUndeliverContinue(): void {
    this.setState({showDeliverDialog: false});
    this.loadContainer(this.state.container!.id!);
  }

  onDeliverUndeliverCancel(): void {
    this.setState({showDeliverDialog: false});
  }

  render() {
    const {container} = this.state;
    const state = localStorage.getItem('current state') ?? '';
    const stage = container?.states?.[state] ?? '';

    return container ? (
      <>
        <TwoEntityComponent title={container.id ?? ''} actions={this.getActions(stage)} showDetail={false}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingContainer ? (
              <ContainerDetail container={container} history={this.props.history} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>

          <TwoEntityPanel label="Orders" icon={faList} tooltip="Orders">
            {!this.state.loadingSecondaryView ? (
              <ContainerOrders container={container} history={this.props.history} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            {!this.state.loadingSecondaryView ? (
              <TwoTimeline key={this.state.container?.id} items={this.state.items} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
        </TwoEntityComponent>

        <DeliverUndeliverDialog
          showDialog={this.state.showDeliverDialog}
          type={'deliver'}
          onCancel={this.onDeliverUndeliverCancel}
          onContinue={this.onDeliverUndeliverContinue}
          toast={this.toast}
          orders={this.state.container!.freight_orders!}
        />

        <Toast ref={this.toast} />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(ContainerComponent);
