import {ContainerStage} from 'two-core';

export interface ContainerStageDropdownOption {
  label: ContainerStage;
  value: ContainerStage;
}
export const containerStages: ContainerStageDropdownOption[] = [
  {label: 'Inbound', value: 'Inbound'},
  {label: 'In Progress', value: 'In Progress'},
  {label: 'Done', value: 'Done'},
];
