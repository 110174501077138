import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, FreightOrder, QueryParameter, Task, TaskPatch} from 'two-core';

class TasksService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.tasks ?? '';
  }

  async getTasks(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateTask(id: string, data: TaskPatch): Promise<Task> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as Task);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  /**
   * Deletes the tasks of the specified order that are to or took place in one
   * of the specified states.
   * @param forOrder - needs to be the aggregated version of the order, with runs and stops and tasks
   * @param inStates
   */
  async deleteTasks(forOrder: FreightOrder, inStates: string[]) {
    if (!forOrder.id) {
      return Promise.reject();
    }
    if (inStates.length === 0) {
      return Promise.resolve();
    }
    const inRunIds: number[] | undefined = [];
    forOrder.runs?.forEach(run => {
      if (inStates.includes(run.state_id) && run.id) {
        inRunIds.push(run.id);
      }
    });
    if (inRunIds.length === 0) {
      return Promise.resolve();
    }
    const inStopIds: number[] | undefined = [];
    forOrder.stops?.forEach(stop => {
      if (stop.run_id && inRunIds.includes(stop.run_id) && stop.id) {
        inStopIds.push(stop.id);
      }
    });
    if (inStopIds.length === 0) {
      return Promise.resolve();
    }
    const tasks2delete = forOrder.tasks?.filter(task => {
      return task.stop_id && inStopIds.includes(task.stop_id);
    });
    if (!tasks2delete || tasks2delete.length === 0) {
      return Promise.resolve();
    }
    return Promise.all(tasks2delete.map(task => this.delete(`${this.endpoint}/${task.id}`)));
  }

  async updateTaskCompletion(task: Task) {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    const updatedTask: Task = {
      ...task,
      executed_on: new Date(),
      executed_by_id: userId,
      executed_at: {long: 0, lat: 0},
    };
    return this.updateTask(task?.id?.toString() as string, updatedTask);
  }

  async createTask(data: TaskPatch): Promise<Task> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as Task);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteTask(id: string): Promise<void> {
    return this.delete(`${this.endpoint}/${id}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default TasksService;
