import React from 'react';
import {
  DataTableExpandedRows,
  DataTablePageParams,
  DataTableSortOrderType,
  DataTableSortParams,
} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {
  AppMenuItem,
  AppMenuItemTemplate,
  AppSelectAllColumnHeaderTemplate,
  getCompanyDescription,
  MessageService,
  TwoDataTable,
} from 'two-app-ui';
import {AppContext, ToastService} from 'two-app-ui';
import {
  FreightOrder,
  FreightOrderDeliveryDocketGroup,
  FreightOrderPatch,
  FreightStage,
  MapOf,
  QueryParameter,
  Run,
  Stop,
} from 'two-core';
import OrderListComponent from '../Orders/OrderListComponent';
import {Container, Location} from 'two-core';
import FreightOrdersService from '../../services/FreightOrdersService';
import '../../scss/CustomTable.scss';
import {faPersonCarryBox} from '@fortawesome/pro-regular-svg-icons';
import {faMessageExclamation} from '@fortawesome/pro-light-svg-icons';
import {MenuItemOptions} from 'primereact/menuitem';
import AssignOrdersToRunDialog from '../Orders/AssignOrdersToRunDialog';
import TasksService from '../../services/TasksService';
import TlesService from '../../services/TlesService';
import OrdersService from '../../services/OrdersService';
import {InputText} from 'primereact/inputtext';
import config from '../../config/config';
import {DropdownChangeParams} from 'primereact/dropdown';
import {StageDropdownOption, stages} from '../Order/Constants/constants';
import {MultiSelect} from 'primereact/multiselect';
import ContainersService from '../../services/ContainersService';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import LocationsService from '../../services/LocationsService';
import DeliverUndeliverDialog, {DeliverUndeliverDialogType} from '../Orders/DeliverUndeliverDialog';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getFreightOrderAgeInDays} from '../../utils/FreightOrderUtil';

interface Props {
  container?: Container;
  location?: Location;
  run?: Run;
  heightToScroll?: string;
  hideFilter?: boolean;
  initMenuItems?: (selectedFreightOrders: FreightOrder[]) => AppMenuItem[];
}

interface State {
  loading: boolean;
  items: FreightOrderDeliveryDocketGroup[];
  selectedItems: FreightOrderDeliveryDocketGroup[];
  selectedOrders: MapOf<FreightOrder[]>;
  totalItems: number;
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  expandedRows: DataTableExpandedRows[];
  showAssignToRunDialog: boolean;
  showDeliverDialog: boolean;
  deliverDialogType: DeliverUndeliverDialogType;
  filters: {
    company: string;
    destination: string;
    freight_stage: string;
  };
  containersMap?: MapOf<Container>;
  locationsMap?: MapOf<Location>;
  stopsMap: MapOf<Stop>; //for easy stops access
  runsMap: MapOf<Run>; //for easy runs access
}

class GroupOrdersComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  freightOrdersService: FreightOrdersService | null = null;
  tasksService: TasksService | null = null;
  tlesService: TlesService | null = null;
  ordersService: OrdersService | null = null;
  toastService: ToastService | null = null;
  containersService: ContainersService | null = null;
  locationsService: LocationsService | null = null;

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;
  subscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      selectedItems: [],
      selectedOrders: {},
      totalItems: 0,
      loading: true,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      expandedRows: [],
      showAssignToRunDialog: false,
      showDeliverDialog: false,
      deliverDialogType: 'deliver',
      filters: {
        company: '',
        destination: '',
        freight_stage: '',
      },
      stopsMap: {},
      runsMap: {},
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.progressBodyTemplate = this.progressBodyTemplate.bind(this);
    this.ageBodyTemplate = this.ageBodyTemplate.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setSelectedOrders = this.setSelectedOrders.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.showAssignOrdersToRunDialog = this.showAssignOrdersToRunDialog.bind(this);
    this.setUnloadedFreightOrders = this.setUnloadedFreightOrders.bind(this);
    this.getSelectedOrders = this.getSelectedOrders.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.dealerBodyTemplate = this.dealerBodyTemplate.bind(this);
    this.onDeliverUndeliverContinue = this.onDeliverUndeliverContinue.bind(this);
    this.onDeliverUndeliverCancel = this.onDeliverUndeliverCancel.bind(this);
  }

  async componentDidMount() {
    this.freightOrdersService = this.context.freightOrdersService;
    this.tasksService = this.context.tasksService;
    this.tlesService = this.context.tlesService;
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;
    this.containersService = this.context.containersService;
    this.locationsService = this.context.locationsService;

    this.loadData();

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.runOrderCreate) {
        this.loadData();
      }
      if (message === messages.removeOrderClicked) {
        this.setState({
          loading: true,
        });
      }
      if (message === messages.runOrdersRemoved) {
        this.loadData();
        this.setState({
          selectedItems: [],
          selectedOrders: {},
        });
        this.initMenuItems();
      }
    });
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  loadData() {
    this.setState({loading: true});
    const container = this.props.container;
    const location = this.props.location;
    const run = this.props.run;
    const filters: string[] = [];
    const sortBy: string[] = [];

    if (!container && !location && !run) {
      this.toastService?.showError(this.toast, 'Sorry, order groups load failed, please try again.');

      return this.setState({
        loading: false,
      });
    } else {
      if (container) {
        filters.push(
          JSON.stringify({
            field: 'container_id',
            value: container.id ?? '',
            condition: '=',
          })
        );
      } else if (location) {
        filters.push(
          JSON.stringify({
            field: 'current_location_id',
            value: location.id ?? '',
            condition: '=',
          })
        );
      } else if (run) {
        filters.push(
          JSON.stringify({
            field: 'run.id',
            value: run.id ?? '',
            condition: '=',
          })
        );
      }

      if (this.state.filters.company) {
        filters.push(
          JSON.stringify({
            field: 'company.account_number',
            value: this.state.filters.company,
            condition: 'iLike',
          })
        );
      }

      if (this.state.filters.destination) {
        filters.push(
          JSON.stringify({
            field: "order.shipping_address->>'suburb'",
            value: this.state.filters.destination,
            condition: 'iLike',
          })
        );
      }

      if (this.state.filters.freight_stage && this.state.filters.freight_stage.length) {
        filters.push(
          JSON.stringify({
            field: `route->'${localStorage.getItem('current state')}'->>'stage'`,
            value: this.state.filters.freight_stage,
            condition: 'in',
          })
        );
      }

      if (location) {
        //only show orders with stage in current state
        filters.push(
          JSON.stringify({
            field: `route->>'${localStorage.getItem('current state')}'`,
            condition: 'isNotNull',
          })
        );
      }

      const field = this.state.sortBy?.field;
      const direction = this.state.sortBy?.order ?? 1;
      sortBy.push(
        JSON.stringify({
          field: field ? ':' + field : 'company.account_number',
          direction: direction === 1 ? 'ASC' : 'DESC',
        })
      );

      const params: QueryParameter = {
        filters: filters,
        orderBys: sortBy,
        aggregate: ['owner_company'],
      };

      return this.freightOrdersService
        ?.getFreightOrdersGrouppedByDeliveryDocket(params)
        .then(data => {
          const dataRecords = (data?.records as FreightOrderDeliveryDocketGroup[]) ?? [];

          //load maps
          const containersIds = [];
          const locationsIds = [];
          const stopsMap: MapOf<Stop> = {};
          const runsMap: MapOf<Run> = {};
          for (const group of dataRecords) {
            for (const order of group.freight_orders) {
              //set ids for containers map
              if (order.container_id) {
                containersIds.push(order.container_id);
              }
              //set ids for locations map
              if (order.current_location_id) {
                locationsIds.push(order.current_location_id);
              }
            }
            //add stops to map
            for (const stop of group.stops ?? []) {
              stopsMap[stop.id!] = stop;
            }
            //add runs to map
            for (const run of group.runs ?? []) {
              runsMap[run.id!] = run;
            }
          }
          //load containers map
          if (this.props.container) {
            this.loadContainersMap();
          } else {
            this.loadContainersMap(containersIds);
          }
          //load locations map
          if (this.props.location) {
            this.loadLocationsMap();
          } else {
            this.loadLocationsMap(locationsIds);
          }

          this.setState({
            loading: false,
            items: dataRecords,
            totalItems: data?.total_records ?? 0,
            stopsMap: stopsMap,
            runsMap: runsMap,
          });
        })
        .catch(e => {
          this.setState({loading: false});
          this.toastService?.showError(this.toast, 'Sorry, order groups load failed, please try again.');
          console.log(e);
        });
    }
  }

  async loadContainersMap(containersIds?: string[]) {
    const containersMap: MapOf<Container> = {};
    if (this.props.container) {
      containersMap[this.props.container.id!] = this.props.container;
    } else {
      const filters: string[] = [];
      filters.push(
        JSON.stringify({
          field: 'id',
          value: containersIds,
          condition: 'in',
        })
      );
      const data = await this.containersService?.getContainers({
        filters: filters,
      });
      for (const container of data?.records as Container[]) {
        containersMap[container.id!] = container;
      }
    }
    this.setState({
      containersMap: containersMap,
    });
  }

  async loadLocationsMap(locationsIds?: number[]) {
    const locationsMap: MapOf<Location> = {};
    if (this.props.location) {
      locationsMap[this.props.location.id!] = this.props.location;
    } else {
      const filters: string[] = [];
      filters.push(
        JSON.stringify({
          field: 'id',
          value: locationsIds,
          condition: 'in',
        })
      );
      const data = await this.locationsService?.getLocations({
        filters: filters,
      });
      for (const location of data?.records as Location[]) {
        locationsMap[location.id!] = location;
      }
    }
    this.setState({
      locationsMap: locationsMap,
    });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  setChangeSelectedItems(items: FreightOrderDeliveryDocketGroup[]) {
    const selectedOrders: MapOf<FreightOrder[]> = {};
    for (const group of items) {
      selectedOrders[group.id] = group.freight_orders;
    }
    this.setState({
      selectedItems: items,
      selectedOrders: selectedOrders,
    });
  }

  async setChangeSelectedItem(item: FreightOrderDeliveryDocketGroup): Promise<void> {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    const selectedOrders: MapOf<FreightOrder[]> = {};
    if (!existingItem) {
      items.push(item);
      for (const group of items) {
        selectedOrders[group.id] = group.freight_orders;
      }
      await this.setState({
        selectedItems: items,
        selectedOrders: selectedOrders,
      });
    }
  }

  setSelectedOrders(groupId: string, orders: FreightOrder[]) {
    let selectedGroups = [...this.state.selectedItems];
    const group = this.state.items.find(group => group.id === groupId);
    if (group?.freight_orders.length === orders.length) {
      if (!selectedGroups.some(selectedGroup => selectedGroup.id === groupId)) {
        selectedGroups.push(group);
      }
    } else {
      selectedGroups = selectedGroups.filter(selectedGroup => selectedGroup.id !== groupId);
    }

    this.setState({
      selectedOrders: {...this.state.selectedOrders, [groupId]: orders},
      selectedItems: selectedGroups,
    });
  }

  rowExpansionTemplate(item: FreightOrderDeliveryDocketGroup) {
    const freightOrderIds = item.freight_orders.map(fo => fo.id ?? '');
    const {container, location, run} = this.props;
    const subtableOfTable = container ? 'containers' : location ? 'locations' : run ? 'runs' : undefined;
    return (
      <OrderListComponent
        freightOrderIds={freightOrderIds}
        heightToScroll={'max'}
        subtableOfTable={subtableOfTable}
        hideFilter={true}
        isGroupped={true}
        selectionColumnClassName="no-filter-selection"
        selectedOrders={this.state.selectedOrders[item.id]}
        setSelectedOrders={this.setSelectedOrders}
        groupId={item.id}
        hidePaging={true}
        currentRun={this.props.run}
      />
    );
  }

  ageBodyTemplate(item: FreightOrderDeliveryDocketGroup) {
    const freightOrderAges = item.freight_orders.map(freightOrder => getFreightOrderAgeInDays(freightOrder));
    const maxAge = Math.max(...freightOrderAges);
    return <span>{maxAge}</span>;
  }

  stageBodyTemplate(item: FreightOrderDeliveryDocketGroup) {
    const state = localStorage.getItem('current state')!;
    let stage = '';

    for (const order of item.freight_orders) {
      const orderStage = order?.route?.[state]?.stage;
      if (orderStage) {
        if (!stage) {
          stage = orderStage;
        } else if (stage !== orderStage) {
          stage = 'Mixed';
          break;
        }
      }
    }

    return <span className={`stage-badge stage-${stage.toLowerCase().replaceAll(' ', '-')}`}>{stage}</span>;
  }

  progressBodyTemplate(item: FreightOrderDeliveryDocketGroup) {
    let assignedSectionColour = '';
    let inProgressSectionColour = '';
    let deliveredSectionColour = '';
    const state = localStorage.getItem('current state')!;

    const orders = item.freight_orders.filter(o => o !== null) ?? [];
    const {runsMap, stopsMap} = this.state;
    //get tasks in current state
    const stateTasks =
      item.tasks.filter(task => {
        if (task.stop_id) {
          const runId = stopsMap[task.stop_id].run_id;
          const stopStateId = runsMap[runId].state_id;
          return stopStateId === state;
        }
        return false;
      }) ?? [];
    if (orders.every(o => o.route?.[state]?.stage === 'Delivered')) {
      assignedSectionColour = 'green';
      deliveredSectionColour = 'green';
      inProgressSectionColour = 'green';
    } else {
      if (orders.every(o => stateTasks.filter(t => t.freight_order_id === o.id).length > 0)) {
        assignedSectionColour = 'green';
      } else if (orders.every(o => stateTasks.filter(t => t.freight_order_id === o.id).length === 0)) {
        assignedSectionColour = 'blue';
      } else {
        assignedSectionColour = 'red';
      }

      if (orders.every(o => o.route?.[state]?.stage === 'On Board')) {
        inProgressSectionColour = 'green';
      } else if (orders.every(o => o.route?.[state]?.stage !== 'On Board')) {
        inProgressSectionColour = 'blue';
      } else {
        inProgressSectionColour = 'red';
      }

      if (orders.every(o => o.route?.[state]?.stage === 'Delivered')) {
        deliveredSectionColour = 'green';
      } else if (orders.every(o => o.route?.[state]?.stage !== 'Delivered')) {
        deliveredSectionColour = 'blue';
      } else {
        deliveredSectionColour = 'red';
      }
    }

    return (
      <>
        <span className={`p-progress p-component p-progress-${assignedSectionColour}`}>{'A'}</span>
        <span className={`p-progress p-component p-progress-${inProgressSectionColour}`}>{'I'}</span>
        <span className={`p-progress p-component p-progress-${deliveredSectionColour}`}>{'D'}</span>
      </>
    );
  }

  initMenuItems(): AppMenuItem[] {
    const selectedOrders = this.getSelectedOrders();
    const selectedOrdersCount = selectedOrders.length;

    if (this.props.initMenuItems) {
      return this.props.initMenuItems(selectedOrders);
    }

    if (selectedOrdersCount > 0) {
      return this.initOtherMenuItems(selectedOrders);
    }

    return [];
  }

  initOtherMenuItems(selectedItems: FreightOrder[]): AppMenuItem[] {
    const otherMenuItems: AppMenuItem[] = [];
    const state = localStorage.getItem('current state')!;

    if (selectedItems.every(order => order.route?.[state]?.stage === 'In Container')) {
      const hasSameContainer = selectedItems.every(
        (val, i, arr) => val.container?.unload_at_id === arr[0].container?.unload_at_id
      );

      if (hasSameContainer) {
        const unloadedMenu: AppMenuItem = {
          label: 'Unloaded',
          faIcon: ['fas', 'truck-loading'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.setUnloadedFreightOrders();
          },
        };

        otherMenuItems.push(unloadedMenu);
      }
    }

    if (selectedItems.every(order => order.route?.[state]?.stage !== 'Delivered')) {
      const assignRunMenu: AppMenuItem = {
        label: 'Assign To Run',
        faIcon: ['fas', 'truck-moving'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAssignOrdersToRunDialog();
        },
      };
      otherMenuItems.push(assignRunMenu);

      const deliveredMenu: AppMenuItem = {
        label: 'Delivered',
        faIcon: faPersonCarryBox,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showDeliverUndeliverDialog('deliver');
        },
      };
      otherMenuItems.push(deliveredMenu);
    }

    if (selectedItems.every(order => order.route?.[state]?.stage === 'Delivered')) {
      const unloadedMenu: AppMenuItem = {
        label: 'Un-Deliver',
        faIcon: ['fas', 'truck-loading'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showDeliverUndeliverDialog('undeliver');
        },
      };

      otherMenuItems.push(unloadedMenu);
    }

    return otherMenuItems;
  }

  showAssignOrdersToRunDialog(): void {
    this.setState({showAssignToRunDialog: true});
  }

  /**
   * Causes the deliver / undeliver dialog to show with the correct action type.
   * And saves the action type into state, so the continue method knows what to do
   * if triggered.
   * @param dialogType
   */
  showDeliverUndeliverDialog(dialogType: DeliverUndeliverDialogType): void {
    this.setState({showDeliverDialog: true, deliverDialogType: dialogType});
  }

  onDeliverUndeliverContinue(): void {
    this.setState({showDeliverDialog: false});
    this.loadData();
  }

  onDeliverUndeliverCancel(): void {
    this.setState({showDeliverDialog: false});
  }

  async setUnloadedFreightOrders(): Promise<void> {
    this.setState({loading: true});
    const freightOrders: FreightOrder[] = this.getSelectedOrders();
    const state = localStorage.getItem('current state')!;

    Promise.all(
      freightOrders.map(async (freightOrder: FreightOrder) => {
        const unloadLocationId =
          freightOrder.container?.unload_at_id ?? this.state.containersMap?.[freightOrder.container_id!]?.unload_at_id;

        if (unloadLocationId) {
          const newStage: FreightStage =
            Number(unloadLocationId) === Number(freightOrder.final_destination_id) ? 'Delivered' : 'At Warehouse';
          const routePart = {...freightOrder.route![state], stage: newStage};
          const route = {...freightOrder.route, [state]: routePart};

          const updateOrder: FreightOrderPatch = {
            route: route,
            current_location_id: unloadLocationId,
            at_current_location_since: new Date(),
          };

          return await this.freightOrdersService?.updateFreightOrder(freightOrder.id ?? '', updateOrder);
        }
        return;
      })
    )
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Orders unloaded successfully.');
        this.setState({loading: false});
        this.loadData();
        MessageService.sendMessage(messages.containerUpdate);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Orders unload failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  getSelectedOrders(): FreightOrder[] {
    // get selected orders as simple array
    const selectedOrders = Object.values(this.state.selectedOrders).flat();
    for (const order of selectedOrders) {
      // add container object to order if missing
      if (!order.container) {
        order.container = this.state.containersMap?.[order.container_id ?? ''];
      }
    }
    return selectedOrders;
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stopTypingDetection);
  };

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  dealerBodyTemplate(item: FreightOrderDeliveryDocketGroup) {
    const noteElement = item.freight_orders.some(order => order.delivery_note?.length) ? (
      <div className="p-as-center">
        <FontAwesomeIcon className="p-ml-2" icon={faMessageExclamation} />
      </div>
    ) : undefined;
    const owner = item.owner_company!;
    return (
      <div className="p-d-flex">
        <div>{getCompanyDescription(owner)}</div>
        {noteElement}
      </div>
    );
  }

  render() {
    const menuItemsColumnHeaderTemplate = (
      <React.Fragment>
        <AppSelectAllColumnHeaderTemplate initMenuItems={this.initMenuItems} selectedItems={this.getSelectedOrders()} />
      </React.Fragment>
    );

    const companyFilter = (
      <InputText
        name="company"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const destinationFilter = (
      <InputText
        name="destination"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const stageSelectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span className={`p-mr-1 stage-badge stage-badge-filter stage-${value.toLowerCase().replaceAll(' ', '-')}`}>
            {value}
          </span>
        );
      }

      return <></>;
    };
    const stageItemTemplate = (option: StageDropdownOption) => {
      return (
        <span className={`stage-badge stage-${option.value.toLowerCase().replaceAll(' ', '-')}`}>{option.value}</span>
      );
    };
    const freightStageFilter = (
      <MultiSelect
        selectedItemTemplate={stageSelectedItemTemplate}
        itemTemplate={stageItemTemplate}
        value={this.state.filters.freight_stage}
        options={stages}
        name="freight_stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    return (
      <div id="order_group_list_page" className="page-container">
        <TwoDataTable
          style={{height: '100%'}}
          pageSizeIdentifier={this.props.heightToScroll ? undefined : 'order_group_list_page'}
          heightToScroll={
            this.props.heightToScroll && this.props.heightToScroll !== 'max' ? this.props.heightToScroll : undefined
          }
          sizeIdentifiers={[]}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          onPage={e => this.onPageChange(e)}
          onSort={e => this.onSort(e)}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={{}}
          rowExpansionTemplate={data => this.rowExpansionTemplate(data)}
          expandedRows={this.state.expandedRows}
          onRowToggle={e => {
            this.setState({expandedRows: e.data});
          }}
          selectionMode="multiple"
          selectedItems={this.state.selectedItems}
          handleChangeSelectedItems={items =>
            this.setChangeSelectedItems(items as unknown as FreightOrderDeliveryDocketGroup[])
          }
          hideFilter={this.props.hideFilter ?? false}
          showPaging={false}
        >
          <Column
            expander
            className={'table-expander'}
            bodyClassName={'table-expander'}
            header={menuItemsColumnHeaderTemplate}
          />
          <Column
            header="Dealer"
            field="dealer"
            sortable
            style={{width: '150px'}}
            filter={!this.props.hideFilter}
            filterElement={companyFilter}
            showFilterMenu={false}
            body={this.dealerBodyTemplate}
          />
          <Column
            header="Destination"
            field="destination"
            sortable
            style={{width: '150px'}}
            filter={!this.props.hideFilter}
            filterElement={destinationFilter}
            showFilterMenu={false}
          />
          <Column header="Boxes" field="boxes" sortable style={{maxWidth: '100px'}} />
          <Column
            header="Progress"
            field=""
            body={this.progressBodyTemplate}
            style={{maxWidth: '120px'}}
            showFilterMenu={false}
          />
          <Column
            header="Stage"
            field=""
            body={this.stageBodyTemplate}
            style={{maxWidth: '180px'}}
            filter={!this.props.hideFilter}
            filterElement={freightStageFilter}
            showFilterMenu={false}
          />
          <Column header="Orders" field="count" sortable style={{maxWidth: '120px'}} />
          <Column header="Age" field="" body={this.ageBodyTemplate} style={{maxWidth: '80px'}} />
        </TwoDataTable>

        <AssignOrdersToRunDialog
          showDialog={this.state.showAssignToRunDialog}
          onHide={() => this.setState({showAssignToRunDialog: false})}
          toast={this.toast}
          freightOrders={this.getSelectedOrders()}
        />

        <DeliverUndeliverDialog
          showDialog={this.state.showDeliverDialog}
          type={this.state.deliverDialogType}
          onCancel={this.onDeliverUndeliverCancel}
          onContinue={this.onDeliverUndeliverContinue}
          toast={this.toast}
          orders={this.getSelectedOrders()}
        />

        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default GroupOrdersComponent;
