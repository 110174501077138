import React from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faSirenOn} from '@fortawesome/pro-regular-svg-icons';
import {AppContext, ReferenceComponent, ReferenceType} from 'two-app-ui';
import {FreightOrder, Location, MapOf, QueryParameter, Run} from 'two-core';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import RunsService from '../../services/RunsService';
import LocationsService from '../../services/LocationsService';

library.add(faSirenOn);

interface Props {
  order: FreightOrder;
  handleRunReferenceClick?: (run: Run) => void;
}

interface State {
  runs?: Run[];
  locationsMap: MapOf<Location>;
}

class RunReferenceComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  runsService: RunsService | null = null;
  locationsService: LocationsService | null = null;
  constructor(props: Props) {
    super(props);
    this.state = {locationsMap: {}};
  }

  async componentDidMount() {
    this.runsService = this.context.runsService;
    this.locationsService = this.context.locationsService;
    this.loadLocations = this.loadLocations.bind(this);
    this.getLocationName = this.getLocationName.bind(this);
  }

  resolveType(run: Run) {
    let poType;
    switch (run.stage) {
      case 'Done':
        poType = ReferenceType.SUCCESS;
        break;
      case 'In Progress':
        poType = ReferenceType.WARNING;
        break;
      case 'Scheduled':
        poType = ReferenceType.INFO;
        break;
      case 'Draft':
        poType = ReferenceType.DANGER;
        break;
      default:
        poType = ReferenceType.DEFAULT;
        break;
    }

    return poType;
  }

  /**
   * Get location name from map.
   * @param run
   * @param taskTypes
   */
  getLocationName(run: Run, taskTypes: string[]): string | undefined {
    const orderId = this.props.order.id;
    const currentState = localStorage.getItem('current state') ?? '';
    const locationsMap = this.state.locationsMap;
    const task = run.tasks?.find(task => task.freight_order_id === orderId && taskTypes.includes(task.type));
    const stop = run.stops?.find(stop => stop.id === task?.stop_id);
    const location = locationsMap[stop?.location_id ?? ''];
    if (!location) {
      return undefined;
    }
    if (location.state_id === currentState) {
      return location.name;
    }
    return `${location.name} ${location.state_id}`;
  }

  resolveTooltip(run: Run) {
    const currentStateId = localStorage.getItem('current state') ?? '';
    const pickupLocationName = this.getLocationName(run, ['pickup']);
    const dropLocationName = this.getLocationName(run, ['drop', 'final-drop']);

    return (
      <div className={'p-d-flex p-flex-column'}>
        <div className="p-grid">
          <label className="p-col-12 p-md-5">name</label>
          <div className="p-col-12 p-md-7">
            <span>{run.name}</span>
          </div>
        </div>

        <div className="p-grid">
          <label htmlFor="type" className="p-col-12 p-md-5">
            stage
          </label>
          <div className="p-col-12 p-md-7">
            <span>{run.stage}</span>
          </div>
        </div>

        {run.state_id !== currentStateId && (
          <div className="p-grid">
            <label className="p-col-12 p-md-5">state</label>
            <div className="p-col-12 p-md-7">
              <span>{run.state_id}</span>
            </div>
          </div>
        )}

        {run.vehicle?.name && (
          <div className="p-grid">
            <label className="p-col-12 p-md-5">vehicle</label>
            <div className="p-col-12 p-md-7">
              <span>{run.vehicle.name}</span>
            </div>
          </div>
        )}

        {run.schedule_entry?.start_at && (
          <div className="p-grid">
            <label className="p-col-12 p-md-5">start</label>
            <div className="p-col-12 p-md-7">
              <span>{DateTime.fromISO(run.schedule_entry.start_at.toString()).toFormat(formats.date)}</span>
            </div>
          </div>
        )}

        {run.schedule_entry?.end_at && (
          <div className="p-grid">
            <label className="p-col-12 p-md-5">end</label>
            <div className="p-col-12 p-md-7">
              <span>{DateTime.fromISO(run.schedule_entry.end_at.toString()).toFormat(formats.date)}</span>
            </div>
          </div>
        )}

        {pickupLocationName && (
          <div className="p-grid">
            <label className="p-col-12 p-md-5">from</label>
            <div className="p-col-12 p-md-7">
              <span>{pickupLocationName}</span>
            </div>
          </div>
        )}

        {dropLocationName && (
          <div className="p-grid">
            <label className="p-col-12 p-md-5">to</label>
            <div className="p-col-12 p-md-7">
              <span>{dropLocationName}</span>
            </div>
          </div>
        )}
      </div>
    );
  }

  onTooltipShow = async () => {
    if (!this.state.runs) {
      const ids = this.props.order.runs?.map(run => run.id!) ?? [];
      this.loadRuns(ids);
    }
  };

  loadRuns = async (ids: number[]) => {
    if (ids.length) {
      const filters: string[] = [];
      filters.push(
        JSON.stringify({
          field: 'id',
          value: ids,
          condition: 'in',
        })
      );

      const params: QueryParameter = {
        filters: filters,
        aggregate: true,
      };

      this.runsService?.getRuns(params).then(data => {
        const runs = (data?.records as Run[]) ?? [];
        const locationIds = runs.map(run => run.stops?.map(stop => stop.location_id)).flatMap(id => id!);
        this.loadLocations(locationIds);

        this.setState({runs: runs});
      });
    }
  };

  loadLocations(ids: number[]) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: ids,
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
      showAll: true,
    };
    this.locationsService?.getLocations(params).then(data => {
      const locations = data.records as Location[];
      const locationsMap: MapOf<Location> = {};
      for (const location of locations) {
        locationsMap[location.id!] = location;
      }
      this.setState({
        locationsMap: locationsMap,
      });
    });
  }

  render() {
    const icon: IconProp = ['far', 'truck'];
    const currentStateId = localStorage.getItem('current state') ?? '';
    const runs = this.state.runs ?? this.props.order.runs ?? [];

    return (
      runs &&
      runs.map((run: Run, index: number) => {
        if (run) {
          const id = this.props.order.id! + run.id + '-' + index;
          let link = undefined;
          let label = run.name;
          if (currentStateId === run.state_id) {
            link = `/run/${run.id}`;
          } else {
            label = `${run.state_id} ${label}`;
          }
          return (
            <ReferenceComponent
              key={'run-reference-component' + id}
              label={label}
              index={'run-reference-' + id}
              faIcon={icon}
              type={this.resolveType(run)}
              tooltipElement={this.resolveTooltip(run)}
              link={link}
              onTooltipShow={this.onTooltipShow}
            />
          );
        } else {
          return <></>;
        }
      })
    );
  }
}

export default RunReferenceComponent;
