import React from 'react';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {AppContext, TwoDialog} from 'two-app-ui';
import {Location} from 'two-core';

interface Props {
  showDialog: boolean;
  onHide: (runId?: number) => void;
  setLocationsToOrders: (location: Location) => void;
  locations: Location[];
}

interface State {
  selectedLocation: Location | undefined;
}

class SetLocationDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedLocation: undefined,
    };

    this.save = this.save.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.setLocation = this.setLocation.bind(this);
  }

  componentDidMount() {
    this.setLocation();
  }

  save() {
    const location = this.state.selectedLocation;
    if (location) {
      this.props.setLocationsToOrders(location);
    }
    this.hideDialog();
  }

  hideDialog() {
    this.props.onHide();
  }

  setLocation() {
    const location = this.props.locations.find(l => l.id === 0);

    this.setState({
      selectedLocation: location,
    });
  }

  setSelectedLocation(e: DropdownChangeParams) {
    const value = e.value as Location;
    this.setState({selectedLocation: value});
  }

  render() {
    const dialogBody = (
      <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0 p-pb-0">
        <label className="p-col-2">locations</label>
        <div className="p-col-10 p-p-0">
          <span className="p-fluid">
            <Dropdown
              className="p-d-flex w-100"
              filter
              value={this.state.selectedLocation}
              options={this.props.locations}
              onChange={e => this.setSelectedLocation(e)}
              optionLabel="name"
            />
          </span>
        </div>
      </div>
    );
    return (
      <TwoDialog
        headerTitle={'Choose Location'}
        showDialog={this.props.showDialog}
        width={50}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={false}
        onShow={this.setLocation}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default SetLocationDialog;
